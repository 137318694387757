import { useState, useMemo, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { YUP_VALIDATION_CONFIGURATIONS } from '@Constants';
import { setLocale } from 'yup';

setLocale(YUP_VALIDATION_CONFIGURATIONS);

const getFieldNameWithBooleanType = (initialValue) => {
  const booleanFields = [];

  for (const key in initialValue) {
    if (Object.hasOwnProperty.call(initialValue, key)) {
      if (typeof initialValue[key] === 'boolean') {
        booleanFields.push(key);
      }
    }
  }

  return booleanFields;
};

const castStringToBoolean = (value) => (value && value.toLowerCase() === 'true' ? true : false);

const composeValidationErrors = (validationErrors, yupErrorObject) => {
  yupErrorObject.inner.forEach((error) => {
    const fieldName = error.path;
    const errorMessage = error.message;

    if (fieldName) {
      if (isEmpty(validationErrors[fieldName])) {
        validationErrors[fieldName] = {
          messages: [],
          detail: [],
        };
      }

      validationErrors[fieldName].messages.push(errorMessage);
      validationErrors[fieldName].detail.push(yupErrorObject);
    }
  });

  return validationErrors;
};

const wrapWithTarget = (name, value) => {
  return {
    target: {
      name,
      value,
    },
  };
};

const useForm = ({ initialValue = {}, inferBoolean = true, stringToBoolean, validationSchema } = {}) => {
  const [form, setForm] = useState({ ...initialValue });

  const validate = async (additionalValues = {}) => {
    let validationErrors;

    try {
      await validationSchema.validate({ ...form, ...additionalValues }, { abortEarly: false });

      return undefined;
    } catch (errors) {
      validationErrors = {};
      composeValidationErrors(validationErrors, errors);

      setForm({
        ...form,
        validationErrors,
      });

      return validationErrors;
    }
  };

  const booleanFields = useMemo(() => {
    if (stringToBoolean?.length) {
      return stringToBoolean;
    }

    if (!inferBoolean) {
      return [];
    }

    return getFieldNameWithBooleanType(initialValue);
  }, [initialValue, inferBoolean, stringToBoolean]);

  const onChange = async (e) => {
    const name = e.target.name;
    let value = e.target.value;

    const isCheckbox = e.target.type === 'checkbox';

    if (isCheckbox) {
      value = e.target.checked;
    }

    const requireParseToBool = booleanFields.includes(name) && !isCheckbox;

    if (requireParseToBool) {
      value = castStringToBoolean(value);
    }

    const validationErrors = form.validationErrors;

    if (form?.validationErrors?.[name]) {
      try {
        await validationSchema.validateAt(name, { [name]: value }, { abortEarly: false });

        delete validationErrors?.[name];
      } catch (errors) {
        delete form?.validationErrors?.[name];
        composeValidationErrors(validationErrors, errors);
      }
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: value,
      ...({ validationErrors } || {}),
    }));
  };

  const clearForm = () => {
    setForm({});
  };

  const resetForm = () => {
    setForm({ ...initialValue });
  };

  return {
    onChange,
    form,
    setForm,
    validate,
    clearForm,
    resetForm,
    wrapWithTarget,
  };
};

export default useForm;
