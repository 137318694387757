import { useState } from 'react';

const useModal = ({ initialState = false } = {}) => {
  const [isModalVisible, setIsModalVisible] = useState(initialState);

  const toggleModal = () => {
    setIsModalVisible((prevState) => !prevState);
  };

  return [isModalVisible, toggleModal, setIsModalVisible];
};

export { useModal };
