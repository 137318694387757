import { createAction } from 'redux-actions';

export const FETCH_AGENCIES = 'FETCH_AGENCIES';
export const fetchAgencies = createAction(FETCH_AGENCIES);

export const FETCH_AGENCIES_FAILURE = 'FETCH_AGENCIES_FAILURE';
export const fetchAgenciesFailure = createAction(FETCH_AGENCIES_FAILURE);

export const FETCH_AGENCIES_REQUESTED = 'FETCH_AGENCIES_REQUESTED';
export const fetchAgenciesRequested = createAction(FETCH_AGENCIES_REQUESTED);

export const FETCH_AGENCIES_SUCCESS = 'FETCH_AGENCIES_SUCCESS';
export const fetchAgenciesSuccess = createAction(FETCH_AGENCIES_SUCCESS);
