import {
  FETCH_AGENCIES_FAILURE,
  FETCH_AGENCIES_REQUESTED,
  FETCH_AGENCIES_SUCCESS,
} from '../actions/agency-action-types';
import { CLEAR_USER_INFO } from '../actions/user-action-types';

const initialState = {
  fetchAgenciesStatus: 'pending',
  agencies: [],
};

export default function agencies(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_AGENCIES_FAILURE:
      return {
        ...state,
        fetchAgenciesStatus: 'failure',
      };

    case FETCH_AGENCIES_REQUESTED:
      return {
        ...state,
        fetchAgenciesStatus: 'searching',
      };

    case FETCH_AGENCIES_SUCCESS:
      return {
        ...state,
        fetchAgenciesStatus: 'success',
        agencies: payload,
      };

    case CLEAR_USER_INFO:
      return { ...initialState };

    default:
      return state;
  }
}
