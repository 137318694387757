import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import * as jsonpatch from 'fast-json-patch';
import cloneDeep from 'lodash.clonedeep';
import httpClient from './http-client';
import {
  createTaskFailure,
  createTaskRequested,
  createTaskSuccess,
  CREATE_TASK,
  UPDATE_TASK,
  fetchTasksFailure,
  fetchTasksRequested,
  fetchTasksSuccess,
  FETCH_TASKS,
  FETCH_TASK_BY_ID,
  fetchTaskByIdFailure,
  fetchTaskByIdSuccess,
  fetchTaskByIdRequested,
  fetchTaskById,
  updateTaskFailure,
  updateTaskSuccess,
  updateTaskRequested,
  fetchTasks,
} from '../actions/tasks-action-types';
import { createQuote } from '../actions/quote-action-types';
import { fetchConsumerChartsData } from '../actions/analytics-action-types';
import { HIDDEN_TASKS_DICTIONARY } from '../constants/tasks';
import { isEmpty, pick, isEqual } from 'lodash';

export function* addTaskHandler({ payload }) {
  yield put(createTaskRequested());
  const userData = yield select((state) => state?.user?.myProfile);
  const userId = userData?.identifier;
  const request = {
    data: {
      payload: {
        description: payload.description.trim(),
        name: payload.name.trim(),
        coverImage: payload.coverImage,
        dueDate: payload.dueDate.getTime(),
        category: payload.category,
        status: payload.status,
      },
    },
    method: 'POST',
    url: '/api/v2/customer/tasks',
  };
  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(createTaskFailure(error));
  } else {
    const taskId = data.details.id;

    if (payload?.isCreateQuote) {
      yield put(fetchTaskById(taskId));
      // yield delay(200);
      yield put(
        createQuote({
          ...payload?.quoteData,
          taskId,
          userId,
          itemDetails: {
            description: payload.description.trim(),
            name: payload.name.trim(),
            coverImage: payload.coverImage,
            dueDate: payload.dueDate.getTime(),
            category: payload.category,
            status: payload.status,
          },
        }),
      );
    } else {
      yield put(fetchTaskById(taskId));
    }
    yield put(createTaskSuccess(data));
    yield put(fetchConsumerChartsData());
  }
}

export function* updateTaskHandler({ payload }) {
  yield put(updateTaskRequested());

  const isUpdateParent = !payload?.param?.childTaskId || false;

  if (!isEmpty(payload?.body)) {
    const request = {
      data: payload?.body,
      method: 'PUT',
      url: isUpdateParent
        ? `/api/v2/customer/tasks/${payload?.param?.taskId}`
        : `/api/v2/customer/tasks/${payload?.param?.taskId}/childTasks/${payload.param.childTaskId}`,
    };

    const { data, error } = yield call(httpClient, request, true);

    if (error) {
      yield put(updateTaskFailure(error));
    } else {
      yield put(fetchTasks());
      yield put(updateTaskSuccess(data));
      yield put(fetchConsumerChartsData());
    }
  }
}

export function* fetchAllTasksHandler() {
  yield put(fetchTasksRequested());

  const userData = yield select((state) => state?.user?.user);
  const remoteConfig = yield select((store) => store?.app?.remoteConfig ?? {});
  const { enable_cimet_broadband } = remoteConfig;

  const request = {
    method: 'GET',
    params: {
      sort: 'asc',
    },
    url: 'api/v2/customer/tasks',
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(fetchTasksFailure(error));
  } else {
    const HIDDEN_TASKS = [...HIDDEN_TASKS_DICTIONARY];

    if (!enable_cimet_broadband) {
      HIDDEN_TASKS.push('Connect Internet');
    }

    const nonHiddenTasks = data.filter((task) => !HIDDEN_TASKS.includes(task.name));

    yield put(fetchTasksSuccess(nonHiddenTasks));
    yield put(fetchConsumerChartsData());
  }
}

export function* fetchTaskByIdHandler({ payload }) {
  yield put(fetchTaskByIdRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/customer/tasks/${payload}`,
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(fetchTaskByIdFailure(error));
  } else {
    yield put(fetchTaskByIdSuccess(data.details));
    yield put(fetchConsumerChartsData());
  }
}

function* Task() {
  yield all([
    takeLatest(CREATE_TASK, addTaskHandler),
    takeLatest(UPDATE_TASK, updateTaskHandler),
    takeLatest(FETCH_TASKS, fetchAllTasksHandler),
    takeLatest(FETCH_TASK_BY_ID, fetchTaskByIdHandler),
  ]);
}

export default Task;
