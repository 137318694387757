import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useSearchParams = ({ arrayTypes } = {}) => {
  const { search } = useLocation();

  const appendArraySearchParams = (searchParams, newParamName, newParamValues) => {
    searchParams.delete(newParamName);
    newParamValues.forEach((newParamValue) => {
      searchParams.append(newParamName, newParamValue);
    });

    return searchParams;
  };

  const transformSearchParamsStringToObject = (searchString) => {
    const query = new URLSearchParams(searchString);

    const searchKeys = [];
    const paramsObject = {};
    for (const key of query.keys()) {
      searchKeys.push(key);
    }

    searchKeys.forEach((key) => {
      if (arrayTypes?.includes(key)) {
        paramsObject[key] = query.getAll(key);
      } else {
        paramsObject[key] = query.get(key);
      }
    });

    return paramsObject;
  };

  const initialSearchParams = transformSearchParamsStringToObject(search);
  const [searchParamsState, setSearchParamsState] = useState(initialSearchParams);

  useEffect(() => {
    const searchParams = transformSearchParamsStringToObject(search);

    setSearchParamsState({ ...searchParams });
  }, [search]);

  /**
   * @params doesn't support nested object,
   * supported type: number, string, array
   * any falsy value will delete those corresponding key from search params
   * for more complex usage please consider upgrade to latest version of react router to use `useSearchParams`
   * */
  const composeSearchParams = (params) => {
    let searchParams = new URLSearchParams(search);
    for (const key in params) {
      const currentParamKey = key;
      const currentParamValue = params[key];

      if (Array.isArray(currentParamValue)) {
        appendArraySearchParams(searchParams, currentParamKey, currentParamValue);
      } else if (['', null, undefined].includes(currentParamValue)) {
        searchParams.delete(currentParamKey);
      } else {
        searchParams.set(currentParamKey, currentParamValue);
      }
    }

    return searchParams;
  };

  const appendSearchParams = (params) => {
    const searchParams = composeSearchParams(params);
    return searchParams.toString();
  };

  return {
    query: new URLSearchParams(search),
    composeSearchParams,
    appendSearchParams,
    searchParams: searchParamsState,
    ...searchParamsState,
  };
};
