import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeEmailVerifiedLinkSuccess } from '../actions/user-action-types';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { useAnalytics } from '@Contexts';
import { useRemoteConfigFlag } from '@Hooks';

const Redirector = ({ accessible, type, ...rest }) => {
  const { enable_chat, disable_web_url } = useRemoteConfigFlag();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const navigate = useNavigate();
  // Analytics with mixpanel
  const Analytics = useAnalytics();
  // Check if we have initialised analytics
  Analytics.isInitialized = !!Analytics['persistence'];

  if (Analytics.isInitialized && user) {
    Analytics.identify(user.email);
  }

  const { path, title } = rest;
  const redirectorProps = { accessible, type, path, title };

  useEffect(() => {
    window.$app = {
      navigate,
    };
  }, []);

  if (!!disable_web_url?.length && !rest.path.includes('activate')) {
    if (Analytics.isInitialized) {
      Analytics.track(`Redirecting from ${title} to ${disable_web_url}`, { disable_web_url, ...redirectorProps });
    }

    window.location.href = disable_web_url;
  }

  if (!user && type === 'private') {
    if (Analytics.isInitialized) {
      Analytics.track('Access attempt', redirectorProps);
    }
    return <Navigate to="/" from={rest.path} />;
  }

  if (user && !user.holdForBusinessInfo && type === 'public') {
    if (rest.path === '/email-verified') {
      dispatch(storeEmailVerifiedLinkSuccess());
    }

    if (Analytics.isInitialized) {
      Analytics.track('Log In', redirectorProps);
    }

    return <Navigate to="/home" from={rest.path} />;
  }

  if (user && !enable_chat && type === 'private') {
    if (rest.path.includes('chat')) {
      return <Navigate to="/" from={rest.path} />;
    }
  }

  if (Analytics.isInitialized) {
    Analytics.track(`Navigate to ${title}`, redirectorProps);
  }

  return <Outlet />;
};

export default Redirector;
