import useForm from '@Hooks/useForm';
import { VALIDATION_ERROR_MESSAGES } from '@Constants';
import { object, array, string, date } from 'yup';
import { useSelector } from 'react-redux';
import { composeQuoteRequestPayload } from './helper/compose-payload';
import { useSearchParams } from '@Hooks';

// return yesterday date at 23:59:59
const getYesterdayDate = () => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  return new Date(todayDate - 1);
};

const MOVING_FROM_AND_TO_ADDRESS_IS_THE_SAME_MESSAGE = 'Moving to and moving from address cannot be the same';

const quoteRequestFormValidationSchema = object({
  targetDate: date()
    .typeError(VALIDATION_ERROR_MESSAGES.INCORRECT_FORMAT)
    .required()
    .min(getYesterdayDate(), VALIDATION_ERROR_MESSAGES.DATE_IN_PAST),
  bookingDetails: string().required(),
  deliveryTime: array().required().min(1, VALIDATION_ERROR_MESSAGES.FIELD_IS_REQUIRED),
  movingFromAddress: string().test({
    test: (value, ctx) => {
      if (!ctx.parent.isRequireFromAndToAddressField) {
        return true;
      }

      if (value === ctx.parent.movingToAddress) {
        return ctx.createError({ message: MOVING_FROM_AND_TO_ADDRESS_IS_THE_SAME_MESSAGE });
      }
      return true;
    },
  }),
  movingToAddress: string().test({
    test: async (value, ctx) => {
      if (!ctx.parent.isRequireFromAndToAddressField) {
        return true;
      }

      if (value === ctx.parent.movingFromAddress) {
        return ctx.createError({ message: MOVING_FROM_AND_TO_ADDRESS_IS_THE_SAME_MESSAGE });
      }
      return true;
    },
  }),
});

const getServiceProductAddressInitialValue = ({ fromAddress, toAddress, manualAddress }) => {
  if (!!fromAddress) {
    return 'fromAddress';
  } else if (!!toAddress) {
    return 'toAddress';
  } else if (!!manualAddress) {
    return 'manualAddress';
  } else {
    return '';
  }
};

const getIsMovingInfoSufficient = ({ isRequireFromAndToAddressField, fromAddress, toAddress, manualAddress }) => {
  let isMovingInfoSufficient;

  if (isRequireFromAndToAddressField) {
    const hasCompleteMovingInfo = !!fromAddress && !!toAddress;

    const hasPartialMovingInfoWithManualAddress = (!!fromAddress || !!toAddress) && !!manualAddress;

    isMovingInfoSufficient = hasCompleteMovingInfo || hasPartialMovingInfoWithManualAddress;
  } else {
    isMovingInfoSufficient = !!fromAddress || !!toAddress || !!manualAddress;
  }

  return isMovingInfoSufficient;
};

const getMovingToAddressInitialValue = ({ toAddress, manualAddress }) => {
  let initialValue;
  if (!!toAddress) {
    initialValue = 'toAddress';
  } else if (!!manualAddress) {
    initialValue = 'manualAddress';
  } else {
    initialValue = '';
  }
  return initialValue;
};

const getMovingFromAddressInitialValue = ({ fromAddress, manualAddress }) => {
  let initialValue;
  if (!!fromAddress) {
    initialValue = 'fromAddress';
  } else if (!!manualAddress) {
    initialValue = 'manualAddress';
  } else {
    initialValue = '';
  }
  return initialValue;
};

export const useQuoteRequestForm = ({ initialCategory, initialService, initialTargetDate }) => {
  const { fromAddress, toAddress } = useSelector(
    ({
      user: {
        myProfile: {
          movingDetails: { movingFrom, movingTo },
        },
      },
    }) => ({
      fromAddress: movingFrom,
      toAddress: movingTo,
    }),
  );
  const { address } = useSearchParams();

  const fromAddressFeatures = fromAddress?.features ?? {};
  const toAddressFeatures = toAddress?.features ?? {};

  const isManualAddressEqualFromAddress = fromAddress?.address === address;
  const isManualAddressEqualToAddress = toAddress?.address === address;

  const manualAddress = !isManualAddressEqualFromAddress && !isManualAddressEqualToAddress ? address : undefined;
  const hasManualAddress = !!manualAddress;

  const initialValue = {
    category: initialCategory,
    service: initialService,
    targetDate: new Date(initialTargetDate),
    deliveryTime: ['anytime'],
    dueDateType: 'onDate',
    serviceProductAddress: getServiceProductAddressInitialValue({ fromAddress, toAddress, manualAddress }),
    serviceProductAddressBeds: fromAddressFeatures?.beds ?? 0,
    serviceProductAddressBaths: fromAddressFeatures?.baths ?? 0,
    serviceProductAddressCarSpaces: fromAddressFeatures?.carSpaces ?? 0,
    serviceProductAddressLandArea: fromAddressFeatures?.landArea ?? 0,
    serviceProductAddressPropertyType: fromAddress?.propertyType?.capitalizeEachLetter(),
    movingToAddress: getMovingToAddressInitialValue({ toAddress, manualAddress }),
    movingToPropertyAccess: [],
    movingToPropertyFurnish: '',
    movingToPropertyType: toAddress?.propertyType?.capitalizeEachLetter(),
    movingToBeds: toAddressFeatures.beds,
    movingToBaths: toAddressFeatures.baths,
    movingToCarSpaces: toAddressFeatures.carSpaces,
    movingToLandArea: toAddressFeatures.landArea,
    movingToBulkyItems: [],
    movingFromAddress: getMovingFromAddressInitialValue({ fromAddress, manualAddress }),
    movingFromPropertyAccess: [],
    movingFromPropertyFurnish: '',
    movingFromPropertyType: fromAddress?.propertyType?.capitalizeEachLetter(),
    movingFromBeds: fromAddressFeatures?.beds ?? 0,
    movingFromBaths: fromAddressFeatures?.baths ?? 0,
    movingFromCarSpaces: fromAddressFeatures?.carSpaces ?? 0,
    movingFromLandArea: fromAddressFeatures?.landArea ?? 0,
    manualAddressPropertyType: '',
    manualAddressBeds: '',
    manualAddressBaths: '',
    manualAddressCarSpaces: '',
    movingFromBulkyItems: [],
    bookingDetails: '',
    images: [],
  };

  const { onChange, form, wrapWithTarget, validate } = useForm({
    initialValue,
    validationSchema: quoteRequestFormValidationSchema,
  });

  const handleTargetDateChange = (value) => onChange(wrapWithTarget('targetDate', value));

  const handleDeliveryTimeChange = (value) => onChange(wrapWithTarget('deliveryTime', value));

  const handleImagesChange = (value) => onChange(wrapWithTarget('images', value));

  const validateCorrespondingAddressField = (e) => {
    const {
      target: { name },
    } = e;

    const correspondingFieldName = name === 'movingFromAddress' ? 'movingToAddress' : 'movingFromAddress';
    const correspondingFieldValue = form[correspondingFieldName];

    // onChange will automatically trigger validation to the corresponding field
    onChange(wrapWithTarget(correspondingFieldName, correspondingFieldValue));
  };

  const handleAddressChange = (e) => {
    onChange(e);
    validateCorrespondingAddressField(e);
  };

  return {
    onChange,
    form,
    wrapWithTarget,
    validate,
    handleTargetDateChange,
    handleDeliveryTimeChange,
    handleImagesChange,
    fromAddress,
    toAddress,
    composeQuoteRequestPayload,
    manualAddress,
    hasManualAddress,
    handleAddressChange,
    getIsMovingInfoSufficient,
  };
};
