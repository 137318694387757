import {
  FETCH_JOBS_FAILURE,
  FETCH_JOBS_REQUESTED,
  FETCH_JOBS_SUCCESS,
  CREATE_JOB_BY_QUOTE_ID_REQUESTED,
  CREATE_JOB_BY_QUOTE_ID_FAILURE,
  CREATE_JOB_BY_QUOTE_ID_SUCCESS,
  UPDATE_JOB_DELIVERED_DATE_REQUESTED,
  UPDATE_JOB_DELIVERED_DATE_SUCCESS,
  UPDATE_JOB_DELIVERED_DATE_FAILURE,
} from '../actions/jobs-actions-types';
import formatDate from '../pages/dashboard/common/commonFunctions.jsx';

const initialState = {
  addJobStatus: 'pending',
  jobs: [],
  fetchJobsStatus: 'pending',
  fetchJobByIdStatus: 'pending',
  updateJobStatus: 'pending',
  createJobByQuoteId: 'pending',
  updateJobDeliveredDateStatus: 'pending',
};

export default function Jobs(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_JOBS_FAILURE:
      return {
        ...state,
        fetchJobsStatus: 'failed',
      };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        fetchJobsStatus: 'success',
        jobs: payload.filter((item) => !item?.error),
        requestedCount: payload?.filter((item) => item.status === 'Requested' && !item?.error).length || 0,
        quotedCount: payload?.filter((item) => item.status === 'Quoted' && !item?.error).length || 0,
        acceptedCount: payload?.filter((item) => item.status === 'Accepted' && !item?.error).length || 0,
        declinedCount: payload?.filter((item) => item.status === 'Declined' && !item?.error).length || 0,
      };
    case FETCH_JOBS_REQUESTED:
      return {
        ...state,
        fetchJobsStatus: 'request',
      };
    case CREATE_JOB_BY_QUOTE_ID_REQUESTED:
      return {
        ...state,
        createJobByQuoteId: 'requested',
      };
    case CREATE_JOB_BY_QUOTE_ID_FAILURE:
      return {
        ...state,
        createJobByQuoteId: 'failure',
      };
    case CREATE_JOB_BY_QUOTE_ID_SUCCESS:
      return {
        ...state,
        createJobByQuoteId: 'success',
      };
    case UPDATE_JOB_DELIVERED_DATE_REQUESTED:
      return {
        ...state,
        updateJobDeliveredDateStatus: 'requested',
      };
    case UPDATE_JOB_DELIVERED_DATE_FAILURE:
      return {
        ...state,
        updateJobDeliveredDateStatus: 'failure',
      };
    case UPDATE_JOB_DELIVERED_DATE_SUCCESS:
      return {
        ...state,
        updateJobDeliveredDateStatus: 'success',
      };
    default:
      return state;
  }
}
