export const VALIDATION_ERROR_MESSAGES = {
  FIELD_IS_REQUIRED: 'Field is required',
  DATE_IN_PAST: 'Date must not be in the past',
  INCORRECT_FORMAT: 'Incorrect Format',
};

/**
 * For documentation @see https://github.com/jquense/yup#error-message-customization
 */
export const YUP_VALIDATION_CONFIGURATIONS = {
  mixed: {
    default: 'field_invalid',
    required: () => VALIDATION_ERROR_MESSAGES.FIELD_IS_REQUIRED,
  },
};
