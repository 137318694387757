import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_AGENT_FAVORITE_BUSINESSES,
  ADD_AGENT_FAVORITE_BUSINESSES,
  DELETE_AGENT_FAVORITE_BUSINESSES,
  FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD,
  fetchAgentFavoriteBusinessesRequested,
  fetchAgentFavoriteBusinessesSuccess,
  fetchAgentFavoriteBusinessesFailure,
  addAgentFavoriteBusinessesRequested,
  addAgentFavoriteBusinessesSuccess,
  addAgentFavoriteBusinessesFailure,
  deleteAgentFavoriteBusinessesRequested,
  deleteAgentFavoriteBusinessesSuccess,
  deleteAgentFavoriteBusinessesFailure,
  fetchAgentServiceDirectoryByKeywordRequested,
  fetchAgentServiceDirectoryByKeywordRequestedSuccess,
  fetchAgentServiceDirectoryByKeywordRequestedFailure,
} from '../actions/agent-favorite-business-action-types';
import { handleLoader } from '../actions/app-action-types';
import httpClient from './http-client';

export function* fetchAgentFavoriteBusinessHandler() {
  yield put(fetchAgentFavoriteBusinessesRequested());
  yield put(handleLoader(true));
  const request = {
    method: 'GET',
    url: '/api/v2/agent/favourite-businesses',
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(fetchAgentFavoriteBusinessesFailure(error));
    yield put(handleLoader(false));
  } else if (!data.length) {
    yield put(
      fetchAgentFavoriteBusinessesSuccess({
        data,
        isFavoriteBusinessListEmpty: true,
      }),
    );
    yield put(handleLoader(false));
  } else {
    const flaggedData = data.map((business) => ({ ...business }));

    yield put(
      fetchAgentFavoriteBusinessesSuccess({
        data: flaggedData,
        isFavoriteBusinessListEmpty: false,
      }),
    );
    yield put(handleLoader(false));
  }
}

export function* addAgentFavoriteBusinessHandler({ payload }) {
  yield put(addAgentFavoriteBusinessesRequested());
  yield put(handleLoader(true));

  const request = {
    data: payload.body,
    method: 'PUT',
    url: '/api/v2/agent/favourite-businesses',
  };

  const { error } = yield call(httpClient, request, true);

  if (error) {
    yield put(addAgentFavoriteBusinessesFailure(error));
    yield put(handleLoader(false));
  } else {
    yield put(addAgentFavoriteBusinessesSuccess());
    yield put(handleLoader(false));
    if (payload.meta && typeof payload.meta.callback === 'function') {
      payload.meta.callback();
    }
  }
}

export function* deleteAgentFavoriteBusinessHandler({ payload }) {
  yield put(deleteAgentFavoriteBusinessesRequested());
  yield put(handleLoader(true));

  const request = {
    method: 'DELETE',
    url: `/api/v2/agent/favourite-businesses/${payload.param.itemID}`,
  };

  const { error } = yield call(httpClient, request, true);

  if (error) {
    yield put(deleteAgentFavoriteBusinessesFailure(error));
    yield put(handleLoader(false));
  } else {
    yield put(deleteAgentFavoriteBusinessesSuccess());
    yield put(handleLoader(false));
    if (payload.meta && typeof payload.meta.callback === 'function') {
      payload.meta.callback();
    }
  }
}

export function* fetchAgentServiceDirectoryByKeywordHandler({ payload }) {
  yield put(fetchAgentServiceDirectoryByKeywordRequested());

  const loader = payload?.meta?.loader ?? true;
  const { query } = payload;

  const request = {
    method: 'GET',
    url: '/api/v2/agent/service-directory-search',
    params: query,
  };

  const { data, error } = yield call(httpClient, request, loader);

  if (error) {
    yield put(fetchAgentServiceDirectoryByKeywordRequestedFailure(error));
  } else {
    yield put(fetchAgentServiceDirectoryByKeywordRequestedSuccess({ data }));
  }
}

function* AgentFavoriteBusiness() {
  yield all([
    takeLatest(FETCH_AGENT_FAVORITE_BUSINESSES, fetchAgentFavoriteBusinessHandler),
    takeLatest(ADD_AGENT_FAVORITE_BUSINESSES, addAgentFavoriteBusinessHandler),
    takeLatest(DELETE_AGENT_FAVORITE_BUSINESSES, deleteAgentFavoriteBusinessHandler),
    takeLatest(FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD, fetchAgentServiceDirectoryByKeywordHandler),
  ]);
}

export default AgentFavoriteBusiness;
