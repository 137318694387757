import { createAction } from 'redux-actions';

export const ONBOARDING_USER_SUCCESS = 'ONBOARDING_USER_SUCCESS';
export const onboardingUserSuccess = createAction(ONBOARDING_USER_SUCCESS);

export const STORE_USER_INFO = 'STORE_USER_INFO';
export const storeUserInfo = createAction(STORE_USER_INFO);

export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const clearUserInfo = createAction(CLEAR_USER_INFO);

// TODO consider to remove 'LOGIN_USER' (or replacing it /user/check-user-exists)
//  because the name itself is misleading. This is actually to check if the user
//  exists after the login using `email + password` or social login
export const LOGIN_USER = 'LOGIN_USER';
export const loginUser = createAction(LOGIN_USER);

export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const loginUserFailure = createAction(LOGIN_USER_FAILURE);

export const LOGIN_USER_REQUESTED = 'LOGIN_USER_REQUESTED';
export const loginUserRequested = createAction(LOGIN_USER_REQUESTED);

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const loginUserSuccess = createAction(LOGIN_USER_SUCCESS);

export const HANDLE_ONBOARDING = 'HANDLE_ONBOARDING';
export const handleOnboarding = createAction(HANDLE_ONBOARDING);

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const updateUserProfile = createAction(UPDATE_USER_PROFILE);

export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const updateUserProfileFailure = createAction(UPDATE_USER_PROFILE_FAILURE);

export const UPDATE_USER_PROFILE_REQUESTED = 'UPDATE_USER_PROFILE_REQUESTED';
export const updateUserProfileRequested = createAction(UPDATE_USER_PROFILE_REQUESTED);

export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const updateUserProfileSuccess = createAction(UPDATE_USER_PROFILE_SUCCESS);

export const UPDATE_USER_ONBOARDING_DETAILS = 'UPDATE_USER_ONBOARDING_DETAILS';
export const updateUserOnBoardingDetails = createAction(UPDATE_USER_ONBOARDING_DETAILS);

export const INVITE_USER_ON_PLATFORM = 'INVITE_USER_ON_PLATFORM';
export const inviteUserOnPlatform = createAction(INVITE_USER_ON_PLATFORM);

export const INVITE_USER_ON_PLATFORM_FAILURE = 'INVITE_USER_ON_PLATFORM_FAILURE';
export const inviteUserOnPlatformFailure = createAction(INVITE_USER_ON_PLATFORM_FAILURE);

export const INVITE_USER_ON_PLATFORM_REQUESTED = 'INVITE_USER_ON_PLATFORM_REQUESTED';
export const inviteUserOnPlatformRequested = createAction(INVITE_USER_ON_PLATFORM_REQUESTED);

export const INVITE_USER_ON_PLATFORM_SUCCESS = 'INVITE_USER_ON_PLATFORM_SUCCESS';
export const inviteUserOnPlatformSuccess = createAction(INVITE_USER_ON_PLATFORM_SUCCESS);

export const SEARCH_AGENT_BY_KEYWORD = 'SEARCH_AGENT_BY_KEYWORD';
export const searchAgentByKeyword = createAction(SEARCH_AGENT_BY_KEYWORD);

export const SEARCH_AGENT_BY_KEYWORD_FAILURE = 'SEARCH_AGENT_BY_KEYWORD_FAILURE';
export const searchAgentByKeywordFailure = createAction(SEARCH_AGENT_BY_KEYWORD_FAILURE);

export const SEARCH_AGENT_BY_KEYWORD_REQUESTED = 'SEARCH_AGENT_BY_KEYWORD_REQUESTED';
export const searchAgentByKeywordRequested = createAction(SEARCH_AGENT_BY_KEYWORD_REQUESTED);

export const SEARCH_AGENT_BY_KEYWORD_SUCCESS = 'SEARCH_AGENT_BY_KEYWORD_SUCCESS';
export const searchAgentByKeywordSuccess = createAction(SEARCH_AGENT_BY_KEYWORD_SUCCESS);

export const CHECK_USER_EXISTS = 'CHECK_USER_EXISTS';
export const checkUserExists = createAction(CHECK_USER_EXISTS);

export const CHECK_USER_EXISTS_REQUESTED = 'CHECK_USER_EXISTS_REQUESTED';
export const checkUserExistsRequested = createAction('CHECK_USER_EXISTS_REQUESTED');

export const CHECK_USER_EXISTS_SUCCESS = 'CHECK_USER_EXISTS_SUCCESS';
export const checkUserExistsSuccess = createAction('CHECK_USER_EXISTS_SUCCESS');

export const CHECK_USER_EXISTS_FAILURE = 'CHECK_USER_EXISTS_FAILURE';
export const checkUserExistsFailure = createAction('CHECK_USER_EXISTS_FAILURE');

export const CHECK_USER_EXISTS_CLEAR = 'CHECK_USER_EXISTS_CLEAR';
export const checkUserExistsClear = createAction(CHECK_USER_EXISTS_CLEAR);

export const CHECK_USER_EXISTS_CLEAR_SUCCESS = 'CHECK_USER_EXISTS_CLEAR_SUCCESS';
export const checkUserExistsClearSuccess = createAction('CHECK_USER_EXISTS_CLEAR_SUCCESS');

export const STORE_USER_ONBOARDING = 'STORE_USER_ONBOARDING';
export const storeUserOnboarding = createAction(STORE_USER_ONBOARDING);

export const STORE_USER_ONBOARDING_CLEAR = 'STORE_USER_ONBOARDING_CLEAR';
export const storeUserOnboardingClear = createAction(STORE_USER_ONBOARDING_CLEAR);

export const STORE_USER_ONBOARDING_CLEAR_SUCCESS = 'STORE_USER_ONBOARDING_CLEAR_SUCCESS';
export const storeUserOnboardingClearSuccess = createAction(STORE_USER_ONBOARDING_CLEAR_SUCCESS);

export const STORE_USER_ONBOARDING_SUCCESS = 'STORE_USER_ONBOARDING_SUCCESS';
export const storeUserOnboardingSuccess = createAction(STORE_USER_ONBOARDING_SUCCESS);

export const STORE_BUSINESS_ONBOARDING = 'STORE_BUSINESS_ONBOARDING';
export const storeBusinessOnboarding = createAction(STORE_BUSINESS_ONBOARDING);

export const STORE_BUSINESS_ONBOARDING_CLEAR = 'STORE_BUSINESS_ONBOARDING_CLEAR';
export const storeBusinessOnboardingClear = createAction(STORE_BUSINESS_ONBOARDING_CLEAR);

export const STORE_BUSINESS_ONBOARDING_SUCCESS = 'STORE_BUSINESS_ONBOARDING_SUCCESS';
export const storeBusinessOnboardingSuccess = createAction(STORE_BUSINESS_ONBOARDING_SUCCESS);

export const CREATE_BUSINESS_SIGN_UP = 'CREATE_BUSINESS_SIGN_UP';
export const createBusinessSignUp = createAction(CREATE_BUSINESS_SIGN_UP);

export const CREATE_BUSINESS_SIGN_UP_REQUESTED = 'CREATE_BUSINESS_SIGN_UP_REQUESTED';
export const createBusinessSignUpRequested = createAction(CREATE_BUSINESS_SIGN_UP_REQUESTED);

export const CREATE_BUSINESS_SIGN_UP_SUCCESS = 'CREATE_BUSINESS_SIGN_UP_SUCCESS';
export const createBusinessSignUpSuccess = createAction(CREATE_BUSINESS_SIGN_UP_SUCCESS);

export const CREATE_BUSINESS_SIGN_UP_FAILURE = 'CREATE_BUSINESS_SIGN_UP_FAILURE';
export const createBusinessSignUpFailure = createAction(CREATE_BUSINESS_SIGN_UP_FAILURE);

export const CREATE_BUSINESS_SIGN_UP_CLEAR = 'CREATE_BUSINESS_SIGN_UP_CLEAR';
export const createBusinessSignUpClear = createAction(CREATE_BUSINESS_SIGN_UP_CLEAR);

export const STORE_USER_PROFILE = 'STORE_USER_PROFILE';
export const storeUserProfile = createAction(STORE_USER_PROFILE);

export const CREATE_AGENT_SIGN_UP = 'CREATE_AGENT_SIGN_UP';
export const createAgentSignUp = createAction(CREATE_AGENT_SIGN_UP);

export const CREATE_AGENT_SIGN_UP_REQUESTED = 'CREATE_AGENT_SIGN_UP_REQUESTED';
export const createAgentSignUpRequested = createAction(CREATE_AGENT_SIGN_UP_REQUESTED);

export const CREATE_AGENT_SIGN_UP_SUCCESS = 'CREATE_AGENT_SIGN_UP_SUCCESS';
export const createAgentSignUpSuccess = createAction(CREATE_AGENT_SIGN_UP_SUCCESS);

export const CREATE_AGENT_SIGN_UP_CLEAR = 'CREATE_AGENT_SIGN_UP_CLEAR';
export const createAgentSignUpClear = createAction(CREATE_AGENT_SIGN_UP_CLEAR);

export const CREATE_AGENT_SIGN_UP_FAILURE = 'CREATE_AGENT_SIGN_UP_FAILURE';
export const createAgentSignUpFailure = createAction(CREATE_AGENT_SIGN_UP_FAILURE);

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const fetchUserProfile = createAction(FETCH_USER_PROFILE);

export const FETCH_USER_PROFILE_REQUESTED = 'FETCH_USER_PROFILE_REQUESTED';
export const fetchUserProfileRequested = createAction(FETCH_USER_PROFILE_REQUESTED);

export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const fetchUserProfileFailure = createAction(FETCH_USER_PROFILE_FAILURE);

export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const fetchUserProfileSuccess = createAction(FETCH_USER_PROFILE_SUCCESS);

export const CREATE_CUSTOMER_ACCOUNT = 'CREATE_CUSTOMER_ACCOUNT';
export const createCustomerAccount = createAction(CREATE_CUSTOMER_ACCOUNT);

export const CREATE_CUSTOMER_ACCOUNT_REQUESTED = 'CREATE_CUSTOMER_ACCOUNT_REQUESTED';
export const createCustomerAccountRequested = createAction(CREATE_CUSTOMER_ACCOUNT_REQUESTED);

export const CREATE_CUSTOMER_ACCOUNT_SUCCESS = 'CREATE_CUSTOMER_ACCOUNT_SUCCESS';
export const createCustomerAccountSuccess = createAction(CREATE_CUSTOMER_ACCOUNT_SUCCESS);

export const CREATE_CUSTOMER_ACCOUNT_FAILURE = 'CREATE_CUSTOMER_ACCOUNT_FAILURE';
export const createCustomerAccountFailure = createAction(CREATE_CUSTOMER_ACCOUNT_FAILURE);

export const CLEAR_CUSTOMER_REGISTRATION_DATA = 'CLEAR_CUSTOMER_REGISTRATION_DATA';
export const clearCustomerRegistrationData = createAction(CLEAR_CUSTOMER_REGISTRATION_DATA);

// /user/mobile-number
export const UPDATE_USER_MOBILE_NUMBER = 'UPDATE_USER_MOBILE_NUMBER';
export const updateUserMobileNumber = createAction(UPDATE_USER_MOBILE_NUMBER);

export const UPDATE_USER_MOBILE_NUMBER_FAILURE = 'UPDATE_USER_MOBILE_NUMBER_FAILURE';
export const updateUserMobileNumberFailure = createAction(UPDATE_USER_MOBILE_NUMBER_FAILURE);

export const UPDATE_USER_MOBILE_NUMBER_REQUESTED = 'UPDATE_USER_MOBILE_NUMBER_REQUESTED';
export const updateUserMobileNumberRequested = createAction(UPDATE_USER_MOBILE_NUMBER_REQUESTED);

export const UPDATE_USER_MOBILE_NUMBER_SUCCESS = 'UPDATE_USER_MOBILE_NUMBER_SUCCESS';
export const updateUserMobileNumberSuccess = createAction(UPDATE_USER_MOBILE_NUMBER_SUCCESS);

// /user/email
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const updateUserEmail = createAction(UPDATE_USER_EMAIL);

export const UPDATE_USER_EMAIL_FAILURE = 'UPDATE_USER_EMAIL_FAILURE';
export const updateUserEmailFailure = createAction(UPDATE_USER_EMAIL_FAILURE);

export const UPDATE_USER_EMAIL_REQUESTED = 'UPDATE_USER_EMAIL_REQUESTED';
export const updateUserEmailRequested = createAction(UPDATE_USER_EMAIL_REQUESTED);

export const UPDATE_USER_EMAIL_SUCCESS = 'UPDATE_USER_EMAIL_SUCCESS';
export const updateUserEmailSuccess = createAction(UPDATE_USER_EMAIL_SUCCESS);

// /agent/profile/{profileId}
export const UPDATE_AGENT_PROFILE = 'UPDATE_AGENT_PROFILE';
export const updateAgentProfile = createAction(UPDATE_AGENT_PROFILE);

export const UPDATE_AGENT_PROFILE_FAILURE = 'UPDATE_AGENT_PROFILE_FAILURE';
export const updateAgentProfileFailure = createAction(UPDATE_AGENT_PROFILE_FAILURE);

export const UPDATE_AGENT_PROFILE_REQUESTED = 'UPDATE_AGENT_PROFILE_REQUESTED';
export const updateAgentProfileRequested = createAction(UPDATE_AGENT_PROFILE_REQUESTED);

export const UPDATE_AGENT_PROFILE_SUCCESS = 'UPDATE_AGENT_PROFILE_SUCCESS';
export const updateAgentProfileSuccess = createAction(UPDATE_AGENT_PROFILE_SUCCESS);

// When the user comes from the email-verified page
export const STORE_EMAIL_VERIFIED_LINK_SUCCESS = 'STORE_EMAIL_VERIFIED_LINK_SUCCESS';
export const storeEmailVerifiedLinkSuccess = createAction(STORE_EMAIL_VERIFIED_LINK_SUCCESS);

export const STORE_EMAIL_VERIFIED_LINK_CLEAR = 'STORE_EMAIL_VERIFIED_LINK_CLEAR';
export const storeEmailVerifiedLinkClear = createAction(STORE_EMAIL_VERIFIED_LINK_CLEAR);
