import { createAction } from 'redux-actions';

// GET /api/v2/agent/favorite-business
export const FETCH_AGENT_FAVORITE_BUSINESSES = 'FETCH_AGENT_FAVORITE_BUSINESSES';
export const fetchAgentFavoriteBusinesses = createAction(FETCH_AGENT_FAVORITE_BUSINESSES);

export const FETCH_AGENT_FAVORITE_BUSINESSES_REQUESTED = 'FETCH_AGENT_FAVORITE_BUSINESSES_REQUESTED';
export const fetchAgentFavoriteBusinessesRequested = createAction(FETCH_AGENT_FAVORITE_BUSINESSES_REQUESTED);

export const FETCH_AGENT_FAVORITE_BUSINESSES_SUCCESS = 'FETCH_AGENT_FAVORITE_BUSINESSES_SUCCESS';
export const fetchAgentFavoriteBusinessesSuccess = createAction(FETCH_AGENT_FAVORITE_BUSINESSES_SUCCESS);

export const FETCH_AGENT_FAVORITE_BUSINESSES_FAILURE = 'FETCH_AGENT_FAVORITE_BUSINESSES_FAILURE';
export const fetchAgentFavoriteBusinessesFailure = createAction(FETCH_AGENT_FAVORITE_BUSINESSES_FAILURE);

// PUT /api/v2/agent/favorite-business
export const ADD_AGENT_FAVORITE_BUSINESSES = 'ADD_AGENT_FAVORITE_BUSINESSES';
export const addAgentFavoriteBusinesses = createAction(ADD_AGENT_FAVORITE_BUSINESSES);

export const ADD_AGENT_FAVORITE_BUSINESSES_REQUESTED = 'ADD_AGENT_FAVORITE_BUSINESSES_REQUESTED';
export const addAgentFavoriteBusinessesRequested = createAction(ADD_AGENT_FAVORITE_BUSINESSES_REQUESTED);

export const ADD_AGENT_FAVORITE_BUSINESSES_SUCCESS = 'ADD_AGENT_FAVORITE_BUSINESSES_SUCCESS';
export const addAgentFavoriteBusinessesSuccess = createAction(ADD_AGENT_FAVORITE_BUSINESSES_SUCCESS);

export const ADD_AGENT_FAVORITE_BUSINESSES_FAILURE = 'ADD_AGENT_FAVORITE_BUSINESSES_FAILURE';
export const addAgentFavoriteBusinessesFailure = createAction(ADD_AGENT_FAVORITE_BUSINESSES_FAILURE);

// DELETE /api/v2/agent/favorite-business/{favouriteId}
export const DELETE_AGENT_FAVORITE_BUSINESSES = 'DELETE_AGENT_FAVORITE_BUSINESSES';
export const deleteAgentFavoriteBusinesses = createAction(DELETE_AGENT_FAVORITE_BUSINESSES);

export const DELETE_AGENT_FAVORITE_BUSINESSES_REQUESTED = 'DELETE_AGENT_FAVORITE_BUSINESSES_REQUESTED';
export const deleteAgentFavoriteBusinessesRequested = createAction(DELETE_AGENT_FAVORITE_BUSINESSES_REQUESTED);

export const DELETE_AGENT_FAVORITE_BUSINESSES_SUCCESS = 'DELETE_AGENT_FAVORITE_BUSINESSES_SUCCESS';
export const deleteAgentFavoriteBusinessesSuccess = createAction(DELETE_AGENT_FAVORITE_BUSINESSES_SUCCESS);

export const DELETE_AGENT_FAVORITE_BUSINESSES_FAILURE = 'DELETE_AGENT_FAVORITE_BUSINESSES_FAILURE';
export const deleteAgentFavoriteBusinessesFailure = createAction(DELETE_AGENT_FAVORITE_BUSINESSES_FAILURE);

// fuzzy search
export const FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD = 'FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD';
export const fetchAgentServiceDirectoryByKeyword = createAction(FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD);

export const FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_REQUESTED = 'FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_REQUESTED';
export const fetchAgentServiceDirectoryByKeywordRequested = createAction(
  FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_REQUESTED,
);

export const FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_SUCCESS = 'FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_SUCCESS';
export const fetchAgentServiceDirectoryByKeywordRequestedSuccess = createAction(
  FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_SUCCESS,
);

export const FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_FAILURE = 'FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_FAILURE';
export const fetchAgentServiceDirectoryByKeywordRequestedFailure = createAction(
  FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_FAILURE,
);
