import { applyMiddleware, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import sagas from '../sagas';

const configureStore = () => {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const sagaMiddleware = createSagaMiddleware();
  const isEnhancerAvailable = process.env.NODE_ENV !== 'production' && typeof composeEnhancer === 'function';
  const middleware = isEnhancerAvailable
    ? composeEnhancer(applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware);

  const store = createStore(rootReducer(), middleware);
  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return {
    persistor,
    store,
  };
};

export default configureStore;
