import {
  FETCH_CONSUMER_CHARTS_DATA_FAILURE,
  FETCH_CONSUMER_CHARTS_DATA_REQUESTED,
  FETCH_CONSUMER_CHARTS_DATA_SUCCESS,
  FETCH_AGENT_ANALYTICS_REQUESTED,
  FETCH_AGENT_ANALYTICS_FAILURE,
  FETCH_AGENT_ANALYTICS_SUCCESS,
} from '../actions/analytics-action-types';

const initialState = {
  consumerChartsData: {},
  agentStats: {},
  fetchConsumerChartsStatus: 'pending',
  fetchAgentStatsStatus: 'pending',
};

export default function Analytics(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_CONSUMER_CHARTS_DATA_FAILURE:
      return {
        ...state,
        fetchConsumerChartsStatus: 'failed',
      };

    case FETCH_CONSUMER_CHARTS_DATA_SUCCESS:
      return {
        ...state,
        fetchConsumerChartsStatus: 'success',
        consumerChartsData: payload,
      };
    case FETCH_CONSUMER_CHARTS_DATA_REQUESTED:
      return {
        ...state,
        fetchConsumerChartsStatus: 'request',
      };
    case FETCH_AGENT_ANALYTICS_REQUESTED:
      return {
        ...state,
        fetchAgentStatsStatus: 'requested',
        agentStats: {},
      };
    case FETCH_AGENT_ANALYTICS_FAILURE:
      return {
        ...state,
        fetchAgentStatsStatus: 'failure',
      };
    case FETCH_AGENT_ANALYTICS_SUCCESS:
      return {
        ...state,
        fetchAgentStatsStatus: 'success',
        agentStats: payload,
      };
    default:
      return state;
  }
}
