import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  UPDATE_MOVING_DETAILS,
  updateMovingDetailsRequested,
  updateMovingDetailsFailure,
  updateMovingDetailsSuccess,
} from '../actions/properties-action-types';
import httpClient from './http-client';

export function* updateMovingDetailsHandler({ payload }) {
  yield put(updateMovingDetailsRequested());

  const { movingDetailsId } = payload.param;

  const request = {
    data: payload.body,
    method: 'PUT',
    url: `/api/v2/customer/moving-details/${movingDetailsId}`,
  };

  const { error } = yield call(httpClient, request, true);

  if (error) {
    yield put(updateMovingDetailsFailure());
  } else {
    yield put(updateMovingDetailsSuccess());
  }
}

function* MyProperties() {
  yield all([takeLatest(UPDATE_MOVING_DETAILS, updateMovingDetailsHandler)]);
}

export default MyProperties;
