import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_CATEGORIES,
  fetchCategoriesRequested,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
} from '../actions/shared-action-types';
import httpClient from './http-client';

const mapServicesMeta = (categories) => {
  const mappedData = {};

  categories.forEach((category) => {
    category.services.forEach((service) => {
      mappedData[`${category.name}::${service.name}`] = service.meta;
    });
  });
  return mappedData;
};

const getTopCategories = (categories) => {
  return categories.filter((category) => category.isTopCategory);
};

export function* fetchCategoriesHandler({ payload }) {
  const loader = payload?.meta?.loader ?? true;

  yield put(fetchCategoriesRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/shared/categories`,
  };

  const { data, error } = yield call(httpClient, request, loader);

  if (error) {
    yield put(fetchCategoriesFailure());
  } else {
    const servicesMeta = mapServicesMeta(data);
    const topCategories = getTopCategories(data);
    const reducerPayload = {
      categories: data,
      servicesMeta,
      topCategories,
    };

    yield put(fetchCategoriesSuccess(reducerPayload));
  }
}

function* Shared() {
  yield all([takeLatest(FETCH_CATEGORIES, fetchCategoriesHandler)]);
}

export default Shared;
