const Pages = {
  AgentLandingPage: '/agent-landing-page',
  BusinessLandingPage: '/business-landing-page',
  BusinessSettings: '/business-user-settings',
  // hacks start
  AgentSignUpPage: '/agent/signup',
  BusinessSignUpPage: '/business/signup',
  LoginPage: '/login',
  // hacks end
  Chat: '/chat',
  Dashboard: '/home',
  LandingPage: '/',
  Tasks: '/tasks',
  Profile: '/business-profile',
  Properties: '/my-properties',
  ServiceDirectory: '/services',
};

export default Pages;
