import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  SEARCH_LOCATION,
  searchLocationFailure,
  searchLocationRequested,
  searchLocationSuccess,
} from '../actions/location-action-types';
import httpClient from './http-client';

export function* searchLocationHandler({ payload }) {
  yield put(searchLocationRequested());

  yield delay(200);

  const keyword = encodeURIComponent(payload);
  const request = {
    method: 'GET',
    url: `https://addressr.p.rapidapi.com/addresses?q=${keyword}`,
    headers: {
      'X-RapidAPI-Key': process.env.X_RAPID_API_KEY,
      'X-RapidAPI-Host': process.env.X_RAPID_API_HOST,
    },
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(searchLocationFailure(error));
  } else {
    const records = data?.map((singleRecord) => ({
      ...singleRecord,
      sla: singleRecord.sla.toLowerCase().capitalizeFirstLetter(),
    }));

    yield put(searchLocationSuccess(records));
  }
}

function* Locations() {
  yield all([takeLatest(SEARCH_LOCATION, searchLocationHandler)]);
}

export default Locations;
