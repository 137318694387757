import { createAction } from 'redux-actions';

export const HANDLE_LOADER = 'HANDLE_LOADER';
export const handleLoader = createAction(HANDLE_LOADER);

export const HIDE_LOADER = 'HIDE_LOADER';
export const hideLoader = createAction(HIDE_LOADER);

export const SHOW_LOADER = 'SHOW_LOADER';
export const showLoader = createAction(SHOW_LOADER);

export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const hideSnackbar = createAction(HIDE_SNACKBAR);

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const showSnackbar = createAction(SHOW_SNACKBAR);

export const SHOW_TOAST = 'SHOW_TOAST';
export const showToast = createAction(SHOW_TOAST);

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const toggleDrawer = createAction(TOGGLE_DRAWER);

export const TOGGLE_DRAWER_MENU = 'TOGGLE_DRAWER_MENU';
export const toggleDrawerMenu = createAction(TOGGLE_DRAWER_MENU);

export const TOGGLE_SNACK_BAR = 'TOGGLE_SNACK_BAR';
export const toggleSnackBar = createAction(TOGGLE_SNACK_BAR);

export const TOGGLE_MY_ACCOUNT_POPUP = 'TOGGLE_MY_ACCOUNT_POPUP';
export const toggleMyAccountPopup = createAction(TOGGLE_MY_ACCOUNT_POPUP);

export const TOGGLE_MOBILE_VERIFICATION = 'TOGGLE_MOBILE_VERIFICATION';
export const toggleMobileVerification = createAction(TOGGLE_MOBILE_VERIFICATION);

export const SET_CUSTOMER_CARDS_FILTER = 'SET_CUSTOMER_CARDS_FILTER';
export const setCustomerCardsFilter = createAction(SET_CUSTOMER_CARDS_FILTER);

export const SET_REMOTE_CONFIG = 'SET_REMOTE_CONFIG';
export const setRemoteConfig = createAction(SET_REMOTE_CONFIG);
