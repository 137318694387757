import {
  FETCH_UTILITIES_PROVIDERS_REQUESTED,
  FETCH_UTILITIES_PROVIDERS_SUCCESS,
  FETCH_UTILITIES_PROVIDERS_FAILURE,
  FETCH_LIFE_SUPPORT_EQUIPMENT_REQUESTED,
  FETCH_LIFE_SUPPORT_EQUIPMENT_SUCCESS,
  FETCH_LIFE_SUPPORT_EQUIPMENT_FAILURE,
  FETCH_UTILITIES_URL_REQUESTED,
  FETCH_UTILITIES_URL_SUCCESS,
  FETCH_UTILITIES_URL_FAILURE,
  FETCH_BROADBAND_URL_FAILURE,
  FETCH_BROADBAND_URL_SUCCESS,
  FETCH_BROADBAND_URL_REQUESTED,
} from '../actions/utilities-action-types';

const initialState = {
  utilitiesProviders: [],
  lifeSupportEquipment: [],
  utilitiesUrl: '',
  broadbandUrl: '',
  fetchUtilitiesProviderStatus: 'pending',
  fetchLifeSupportEquipmentStatus: 'pending',
  fetchUtilitiesUrlStatus: 'pending',
  fetchBroadbandUrlStatus: 'pending',
};

export default function utilities(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_UTILITIES_PROVIDERS_REQUESTED:
      return {
        ...state,
        fetchUtilitiesProviderStatus: 'failure',
      };
    case FETCH_UTILITIES_PROVIDERS_SUCCESS:
      return {
        ...state,
        utilitiesProviders: [...payload],
        fetchUtilitiesProviderStatus: 'success',
      };
    case FETCH_UTILITIES_PROVIDERS_FAILURE:
      return {
        ...state,
        fetchUtilitiesProviderStatus: 'failure',
      };
    case FETCH_LIFE_SUPPORT_EQUIPMENT_REQUESTED:
      return {
        ...state,
        fetchLifeSupportEquipmentStatus: 'requested',
      };
    case FETCH_LIFE_SUPPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        lifeSupportEquipment: [...payload],
        fetchLifeSupportEquipmentStatus: 'success',
      };
    case FETCH_LIFE_SUPPORT_EQUIPMENT_FAILURE:
      return {
        ...state,
        fetchLifeSupportEquipmentStatus: 'failure',
      };
    case FETCH_UTILITIES_URL_REQUESTED:
      return {
        ...state,
        fetchUtilitiesUrlStatus: 'requested',
      };
    case FETCH_UTILITIES_URL_SUCCESS:
      return {
        ...state,
        utilitiesUrl: payload,
        fetchUtilitiesUrlStatus: 'success',
      };
    case FETCH_UTILITIES_URL_FAILURE:
      return {
        ...state,
        fetchUtilitiesUrlStatus: 'failure',
      };
    case FETCH_BROADBAND_URL_REQUESTED:
      return {
        ...state,
        fetchBroadbandUrlStatus: 'requested',
      };
    case FETCH_BROADBAND_URL_SUCCESS:
      return {
        ...state,
        broadbandUrl: payload,
        fetchBroadbandUrlStatus: 'success',
      };
    case FETCH_BROADBAND_URL_FAILURE:
      return {
        ...state,
        fetchBroadbandUrlStatus: 'failure',
      };
    default:
      return state;
  }
}
