import { getAuth, connectAuthEmulator } from 'firebase/auth';

let auth = null;

const configureFirebaseAuth = () => {
  if (!auth) {
    auth = getAuth();
    // eslint-disable-next-line no-console
    if (process.env.USE_AUTH_EMULATOR === 'true') {
      connectAuthEmulator(auth, 'http://localhost:9099');
    }
  }

  return auth;
};

const getFirebaseToken = async () => {
  auth = configureFirebaseAuth();
  const token = await auth.currentUser?.getIdToken();

  return token;
};

export { configureFirebaseAuth as default, getFirebaseToken };
