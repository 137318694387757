import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as jsonpatch from 'fast-json-patch';
import cloneDeep from 'lodash.clonedeep';
import {
  CREATE_QUOTE,
  createQuoteRequested,
  createQuoteFailure,
  createQuoteSuccess,
  FETCH_CUSTOMER_QUOTES,
  fetchCustomerQuotesRequested,
  fetchCustomerQuotesSuccess,
  fetchCustomerQuotesFailure,
  FETCH_SERVICE_PROVIDER_QUOTES,
  fetchServiceProviderQuotesRequested,
  fetchServiceProviderQuotesFailure,
  fetchServiceProviderQuotesSuccess,
  FETCH_QUOTE_BY_CHILD_TASK,
  fetchQuoteByChildTaskRequested,
  fetchQuoteByChildTaskSuccess,
  fetchQuoteByChildTaskFailure,
  quoteJobsAcceptRequested,
  quoteJobsAcceptFailure,
  quoteJobsAcceptSuccess,
  quoteJobsDeclineFailure,
  quoteJobsDeclineRequested,
  quoteJobsDeclineSuccess,
  QUOTE_JOBS_ACCEPT,
  QUOTE_JOBS_DECLINE,
  CANCEL_QUOTE_REQUEST,
  cancelQuoteRequestRequested,
  cancelQuoteRequestFailure,
  cancelQuoteRequestSuccess,
} from '../actions/quote-action-types';
import httpClient from './http-client';

import { toggleSnackBar } from '../actions/app-action-types';
import { fetchConsumerChartsData } from '../actions/analytics-action-types';

export function* createQuoteHandler({ payload }) {
  yield put(createQuoteRequested());

  const request = {
    data: payload,
    method: 'POST',
    url: '/api/v2/customer/quotes',
  };

  const options = {
    message: 'Information saved successfully',
    severity: 'success',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(
      toggleSnackBar(error?.response?.data?.details || error?.response?.data?.message || 'Something went wrong.'),
    );
    yield put(createQuoteFailure(error));
  } else {
    yield put(toggleSnackBar(options));
    yield put(createQuoteSuccess(data));
    // yield put(createQuotePending());
    // yield put(updateTask({
    //   identifier: payload.taskId, status: "Quoted",
    // }));
    yield put(fetchConsumerChartsData());
  }
}

export function* fetchCustomerQuotesHandler() {
  yield put(fetchCustomerQuotesRequested());

  const request = {
    method: 'GET',
    // params: { userId },
    url: '/api/v2/customer/quotes',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchCustomerQuotesFailure(error));
  } else {
    // add flag to differentiate between task and quote data
    const mappedData = data.map((quote) => {
      quote.isQuote = true;
      return quote;
    });
    yield put(fetchCustomerQuotesSuccess(mappedData));
  }
}

export function* fetchServiceProviderQuotesHandler() {
  yield put(fetchServiceProviderQuotesRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/business/quotes/`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchServiceProviderQuotesFailure());
  } else {
    yield put(fetchServiceProviderQuotesSuccess(data));
  }
}

export function* fetchQuoteByChildTaskIdHandler({ payload }) {
  yield put(fetchQuoteByChildTaskRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/customer/quotes`,
    params: {
      childTaskId: payload,
    },
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    if (error?.response?.data?.message === "The quote couldn't be found") {
      const failurePayload = {
        identifier: payload,
      };
      yield put(fetchQuoteByChildTaskFailure(failurePayload));
      yield put(fetchQuoteByChildTaskSuccess());
    } else {
      yield put(fetchQuoteByChildTaskFailure());
    }
  } else {
    if (payload) {
      yield put(fetchQuoteByChildTaskSuccess(data[0]));
    } else {
      const mappedData = data.map((quote) => {
        quote.isQuote = true;
        return quote;
      });

      yield put(fetchQuoteByChildTaskSuccess(mappedData[0]));
      yield put(fetchCustomerQuotesSuccess(mappedData));
    }
  }
}

export function* quoteJobsAcceptHandler({ payload }) {
  put(quoteJobsAcceptRequested());

  const { quoteId, jobId } = payload;

  const request = {
    method: 'PUT',
    url: `/api/v2/customer/quotes/${quoteId}/jobs/${jobId}/accept`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(quoteJobsAcceptFailure(error));
  } else {
    yield put(quoteJobsAcceptSuccess(data));
  }
}

export function* quoteJobsDeclineHandler({ payload }) {
  put(quoteJobsDeclineRequested());

  const { quoteId, jobId } = payload;

  const request = {
    method: 'PUT',
    url: `/api/v2/customer/quotes/${quoteId}/jobs/${jobId}/decline`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(quoteJobsDeclineFailure(error));
  } else {
    yield put(quoteJobsDeclineSuccess(data));
  }
}

export function* cancelQuoteRequestHandler({ payload }) {
  yield put(cancelQuoteRequestRequested());

  const { quoteId } = payload.param;

  const request = {
    method: 'PUT',
    url: `/api/v2/customer/quotes/${quoteId}/cancel`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(cancelQuoteRequestFailure());
  } else {
    yield put(cancelQuoteRequestSuccess());
  }
}

function* Quote() {
  yield all([
    takeLatest(CREATE_QUOTE, createQuoteHandler),
    takeLatest(FETCH_CUSTOMER_QUOTES, fetchCustomerQuotesHandler),
    takeLatest(FETCH_SERVICE_PROVIDER_QUOTES, fetchServiceProviderQuotesHandler),
    takeLatest(FETCH_QUOTE_BY_CHILD_TASK, fetchQuoteByChildTaskIdHandler),
    takeLatest(CANCEL_QUOTE_REQUEST, cancelQuoteRequestHandler),
    takeLatest(QUOTE_JOBS_ACCEPT, quoteJobsAcceptHandler),
    takeLatest(QUOTE_JOBS_DECLINE, quoteJobsDeclineHandler),
  ]);
}

export default Quote;
