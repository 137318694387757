import {
  UPDATE_MOVING_DETAILS_REQUESTED,
  UPDATE_MOVING_DETAILS_FAILURE,
  UPDATE_MOVING_DETAILS_SUCCESS,
} from '../actions/properties-action-types';

const initialState = {
  addPropertyStatus: 'pending',
  fetchPropertiesStatus: 'pending',
  fetchSinglePropertyStatus: 'pending',
  properties: [],
  updatePropertyStatus: 'pending',
  updateMovingDetailsStatus: 'pending',
};

export default function myProperties(state = initialState, { payload, type }) {
  switch (type) {
    case UPDATE_MOVING_DETAILS_REQUESTED:
      return {
        ...state,
        updateMovingDetailsStatus: 'requested',
      };
    case UPDATE_MOVING_DETAILS_FAILURE:
      return {
        ...state,
        updateMovingDetailsStatus: 'failure',
      };
    case UPDATE_MOVING_DETAILS_SUCCESS:
      return {
        ...state,
        updateMovingDetailsStatus: 'success',
      };
    default:
      return state;
  }
}
