import { createTheme } from '@mui/material/styles';
import typographyTheme from './typography';

const brandColours = {
  persona: {
    agent: {
      secondary: {
        main: '#DEE6F2',
      },
      primary: {
        contrastText: '#FFFFFF',
        main: '#657795',
        main200: '#53627A',
      },
    },
    sp: {
      secondary: {
        contrastText: '#2E2E2E',
        main: '#FFEBD7',
        main200: '#F9D3AF',
      },
      primary: {
        contrastText: '#FFFFFF',
        main: '#F66C47',
        main200: '#CB4926',
      },
    },
  },
  common: {
    black: '#2E2E2E',
    white: '#FFFFFF',
    orange: '#F66C47',
    grey: '#EEEFF1',
    grey200: '#868C93',
    grey300: '#CDD1D7',
    grey400: '#DDE0E4',
    red: '#F03738',
    green: '#3CC13B',
    /* Neutral/Grey 3 */
    grey3: '#CDD1D7',
    /* Primary/BM Orange 200 */
    Orange200: '#FFEBD7',
    /* Primary/BM Orange 300 */
    Orange300: '#F9D3AF',
    /* System/Error */
    systemError: '#F03738',
    blue: '#DEE6F2',
  },
  contrastThreshold: 3,
  // light or dark
  mode: 'light',
  primary: {
    default: '#F2D45E',
    light: '#FFDF63',
    main: '#F2D45E',
  },
  secondary: {
    default: '#60A7DD',
    light: '#A2DFFF',
    main: '#5B9FD2',
  },
  tonalOffset: 0.2,
};

export const theme = createTheme({
  breakpoints: {
    values: {
      lg: 1200,
      md: 900,
      sm: 600,
      xl: 1536,
      xs: 0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          '&:hover': { backgroundColor: brandColours.common.orange },
          background: brandColours.common.orange,
          backgroundColor: brandColours.common.orange,
          color: brandColours.common.white,
        },
        outlined: {
          '&:hover': {
            backgroundColor: brandColours.common.orange,
            color: brandColours.common.white,
            border: `4px solid ${brandColours.common.orange}`,
          },
          background: brandColours.common.white,
          backgroundColor: brandColours.common.white,
          border: `4px solid ${brandColours.common.orange}`,
          color: brandColours.common.orange,
        },
        greyOutlined: {
          '&:hover': {
            backgroundColor: brandColours.common.grey3,
            color: brandColours.common.black,
            borderColor: `1px solid ${brandColours.common.black}`,
          },
          backgroundColor: brandColours.common.white,
          border: `1px solid ${brandColours.common.grey3}`,
          color: brandColours.common.black,
        },
        subdued: {
          '&:hover': {
            backgroundColor: brandColours.common.orange,
            color: brandColours.common.white,
          },
          background: brandColours.common.Orange200,
          border: `1px solid ${brandColours.common.Orange300}`,
          color: brandColours.common.black,
        },
        inline: {
          background: 'none',
          fontFamily: 'inherit',
          color: 'inherit',
          border: 'none',
          padding: 0,
          fontWeight: 'inherit',
          textDecoration: 'underline',
          '&:hover': { background: 'none' },
          '&:disabled': {
            background: 'none',
            color: brandColours.common.black,
          },
        },
        root: {
          '&:hover': { backgroundColor: brandColours.common.orange },
          '&:disabled': {
            backgroundColor: brandColours.common.grey,
            color: brandColours.common.black,
          },
          background: brandColours.common.orange,
          backgroundColor: brandColours.common.orange,
          borderRadius: 4,
          color: brandColours.common.white,
          fontFamily: 'DM Sans',
          fontSize: '1rem',
          padding: '14px 32px',
          width: 'fit-content',
          textTransform: 'none',
          '& .MuiSvgIcon-root': {
            color: 'currentColor',
            marginRight: '13px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#F03738',
          fontFamily: 'DM Sans',
          fontSize: '0.8rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'DM Sans',
          color: '#232323',
          fontSize: '1rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'DM Sans',
          fontSize: '1rem',
          fontWeight: '300',
          lineHeight: 25,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'DM Sans',
          fontStyle: 'normal',
        },
      },
    },
  },
  palette: brandColours,
  // spacing = `${8 * 2}px` = '16px'
  spacing: 8,
  typography: typographyTheme,
});

export default theme;
