import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_AGENCIES,
  fetchAgenciesFailure,
  fetchAgenciesRequested,
  fetchAgenciesSuccess,
} from '../actions/agency-action-types';
import httpClient from './http-client';

export function* fetchAgenciesHandler({ payload }) {
  yield put(fetchAgenciesRequested());
  yield delay(200);

  const keyword = encodeURIComponent(payload);
  const request = {
    method: 'GET',
    url: `/api/v2/agent/agency-logo?agency=${keyword}`,
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(fetchAgenciesFailure(error));
  } else {
    const response = data?.map((single) => ({
      ...single,
      sla: single.agency,
    }));

    yield put(fetchAgenciesSuccess(response));
  }
}

function* Agencies() {
  yield all([takeLatest(FETCH_AGENCIES, fetchAgenciesHandler)]);
}

export default Agencies;
