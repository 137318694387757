import {
  FETCH_AGENT_RECOMMENDATIONS_FAILURE,
  FETCH_AGENT_RECOMMENDATIONS_REQUESTED,
  FETCH_AGENT_RECOMMENDATIONS_SUCCESS,
} from '../actions/agent-recommendations-action-types';

const initialState = {
  fetchAgentRecommendationStatus: 'pending',
  recommendation: null,
};

export default function recommendation(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_AGENT_RECOMMENDATIONS_REQUESTED:
      return {
        ...state,
        fetchAgentRecommendationStatus: 'requested',
      };
    case FETCH_AGENT_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        fetchAgentRecommendationStatus: 'failure',
      };
    case FETCH_AGENT_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        fetchAgentRecommendationStatus: 'success',
        recommendation: payload,
      };
    default:
      return state;
  }
}
