export const DEFAULT_BLACK = '#333333';
export const BRAND_COLOR = '#374C6C';
export const RED_ALERT = '#F03738';
export const GREY_1 = '#868C93';

export const V2_COLORS = {
  /* Neutral/Black */
  NeutralBlack: '#2E2E2E',
};

const typographyTheme = {
  fontFamily: ['DM Sans', 'Courier New', 'Courier', 'monospace'].join(', '),
  fontStyle: 'normal',
  // register variants
  displayTitleBold: {
    fontWeight: '700',
    fontSize: '36px',
    lineHeight: '54px',
    color: BRAND_COLOR,
  },
  titleBold: {
    fontWeight: '700',
    fontSize: '32px',
    lineHeight: '48px',
    color: BRAND_COLOR,
  },
  headingBold: {
    fontWeight: '700',
    fontSize: '28px',
    lineHeight: '42px',
    color: BRAND_COLOR,
  },
  H1Bold: {
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '36px',
    color: DEFAULT_BLACK,
  },
  H2Bold: {
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '28px',
    color: DEFAULT_BLACK,
  },
  bodyTitleBold: {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '25px',
    color: DEFAULT_BLACK,
  },
  B1Regular: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '25px',
    color: DEFAULT_BLACK,
  },
  bodyTextBold: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '22px',
    color: DEFAULT_BLACK,
  },
  bodyTextRegular: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    color: DEFAULT_BLACK,
  },
  smallTextBold: {
    fontWeight: '700',
    fontSize: '13px',
    lineHeight: '19px',
    color: DEFAULT_BLACK,
  },
  smallTextRegular: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px',
    color: DEFAULT_BLACK,
  },
  overlineRegular: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '19px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: DEFAULT_BLACK,
  },
  smallerTextRegular: {
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '14px',
    color: DEFAULT_BLACK,
  },
  h2: {
    /* Platform / Headings / H2 */
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.02em',
    color: V2_COLORS.NeutralBlack,
  },
  body2: {
    /* Platform/Text/Body 2 (bold) */
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    color: V2_COLORS.NeutralBlack,
  },
};

export default typographyTheme;
