import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from '../actions/app-action-types';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function SnackBar() {
  const dispatch = useDispatch();
  const { message, position, severity, timeout, visible } = useSelector((store) => store.app.snackbarVisible);

  const onClose = () => dispatch(hideSnackbar());

  return (
    <Snackbar anchorOrigin={position} open={visible} autoHideDuration={timeout} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
