import {
  SEARCH_LOCATION_FAILURE,
  SEARCH_LOCATION_REQUESTED,
  SEARCH_LOCATION_SUCCESS,
} from '../actions/location-action-types';

const initialState = {
  searchLocationStatus: 'pending',
  locations: [],
};

export default function locations(state = initialState, { payload, type }) {
  switch (type) {
    case SEARCH_LOCATION_FAILURE:
      return {
        ...state,
        searchLocationStatus: 'failure',
      };

    case SEARCH_LOCATION_REQUESTED:
      return {
        ...state,
        searchLocationStatus: 'searching',
      };

    case SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        searchLocationStatus: 'success',
        locations: payload,
      };

    default:
      return state;
  }
}
