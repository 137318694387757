import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as jsonpatch from 'fast-json-patch';
import cloneDeep from 'lodash.clonedeep';
import {
  FETCH_AGENT_RECOMMENDATIONS,
  fetchAgentRecommendationsSuccess,
  fetchAgentRecommendationsRequested,
  fetchAgentRecommendationsFailure,
} from '../actions/agent-recommendations-action-types';
import httpClient from './http-client';
import { HIDDEN_AGENT_RECOMMENDATION_DICTIONARY } from '../constants/tasks';

export function* fetchAgentRecommendationHandler() {
  yield put(fetchAgentRecommendationsRequested());

  const request = {
    method: 'GET',
    url: '/api/v2/agent/recommendation',
  };

  const { data, error } = yield call(httpClient, request);
  if (error) {
    yield put(fetchAgentRecommendationsFailure());
  } else {
    const recommendations = data.filter(
      (recommendation) => !HIDDEN_AGENT_RECOMMENDATION_DICTIONARY.includes(recommendation.name),
    );
    yield put(fetchAgentRecommendationsSuccess(recommendations));
  }
}

function* AgentRecommendation() {
  yield all([takeLatest(FETCH_AGENT_RECOMMENDATIONS, fetchAgentRecommendationHandler)]);
}

export default AgentRecommendation;
