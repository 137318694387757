import { useSelector } from 'react-redux';
import UserRole from '../../utility/user-role';

export const useRole = () => {
  const { myProfile } = useSelector((store) => ({ myProfile: store?.user?.myProfile }));

  const role = myProfile?.role;
  const isAgent = role === UserRole.Agent;
  const isCustomer = role === UserRole.Customer;
  const isServiceProvider = role === UserRole.ServiceProvider;

  return {
    UserRole,
    role,
    isAgent,
    isCustomer,
    isServiceProvider,
  };
};
