import { createAction } from 'redux-actions';

// fetch all AgentRecommendations
export const FETCH_AGENT_RECOMMENDATIONS = 'FETCH_AGENT_RECOMMENDATIONS';
export const fetchAgentRecommendations = createAction(FETCH_AGENT_RECOMMENDATIONS);

export const FETCH_AGENT_RECOMMENDATIONS_REQUESTED = 'FETCH_AGENT_RECOMMENDATIONS_REQUESTED';
export const fetchAgentRecommendationsRequested = createAction(FETCH_AGENT_RECOMMENDATIONS_REQUESTED);

export const FETCH_AGENT_RECOMMENDATIONS_FAILURE = 'FETCH_AGENT_RECOMMENDATIONS_FAILURE';
export const fetchAgentRecommendationsFailure = createAction(FETCH_AGENT_RECOMMENDATIONS_FAILURE);

export const FETCH_AGENT_RECOMMENDATIONS_SUCCESS = 'FETCH_AGENT_RECOMMENDATIONS_SUCCESS';
export const fetchAgentRecommendationsSuccess = createAction(FETCH_AGENT_RECOMMENDATIONS_SUCCESS);
