import cloneDeep from 'lodash.clonedeep';
import { SEARCH_ABN_FAILURE, SEARCH_ABN_REQUESTED, SEARCH_ABN_SUCCESS } from '../actions/abn-action-types';

const initialState = {
  searchAbnStatus: 'pending',
  abnInfo: [],
};

export default function locations(state = initialState, { payload, type }) {
  switch (type) {
    case SEARCH_ABN_FAILURE:
      return {
        ...state,
        searchAbnStatus: 'failure',
      };

    case SEARCH_ABN_REQUESTED:
      return {
        ...state,
        searchAbnStatus: 'searching',
      };

    case SEARCH_ABN_SUCCESS: {
      let abnData = cloneDeep(state.abnInfo);

      if (payload && payload.indexOf('callback') > -1) {
        const subStr = payload.substring(9, payload.length - 1);

        abnData = JSON.parse(subStr);
      }

      return {
        ...state,
        searchAbnStatus: 'success',
        abnInfo: abnData,
      };
    }

    default:
      return state;
  }
}
