import { all, call, put, takeLatest } from 'redux-saga/effects';
import httpClient from './http-client';
import {
  FETCH_MY_BUSINESS,
  fetchMyBusinessRequested,
  fetchMyBusinessFailure,
  fetchMyBusinessSuccess,
  UPDATE_BUSINESS_PROFILE,
  updateBusinessProfileRequested,
  updateBusinessProfileFailure,
  updateBusinessProfileSuccess,
  CREATE_BUSINESS_LOCATION,
  createBusinessLocationRequested,
  createBusinessLocationFailure,
  createBusinessLocationSuccess,
  DELETE_MULTIPLE_BUSINESS_LOCATIONS,
  deleteMultipleBusinessLocationsRequested,
  deleteMultipleBusinessLocationsFailure,
  deleteMultipleBusinessLocationsSuccess,
  UPDATE_MULTIPLE_BUSINESS_LOCATIONS,
  updateMultipleBusinessLocationsRequested,
  updateMultipleBusinessLocationsFailure,
  updateMultipleBusinessLocationsSuccess,
  UPDATE_BUSINESS_TERM_AND_CONDITIONS,
  updateBusinessTermAndConditionsRequested,
  updateBusinessTermAndConditionsSuccess,
  updateBusinessTermAndConditionsFailure,
  UPDATE_BUSINESS_SERVICES,
  updateBusinessServicesRequested,
  updateBusinessServicesSuccess,
  updateBusinessServicesFailure,
  FETCH_BUSINESS_SERVICES,
  fetchBusinessServicesRequested,
  fetchBusinessServicesSuccess,
  fetchBusinessServicesFailure,
  UPDATE_BUSINESS_PAYMENT_DETAILS,
  updateBusinessPaymentDetailsRequested,
  updateBusinessPaymentDetailsSuccess,
  updateBusinessPaymentDetailsFailure,
  SEARCH_BUSINESS_BY_NAME,
  searchBusinessByNameRequested,
  searchBusinessByNameSuccess,
  searchBusinessByNameFailure,
  fetchBusinessStatsRequested,
  fetchBusinessStatsFailure,
  fetchBusinessStatsSuccess,
  FETCH_BUSINESS_STATS,
} from '../actions/business-action-types';
import { toggleSnackBar } from '../actions/app-action-types';
import { GENERAL_ERROR_MESSAGE } from '@Constants';

export function* fetchMyBusinessHandler() {
  yield put(fetchMyBusinessRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/business/details`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchMyBusinessFailure(error));
  } else {
    yield put(fetchMyBusinessSuccess(data));
  }
}

// TODO: this saga not using standard saga payload format
export function* updateBusinessProfileHandler({ payload }) {
  yield put(updateBusinessProfileRequested());

  const request = {
    method: 'PUT',
    data: payload,
    url: `/api/v2/business/details`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(updateBusinessProfileFailure(error));
  } else {
    yield put(updateBusinessProfileSuccess(data));
  }
}

export function* createBusinessLocationHandler({ payload }) {
  yield put(createBusinessLocationRequested());

  const request = {
    method: 'POST',
    data: payload,
    url: `/api/v2/business/locations`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(createBusinessLocationFailure(error));
  } else {
    yield put(createBusinessLocationSuccess(data));
  }
}

export function* deleteMultipleBusinessLocationsHandler({ payload }) {
  yield put(deleteMultipleBusinessLocationsRequested());

  const multipleDeleteRequests = payload.map((id) => {
    const request = {
      method: 'DELETE',
      url: `/api/v2/business/locations/${id}`,
    };

    return call(httpClient, request);
  });

  const responses = yield all(multipleDeleteRequests);

  const isContainError = responses.find((response) => !!response.error);

  if (isContainError) {
    yield put(deleteMultipleBusinessLocationsFailure());
  } else {
    yield put(deleteMultipleBusinessLocationsSuccess());
  }
}

export function* updateMultipleBusinessLocationsHandler({ payload }) {
  yield put(updateMultipleBusinessLocationsRequested());

  const multipleUpdateRequests = payload.map((singlePayload) => {
    const request = {
      method: 'PUT',
      data: singlePayload.body,
      url: `/api/v2/business/locations/${singlePayload.param.locationId}`,
    };

    return call(httpClient, request);
  });

  const responses = yield all(multipleUpdateRequests);

  const isContainError = responses.find((response) => !!response.error);

  if (isContainError) {
    yield put(updateMultipleBusinessLocationsFailure());
  } else {
    yield put(updateMultipleBusinessLocationsSuccess());
  }
}

export function* updateBusinessTermAndConditionsHandler({ payload }) {
  yield put(updateBusinessTermAndConditionsRequested());

  const request = {
    method: 'PUT',
    url: `/api/v2/business/terms-and-conditions`,
    data: payload.body,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(updateBusinessTermAndConditionsFailure(error));
  } else {
    yield put(updateBusinessTermAndConditionsSuccess(data));
  }
}

export function* updateBusinessServicesHandler({ payload }) {
  yield put(updateBusinessServicesRequested());

  const request = {
    method: 'PUT',
    url: `/api/v2/business/services`,
    data: payload.body,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(updateBusinessServicesFailure(error));
  } else {
    yield put(updateBusinessServicesSuccess(data));
  }
}

export function* fetchBusinessServicesHandler() {
  yield put(fetchBusinessServicesRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/business/services`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchBusinessServicesFailure());
  } else {
    yield put(fetchBusinessServicesSuccess(data));
  }
}

export function* updateBusinessPaymentDetailsHandler({ payload }) {
  yield put(updateBusinessPaymentDetailsRequested());

  const request = {
    method: 'PUT',
    url: `/api/v2/business/payment-details`,
    data: payload.body,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(updateBusinessPaymentDetailsFailure());
    yield put(toggleSnackBar(error.response.data.message || GENERAL_ERROR_MESSAGE));
  } else {
    yield put(updateBusinessPaymentDetailsSuccess(data));
  }
}

export function* searchBusinessByNameHandler({ payload }) {
  yield put(searchBusinessByNameRequested());

  const request = {
    method: 'POST',
    url: '/api/v2/business/search-business',
    data: payload,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(searchBusinessByNameFailure());
  } else {
    yield put(searchBusinessByNameSuccess(data));
  }
}

export function* fetchBusinessStatsHandler({ payload }) {
  yield put(fetchBusinessStatsRequested());

  const params = {};

  if (payload?.startDate) {
    params.startDate = payload.startDate;
  }

  if (payload?.endDate) {
    params.endDate = payload.endDate;
  }

  const request = {
    method: 'GET',
    url: '/api/v2/business/statistics',
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(toggleSnackBar(error?.response?.data?.details || 'Something went wrong.'));
    yield put(fetchBusinessStatsFailure(error));
  } else {
    yield put(fetchBusinessStatsSuccess(data));
  }
}

function* Business() {
  yield all([
    // TODO: DISCREPANCY
    takeLatest(FETCH_MY_BUSINESS, fetchMyBusinessHandler),
    // TODO: DISCREPANCY
    takeLatest(UPDATE_BUSINESS_PROFILE, updateBusinessProfileHandler),
    takeLatest(CREATE_BUSINESS_LOCATION, createBusinessLocationHandler),
    // TODO: DISCREPANCY
    takeLatest(DELETE_MULTIPLE_BUSINESS_LOCATIONS, deleteMultipleBusinessLocationsHandler),
    // TODO: DISCREPANCY
    takeLatest(UPDATE_MULTIPLE_BUSINESS_LOCATIONS, updateMultipleBusinessLocationsHandler),
    takeLatest(UPDATE_BUSINESS_TERM_AND_CONDITIONS, updateBusinessTermAndConditionsHandler),
    takeLatest(UPDATE_BUSINESS_SERVICES, updateBusinessServicesHandler),
    takeLatest(FETCH_BUSINESS_SERVICES, fetchBusinessServicesHandler),
    takeLatest(UPDATE_BUSINESS_PAYMENT_DETAILS, updateBusinessPaymentDetailsHandler),
    takeLatest(SEARCH_BUSINESS_BY_NAME, searchBusinessByNameHandler),
    takeLatest(FETCH_BUSINESS_STATS, fetchBusinessStatsHandler),
  ]);
}

export default Business;
