import {
  CLEAR_USER_INFO,
  ONBOARDING_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUESTED,
  LOGIN_USER_SUCCESS,
  SEARCH_AGENT_BY_KEYWORD_FAILURE,
  SEARCH_AGENT_BY_KEYWORD_REQUESTED,
  SEARCH_AGENT_BY_KEYWORD_SUCCESS,
  INVITE_USER_ON_PLATFORM_FAILURE,
  INVITE_USER_ON_PLATFORM_REQUESTED,
  INVITE_USER_ON_PLATFORM_SUCCESS,
  HANDLE_ONBOARDING,
  STORE_USER_INFO,
  UPDATE_USER_PROFILE_SUCCESS,
  CHECK_USER_EXISTS_REQUESTED,
  CHECK_USER_EXISTS_SUCCESS,
  CHECK_USER_EXISTS_FAILURE,
  CHECK_USER_EXISTS_CLEAR_SUCCESS,
  STORE_USER_ONBOARDING_SUCCESS,
  STORE_USER_ONBOARDING_CLEAR_SUCCESS,
  STORE_BUSINESS_ONBOARDING_SUCCESS,
  STORE_BUSINESS_ONBOARDING_CLEAR,
  CREATE_BUSINESS_SIGN_UP_SUCCESS,
  CREATE_BUSINESS_SIGN_UP_FAILURE,
  CREATE_BUSINESS_SIGN_UP_CLEAR,
  CREATE_AGENT_SIGN_UP_FAILURE,
  CREATE_AGENT_SIGN_UP_SUCCESS,
  CREATE_AGENT_SIGN_UP_CLEAR,
  FETCH_USER_PROFILE_REQUESTED,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  CREATE_CUSTOMER_ACCOUNT_SUCCESS,
  CREATE_CUSTOMER_ACCOUNT_FAILURE,
  CLEAR_CUSTOMER_REGISTRATION_DATA,
  CREATE_AGENT_SIGN_UP_REQUESTED,
  CREATE_BUSINESS_SIGN_UP_REQUESTED,
  CREATE_CUSTOMER_ACCOUNT_REQUESTED,
  UPDATE_USER_MOBILE_NUMBER_REQUESTED,
  UPDATE_USER_MOBILE_NUMBER_FAILURE,
  UPDATE_USER_MOBILE_NUMBER_SUCCESS,
  UPDATE_USER_EMAIL_REQUESTED,
  UPDATE_USER_EMAIL_FAILURE,
  UPDATE_USER_EMAIL_SUCCESS,
  UPDATE_AGENT_PROFILE_FAILURE,
  UPDATE_AGENT_PROFILE_SUCCESS,
  UPDATE_AGENT_PROFILE_REQUESTED,
  STORE_EMAIL_VERIFIED_LINK_SUCCESS,
  STORE_EMAIL_VERIFIED_LINK_CLEAR,
} from '../actions/user-action-types';

const initialState = {
  agentsList: [],
  movingPlan: {},
  oldCred: null,
  onboardingStatus: null,
  createUserStatus: 'pending',
  loginStatus: 'pending',
  searchAgentsStatus: 'pending',
  inviteUserStatus: 'pending',
  user: null,
  usersByRole: [],
  selectedBemovedPlanIndex: 0,
  fetchUserStatus: 'pending',
  isUserExist: null,
  userExistsError: null,
  userExistsStatus: 'pending',
  updateUserProfileStatus: 'pending',
  requestUser: [],
  userOnboarding: null,
  businessOnboarding: null,
  businessSignUp: null,
  businessSignUpError: null,
  businessSignUpStatus: 'pending',
  agentSignUpError: null,
  agentSignUp: null,
  agentSignupStatus: 'pending',
  customerSignUp: null,
  customerSignUpError: null,
  customerSignSignUpStatus: 'pending',
  myProfile: {},
  movingDetails: {},
  fetchUserProfileStatus: 'pending',
  updateUserMobileNumberStatus: 'pending',
  updateUserEmailStatus: 'pending',
  updateAgentProfileStatus: 'pending',
  fromEmailVerifiedLink: null,
};

export default function user(state = initialState, { payload, type }) {
  switch (type) {
    case CLEAR_USER_INFO:
      return {
        ...state,
        movingPlan: {},
        agentRecommendation: {},
        myProfile: {},
        user: null,
        token: null,
      };

    case ONBOARDING_USER_SUCCESS:
      return {
        ...state,
        createUserStatus: 'success',
        user: {
          ...state.user,
          ...payload,
        },
        token: payload.token,
      };

    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loginStatus: 'failure',
      };

    case LOGIN_USER_REQUESTED:
      return {
        ...state,
        loginStatus: 'logging',
      };

    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        oldCred: payload.email,
        loginStatus: 'success',
        user: payload,
        token: payload.token,
      };
    }

    case HANDLE_ONBOARDING:
      return {
        ...state,
        onboardingStatus: payload,
      };

    case STORE_USER_INFO:
      return {
        ...state,
        movingPlan: payload.movingPlan,
        user: payload.user,
      };

    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateUserProfileStatus: 'success',
        user: {
          ...state.user,
          ...payload,
        },
        myProfile: {
          ...state.myProfile,
          ...payload,
        },
      };

    case SEARCH_AGENT_BY_KEYWORD_FAILURE:
      return {
        ...state,
        searchAgentsStatus: 'failure',
      };

    case SEARCH_AGENT_BY_KEYWORD_REQUESTED:
      return {
        ...state,
        searchAgentsStatus: 'fetching',
      };

    case SEARCH_AGENT_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        searchAgentsStatus: 'success',
        agentsList: payload,
      };

    case INVITE_USER_ON_PLATFORM_FAILURE:
      return {
        ...state,
        inviteUserStatus: 'failure',
      };

    case INVITE_USER_ON_PLATFORM_REQUESTED:
      return {
        ...state,
        inviteUserStatus: 'fetching',
      };

    case INVITE_USER_ON_PLATFORM_SUCCESS:
      return {
        ...state,
        inviteUserStatus: 'success',
      };

    case CHECK_USER_EXISTS_REQUESTED:
      return {
        ...state,
        userExistsStatus: 'requested',
      };

    case CHECK_USER_EXISTS_SUCCESS:
      return {
        ...state,
        isUserExist: payload,
        userExistsStatus: 'success',
      };

    case CHECK_USER_EXISTS_FAILURE:
      return {
        ...state,
        userExistsError: payload,
        userExistsStatus: 'failure',
      };

    case CHECK_USER_EXISTS_CLEAR_SUCCESS:
      return {
        ...state,
        isUserExist: null,
        userExistsError: null,
        userExistsStatus: 'pending',
      };

    case STORE_USER_ONBOARDING_SUCCESS:
      return {
        ...state,
        userOnboarding: payload,
      };

    case STORE_USER_ONBOARDING_CLEAR_SUCCESS:
      return {
        ...state,
        userOnboarding: null,
      };

    case STORE_BUSINESS_ONBOARDING_SUCCESS:
      return {
        ...state,
        businessOnboarding: payload,
      };

    case STORE_BUSINESS_ONBOARDING_CLEAR:
      return {
        ...state,
        businessOnboarding: null,
      };

    case CREATE_BUSINESS_SIGN_UP_REQUESTED: {
      return {
        ...state,
        businessSignUpStatus: 'requested',
      };
    }

    case CREATE_BUSINESS_SIGN_UP_SUCCESS:
      return {
        ...state,
        businessSignUp: payload,
        businessSignUpError: null,
        businessSignUpStatus: 'success',
      };

    case CREATE_BUSINESS_SIGN_UP_FAILURE:
      return {
        ...state,
        businessSignUpError: payload,
        businessSignUpStatus: 'failure',
      };

    case CREATE_BUSINESS_SIGN_UP_CLEAR:
      return {
        ...state,
        businessSignUp: null,
        businessSignUpError: null,
        businessSignUpStatus: 'pending',
      };

    case CREATE_AGENT_SIGN_UP_REQUESTED: {
      return {
        ...state,
        agentSignupStatus: 'requested',
      };
    }

    case CREATE_AGENT_SIGN_UP_FAILURE:
      return {
        ...state,
        agentSignUpError: payload,
        agentSignupStatus: 'failure',
        agentSignUp: null,
      };

    case CREATE_AGENT_SIGN_UP_SUCCESS:
      return {
        ...state,
        agentSignUp: payload,
        agentSignUpError: null,
        agentSignupStatus: 'success',
      };

    case CREATE_AGENT_SIGN_UP_CLEAR:
      return {
        ...state,
        agentSignUp: null,
        agentSignUpError: null,
        agentSignupStatus: 'pending',
      };
    case FETCH_USER_PROFILE_REQUESTED: {
      return {
        ...state,
        fetchUserProfileStatus: 'requested',
      };
    }

    case FETCH_USER_PROFILE_FAILURE: {
      return {
        ...state,
        fetchUserProfileStatus: 'failure',
      };
    }

    case FETCH_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        fetchUserProfileStatus: 'success',
        myProfile: payload,
        movingDetails: payload.movingDetails,
        oldCred: payload.email,
        user: {
          ...state.user,
          ...payload,
        },
      };
    }

    case CREATE_CUSTOMER_ACCOUNT_REQUESTED: {
      return {
        ...state,
        customerSignSignUpStatus: 'requested',
      };
    }

    case CREATE_CUSTOMER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        customerSignSignUpStatus: 'success',
        customerSignUp: payload,
      };
    }

    case CREATE_CUSTOMER_ACCOUNT_FAILURE: {
      return {
        ...state,
        customerSignSignUpStatus: 'failure',
        customerSignUp: null,
        customerSignUpError: payload,
      };
    }

    case CLEAR_CUSTOMER_REGISTRATION_DATA: {
      return {
        ...state,
        customerSignSignUpStatus: 'pending',
        customerSignUp: null,
        customerSignUpError: null,
      };
    }

    // *** Update user mobile number ***
    case UPDATE_USER_MOBILE_NUMBER_REQUESTED:
      return {
        ...state,
        updateUserMobileNumberStatus: 'requested',
      };

    case UPDATE_USER_MOBILE_NUMBER_FAILURE:
      return {
        ...state,
        updateUserMobileNumberStatus: 'failure',
      };

    case UPDATE_USER_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
        myProfile: {
          ...state.myProfile,
          ...payload,
        },
        updateUserMobileNumberStatus: 'success',
      };

    // *** Update user email ***
    case UPDATE_USER_EMAIL_REQUESTED:
      return {
        ...state,
        updateUserEmailStatus: 'requested',
      };

    case UPDATE_USER_EMAIL_FAILURE:
      return {
        ...state,
        updateUserEmailStatus: 'failure',
      };

    case UPDATE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload,
        },
        myProfile: {
          ...state.myProfile,
          ...payload,
        },
        updateUserEmailStatus: 'success',
      };

    // *** Update agent profile ***
    case UPDATE_AGENT_PROFILE_REQUESTED:
      return {
        ...state,
        updateAgentProfileStatus: 'requested',
      };

    case UPDATE_AGENT_PROFILE_FAILURE:
      return {
        ...state,
        updateAgentProfileStatus: 'failure',
      };

    case UPDATE_AGENT_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          agency: { ...payload.agentProfile },
        },
        myProfile: {
          ...state.myProfile,
          agentProfile: {
            ...state.myProfile.agentProfile,
            ...payload.agentProfile,
          },
        },
        updateAgentProfileStatus: 'success',
      };

    case STORE_EMAIL_VERIFIED_LINK_SUCCESS:
      return {
        ...state,
        fromEmailVerifiedLink: true,
      };

    case STORE_EMAIL_VERIFIED_LINK_CLEAR:
      return {
        ...state,
        fromEmailVerifiedLink: null,
      };

    default:
      return state;
  }
}
