import { createAction } from 'redux-actions';

export const SEARCH_LOCATION = 'SEARCH_LOCATION';
export const searchLocation = createAction(SEARCH_LOCATION);

export const SEARCH_LOCATION_FAILURE = 'SEARCH_LOCATION_FAILURE';
export const searchLocationFailure = createAction(SEARCH_LOCATION_FAILURE);

export const SEARCH_LOCATION_REQUESTED = 'SEARCH_LOCATION_REQUESTED';
export const searchLocationRequested = createAction(SEARCH_LOCATION_REQUESTED);

export const SEARCH_LOCATION_SUCCESS = 'SEARCH_LOCATION_SUCCESS';
export const searchLocationSuccess = createAction(SEARCH_LOCATION_SUCCESS);
