import { useSelector } from 'react-redux';
import UserRole from '../../utility/user-role';
import { useTheme } from '@mui/material/styles';

/**
 * Return color palette based on role or brand params
 * @param {"property-agent" | "customer" | "service-provider" | undefined} brand
 * @returns
 */
export const useBrandPalette = (brand) => {
  const { palette } = useTheme();
  const { myProfile } = useSelector((store) => ({ myProfile: store?.user?.myProfile }));

  let brandPalette;

  const role = brand || myProfile?.role;

  if (role === UserRole.Agent) {
    brandPalette = palette.persona.agent;
  } else {
    // for the time being customer and service provider has same color branding
    brandPalette = palette.persona.sp;
  }

  return {
    palette,
    brandPalette,
  };
};
