import { createAction } from 'redux-actions';

// /customer/moving-details/{movingDetailsId}
export const UPDATE_MOVING_DETAILS = 'UPDATE_MOVING_DETAILS';
export const updateMovingDetails = createAction(UPDATE_MOVING_DETAILS);

export const UPDATE_MOVING_DETAILS_REQUESTED = 'UPDATE_MOVING_DETAILS_REQUESTED';
export const updateMovingDetailsRequested = createAction(UPDATE_MOVING_DETAILS_REQUESTED);

export const UPDATE_MOVING_DETAILS_FAILURE = 'UPDATE_MOVING_DETAILS_FAILURE';
export const updateMovingDetailsFailure = createAction(UPDATE_MOVING_DETAILS_FAILURE);

export const UPDATE_MOVING_DETAILS_SUCCESS = 'UPDATE_MOVING_DETAILS_SUCCESS';
export const updateMovingDetailsSuccess = createAction(UPDATE_MOVING_DETAILS_SUCCESS);
