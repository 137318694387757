import { createAction } from 'redux-actions';

export const FETCH_CONSUMER_CHARTS_DATA = 'FETCH_CONSUMER_CHARTS_DATA';
export const fetchConsumerChartsData = createAction(FETCH_CONSUMER_CHARTS_DATA);

export const FETCH_CONSUMER_CHARTS_DATA_FAILURE = 'FETCH_CONSUMER_CHARTS_DATA_FAILURE';
export const fetchConsumerChartsDataFailure = createAction(FETCH_CONSUMER_CHARTS_DATA_FAILURE);

export const FETCH_CONSUMER_CHARTS_DATA_REQUESTED = 'FETCH_CONSUMER_CHARTS_DATA_REQUESTED';
export const fetchConsumerChartsDataRequested = createAction(FETCH_CONSUMER_CHARTS_DATA_REQUESTED);

export const FETCH_CONSUMER_CHARTS_DATA_SUCCESS = 'FETCH_CONSUMER_CHARTS_DATA_SUCCESS';
export const fetchConsumerChartsDataSuccess = createAction(FETCH_CONSUMER_CHARTS_DATA_SUCCESS);

// fetch all AgentAnalytic
export const FETCH_AGENT_ANALYTICS = 'FETCH_AGENT_ANALYTICS';
export const fetchAgentAnalytics = createAction(FETCH_AGENT_ANALYTICS);

export const FETCH_AGENT_ANALYTICS_REQUESTED = 'FETCH_AGENT_ANALYTICS_REQUESTED';
export const fetchAgentAnalyticsRequested = createAction(FETCH_AGENT_ANALYTICS_REQUESTED);

export const FETCH_AGENT_ANALYTICS_FAILURE = 'FETCH_AGENT_ANALYTICS_FAILURE';
export const fetchAgentAnalyticsFailure = createAction(FETCH_AGENT_ANALYTICS_FAILURE);

export const FETCH_AGENT_ANALYTICS_SUCCESS = 'FETCH_AGENT_ANALYTICS_SUCCESS';
export const fetchAgentAnalyticsSuccess = createAction(FETCH_AGENT_ANALYTICS_SUCCESS);
