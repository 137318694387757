import storage from 'redux-persist/lib/storage';
import { persistCombineReducers } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import app from './app';
import locations from './locations';
import user from './user';
import myProperties from './my-properties';
import tasks from './tasks';
import jobs from './jobs';
import abn from './abn';
import quote from './quote';
import analytics from './analytics';
import agentRecommendation from './agent-recommendations';
import connectedUsers from './connectedUsers';
import business from './business';
import utilities from './utilities';
import shared from './shared';
import serviceDirectory from './service-directory';
import agencies from './agencies';
import agentFavoriteBusiness from './agent-favorite-business';

const encryption = encryptTransform({ secretKey: process.env.REDUX_PERSIST_SECRET_KEY });

const config = {
  whitelist: ['user'],
  key: 'primary',
  storage,
  transforms: [encryption],
};

export default () =>
  persistCombineReducers(config, {
    agencies,
    abn,
    agentRecommendation,
    analytics,
    app,
    business,
    connectedUsers,
    jobs,
    locations,
    myProperties,
    quote,
    shared,
    tasks,
    user,
    utilities,
    serviceDirectory,
    agentFavoriteBusiness,
  });
