/* eslint-disable no-negated-condition */
import cloneDeep from 'lodash.clonedeep';
import {
  CREATE_QUOTE_REQUESTED,
  CREATE_QUOTE_PENDING,
  CREATE_QUOTE_FAILURE,
  CREATE_QUOTE_SUCCESS,
  FETCH_CUSTOMER_QUOTES_REQUESTED,
  FETCH_CUSTOMER_QUOTES_FAILURE,
  FETCH_CUSTOMER_QUOTES_SUCCESS,
  FETCH_SERVICE_PROVIDER_QUOTES_REQUESTED,
  FETCH_SERVICE_PROVIDER_QUOTES_FAILURE,
  FETCH_SERVICE_PROVIDER_QUOTES_SUCCESS,
  FETCH_QUOTE_BY_CHILD_TASK_REQUESTED,
  FETCH_QUOTE_BY_CHILD_TASK_FAILURE,
  FETCH_QUOTE_BY_CHILD_TASK_SUCCESS,
  CANCEL_QUOTE_REQUEST_REQUESTED,
  CANCEL_QUOTE_REQUEST_FAILURE,
  CANCEL_QUOTE_REQUEST_SUCCESS,
  QUOTE_JOBS_ACCEPT_REQUESTED,
  QUOTE_JOBS_ACCEPT_FAILURE,
  QUOTE_JOBS_ACCEPT_SUCCESS,
  QUOTE_JOBS_DECLINE_REQUESTED,
  QUOTE_JOBS_DECLINE_FAILURE,
  QUOTE_JOBS_DECLINE_SUCCESS,
} from '../actions/quote-action-types';

const initialState = {
  quotes: [],
  createQuoteStatus: 'pending',
  updateQuoteStatus: 'pending',
  userQuotes: [],
  fetchCustomerQuotesStatus: 'pending',
  serviceProviderQuotes: [],
  fetchServiceProviderQuotesStatus: 'pending',
  quoteByChildTaskIds: [],
  fetchQuoteByChildTaskIdStatus: 'pending',
  responseJobsStatus: 'pending',
  cancelQuoteRequestStatus: 'pending',
};

export default function quotes(state = initialState, { payload, type }) {
  const newPayload = [];

  switch (type) {
    case CREATE_QUOTE_REQUESTED:
      return {
        ...state,
        createQuoteStatus: 'requested',
      };
    case CREATE_QUOTE_PENDING:
      return {
        ...state,
        createQuoteStatus: 'pending',
      };
    case CREATE_QUOTE_FAILURE:
      return {
        ...state,
        createQuoteStatus: 'failure',
      };
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        createQuoteStatus: 'success',
      };
    case FETCH_CUSTOMER_QUOTES_FAILURE: {
      return {
        ...state,
        fetchCustomerQuotesStatus: 'failure',
      };
    }
    case FETCH_CUSTOMER_QUOTES_SUCCESS: {
      return {
        ...state,
        fetchCustomerQuotesStatus: 'success',
        userQuotes: [...payload],
      };
    }
    case FETCH_CUSTOMER_QUOTES_REQUESTED: {
      return {
        ...state,
        fetchCustomerQuotesStatus: 'requested',
      };
    }
    case FETCH_SERVICE_PROVIDER_QUOTES_REQUESTED: {
      return {
        ...state,
        fetchServiceProviderQuotesStatus: 'requested',
      };
    }
    case FETCH_SERVICE_PROVIDER_QUOTES_FAILURE: {
      return {
        ...state,
        fetchServiceProviderQuotesStatus: 'failure',
      };
    }
    case FETCH_SERVICE_PROVIDER_QUOTES_SUCCESS: {
      return {
        ...state,
        fetchServiceProviderQuotesStatus: 'success',
        serviceProviderQuotes: payload,
      };
    }
    case FETCH_QUOTE_BY_CHILD_TASK_REQUESTED: {
      return {
        ...state,
        fetchQuoteByChildTaskIdStatus: 'requested',
      };
    }
    case FETCH_QUOTE_BY_CHILD_TASK_FAILURE: {
      let quoteByChildTaskIds;

      if (!payload) {
        quoteByChildTaskIds = state.quoteByChildTaskIds;
      } else {
        quoteByChildTaskIds = state.quoteByChildTaskIds.filter((item) => item.childTaskId !== payload.identifier);
      }

      return {
        ...state,
        quoteByChildTaskIds,
        fetchQuoteByChildTaskIdStatus: 'failure',
      };
    }
    case FETCH_QUOTE_BY_CHILD_TASK_SUCCESS: {
      let quoteByChildTaskIds;

      if (!payload) {
        quoteByChildTaskIds = state.quoteByChildTaskIds;
      } else {
        quoteByChildTaskIds = state.quoteByChildTaskIds.filter((item) => item.identifier !== payload.identifier);
        quoteByChildTaskIds.push(payload);
      }

      return {
        ...state,
        quoteByChildTaskIds,
        fetchQuoteByChildTaskIdStatus: 'success',
      };
    }

    // Accept / Decline the quote's job
    case QUOTE_JOBS_ACCEPT_REQUESTED:
    case QUOTE_JOBS_DECLINE_REQUESTED:
      return {
        ...state,
        responseJobsStatus: 'requested',
      };

    case QUOTE_JOBS_ACCEPT_FAILURE:
    case QUOTE_JOBS_DECLINE_FAILURE:
      return {
        ...state,
        responseJobsStatus: 'failure',
      };

    case QUOTE_JOBS_ACCEPT_SUCCESS:
    case QUOTE_JOBS_DECLINE_SUCCESS:
      return {
        ...state,
        responseJobsStatus: 'success',
      };

    case CANCEL_QUOTE_REQUEST_REQUESTED: {
      return {
        ...state,
        cancelQuoteRequestStatus: 'requested',
      };
    }
    case CANCEL_QUOTE_REQUEST_FAILURE: {
      return {
        ...state,
        cancelQuoteRequestStatus: 'failure',
      };
    }
    case CANCEL_QUOTE_REQUEST_SUCCESS: {
      return {
        ...state,
        cancelQuoteRequestStatus: 'success',
      };
    }
    default:
      return state;
  }
}
