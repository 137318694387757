import { createAction } from 'redux-actions';

export const FETCH_UTILITIES_PROVIDERS = 'FETCH_UTILITIES_PROVIDERS';
export const fetchUtilitiesProvider = createAction(FETCH_UTILITIES_PROVIDERS);

export const FETCH_UTILITIES_PROVIDERS_REQUESTED = 'FETCH_UTILITIES_PROVIDERS_REQUESTED';
export const fetchUtilitiesProvidersRequested = createAction(FETCH_UTILITIES_PROVIDERS_REQUESTED);

export const FETCH_UTILITIES_PROVIDERS_SUCCESS = 'FETCH_UTILITIES_PROVIDERS_SUCCESS';
export const fetchUtilitiesProvidersSuccess = createAction(FETCH_UTILITIES_PROVIDERS_SUCCESS);

export const FETCH_UTILITIES_PROVIDERS_FAILURE = 'FETCH_UTILITIES_PROVIDERS_FAILURE';
export const fetchUtilitiesProvidersFailure = createAction(FETCH_UTILITIES_PROVIDERS_FAILURE);

// GET /customer/utilities/life-support-equipment
export const FETCH_LIFE_SUPPORT_EQUIPMENT = 'FETCH_LIFE_SUPPORT_EQUIPMENT';
export const fetchLifeSupportEquipment = createAction(FETCH_LIFE_SUPPORT_EQUIPMENT);

export const FETCH_LIFE_SUPPORT_EQUIPMENT_REQUESTED = 'FETCH_LIFE_SUPPORT_EQUIPMENT_REQUESTED';
export const fetchLifeSupportEquipmentRequested = createAction(FETCH_LIFE_SUPPORT_EQUIPMENT_REQUESTED);

export const FETCH_LIFE_SUPPORT_EQUIPMENT_SUCCESS = 'FETCH_LIFE_SUPPORT_EQUIPMENT_SUCCESS';
export const fetchLifeSupportEquipmentSuccess = createAction(FETCH_LIFE_SUPPORT_EQUIPMENT_SUCCESS);

export const FETCH_LIFE_SUPPORT_EQUIPMENT_FAILURE = 'FETCH_LIFE_SUPPORT_EQUIPMENT_FAILURE';
export const fetchLifeSupportEquipmentFailure = createAction(FETCH_LIFE_SUPPORT_EQUIPMENT_FAILURE);

// POST /customer/utilities/url
export const FETCH_UTILITIES_URL = 'FETCH_UTILITIES_URL';
export const fetchUtilitiesUrl = createAction(FETCH_UTILITIES_URL);

export const FETCH_UTILITIES_URL_REQUESTED = 'FETCH_UTILITIES_URL_REQUESTED';
export const fetchUtilitiesUrlRequested = createAction(FETCH_UTILITIES_URL_REQUESTED);

export const FETCH_UTILITIES_URL_SUCCESS = 'FETCH_UTILITIES_URL_SUCCESS';
export const fetchUtilitiesUrlSuccess = createAction(FETCH_UTILITIES_URL_SUCCESS);

export const FETCH_UTILITIES_URL_FAILURE = 'FETCH_UTILITIES_URL_FAILURE';
export const fetchUtilitiesUrlFailure = createAction(FETCH_UTILITIES_URL_FAILURE);

// GET /customer/broadband/url
export const FETCH_BROADBAND_URL = 'FETCH_BROADBAND_URL';
export const fetchBroadbandUrl = createAction(FETCH_BROADBAND_URL);

export const FETCH_BROADBAND_URL_SUCCESS = 'FETCH_BROADBAND_URL_SUCCESS';
export const fetchBroadbandUrlSuccess = createAction(FETCH_BROADBAND_URL_SUCCESS);

export const FETCH_BROADBAND_URL_FAILURE = 'FETCH_BROADBAND_URL_FAILURE';
export const fetchBroadbandUrlFailure = createAction(FETCH_BROADBAND_URL_FAILURE);

export const FETCH_BROADBAND_URL_REQUESTED = 'FETCH_BROADBAND_URL_REQUESTED';
export const fetchBroadbandUrlRequested = createAction(FETCH_BROADBAND_URL_REQUESTED);
