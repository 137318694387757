import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import BemovedApplication from './src/index.jsx';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false })],
  tracesSampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
});

const element = document.querySelector('#bemoved-web');

ReactDOM.render(<BemovedApplication />, element);
