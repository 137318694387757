import mixpanel from 'mixpanel-browser';
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Provider } from './Context/Mixpanel';

const defaultOptions = {
  debug: process.env.NODE_ENV === 'development',
};

const AnalyticsProvider = ({ children, config, name, token = process.env.MIXPANEL_TOKEN }) => {
  const [engine, setEngine] = useState(
    new (function () {
      this.init = this.track = this.reset = () => {};
      return this;
    })(),
  );

  const { profile, isLoaded } = useSelector(({ user: { fetchUserProfileStatus: status }, user: { user } }) => {
    // Analytics profile white list
    const profile = user
      ? {
          bemovedUserId: user.identifier,
          isEmailVerified: user.isEmailVerified,
          isPhoneVerified: user.isPhoneVerified,
          role: user.role,
          email: user.email,
          mobile: user.phoneNumber,
          firstName: user.firstName,
          lastName: user.lastName,
          name: `${user.firstName} ${user.lastName}`,
          movingToPostCode: user?.movingFrom?.postcode,
          movingFromPostCode: user?.movingTo?.postcode,
          $email: user.email,
          $phone: user.phoneNumber,
          // TODO: Extend this object with:
          // createdAt // We have this in the backend
        }
      : {};

    return {
      profile,
      isLoaded: status === 'success' && !!profile?.bemovedUserId,
    };
  });

  if (isLoaded) {
    const initMixpanel = async () => {
      await mixpanel.init(
        token,
        {
          ...defaultOptions,
          ...config,
          loaded: () => {
            mixpanel.identify(profile.email);
            mixpanel.people.set(profile);
            mixpanel.register({
              deviceCategory: 'web',
              eventSource: 'Bemoved Platform',
            });
            setEngine(mixpanel);
          },
        },
        name,
      );
    };

    initMixpanel();
  }

  const value = useMemo(() => engine, [engine]);
  return <Provider value={value}>{children}</Provider>;
};

export { AnalyticsProvider };
