import {
  FETCH_CATEGORIES_REQUESTED,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from '../actions/shared-action-types';

const initialState = {
  categories: [],
  topCategories: [],
  servicesMeta: {},
  fetchCategoriesStatus: 'pending',
};

export default function shared(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_CATEGORIES_REQUESTED:
      return {
        ...state,
        fetchCategoriesStatus: 'requested',
      };
    case FETCH_CATEGORIES_SUCCESS: {
      const { categories, servicesMeta, topCategories } = payload;

      return {
        ...state,
        topCategories: [...topCategories],
        categories: [...categories],
        servicesMeta: { ...servicesMeta },
        fetchCategoriesStatus: 'success',
      };
    }
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        fetchCategoriesStatus: 'failure',
      };
    default:
      return state;
  }
}
