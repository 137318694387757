import { call, put, delay } from 'redux-saga/effects';
import { hideLoader, showLoader, toggleSnackBar } from '../actions/app-action-types';
import axiosInstance from '../utility/axios-instance';
import { getFirebaseToken } from '../config/configure-firebase';

function* HttpClient(payload, isLoader = true) {
  if (!navigator.onLine) {
    yield put(toggleSnackBar("Please make sure you're connected with internet."));

    return {
      error: true,
      result: null,
    };
  }

  if (isLoader) {
    yield put(showLoader());
    yield delay(250);
  }

  const token = yield getFirebaseToken();

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

  try {
    const request = { ...payload };
    const { data, headers } = yield call(axiosInstance, request);

    if (isLoader) {
      yield put(hideLoader());
    }

    return {
      data,
      error: null,
      headers,
    };
  } catch (error) {
    if (isLoader) {
      yield put(hideLoader());
    }

    return {
      error,
      result: null,
    };
  }
}

export default HttpClient;
