import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_UTILITIES_PROVIDERS,
  fetchUtilitiesProvidersRequested,
  fetchUtilitiesProvidersSuccess,
  fetchUtilitiesProvidersFailure,
  FETCH_LIFE_SUPPORT_EQUIPMENT,
  fetchLifeSupportEquipmentRequested,
  fetchLifeSupportEquipmentSuccess,
  fetchLifeSupportEquipmentFailure,
  FETCH_UTILITIES_URL,
  fetchUtilitiesUrlRequested,
  fetchUtilitiesUrlSuccess,
  fetchUtilitiesUrlFailure,
  FETCH_BROADBAND_URL,
  fetchBroadbandUrlRequested,
  fetchBroadbandUrlFailure,
  fetchBroadbandUrlSuccess,
} from '../actions/utilities-action-types';
import httpClient from './http-client';
import { toggleSnackBar } from '../actions/app-action-types';

export function* fetchUtilitiesProvidersHandler() {
  yield put(fetchUtilitiesProvidersRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/customer/utilities/providers`,
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(fetchUtilitiesProvidersFailure());
  } else {
    yield put(fetchUtilitiesProvidersSuccess(data));
  }
}

export function* fetchLifeSupportEquipmentHandler() {
  yield put(fetchLifeSupportEquipmentRequested());

  const request = {
    method: 'GET',
    url: `/api/v2/customer/utilities/life-support-equipment`,
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(fetchLifeSupportEquipmentFailure());
  } else {
    yield put(fetchLifeSupportEquipmentSuccess(data));
  }
}

export function* fetchUtilitiesUrlHandler({ payload }) {
  yield put(fetchUtilitiesUrlRequested());

  const request = {
    method: 'POST',
    data: payload.body,
    url: `/api/v2/customer/utilities/url`,
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(fetchUtilitiesUrlFailure());
  } else {
    yield put(fetchUtilitiesUrlSuccess(data.url));
  }
}

export function* fetchBroadbandUrlHandler({ payload }) {
  yield put(fetchBroadbandUrlRequested());

  const request = {
    method: 'POST',
    data: payload.body,
    url: '/api/v2/customer/broadband/url',
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(
      toggleSnackBar({
        type: 'error',
        message: error?.response?.data?.message || 'Something went wrong',
      }),
    );
    yield put(fetchBroadbandUrlFailure());
  } else {
    yield put(fetchBroadbandUrlSuccess(data));
  }
}

function* Utilities() {
  yield all([
    takeLatest(FETCH_UTILITIES_PROVIDERS, fetchUtilitiesProvidersHandler),
    takeLatest(FETCH_LIFE_SUPPORT_EQUIPMENT, fetchLifeSupportEquipmentHandler),
    takeLatest(FETCH_UTILITIES_URL, fetchUtilitiesUrlHandler),
    takeLatest(FETCH_BROADBAND_URL, fetchBroadbandUrlHandler),
  ]);
}

export default Utilities;
