import { createAction } from 'redux-actions';

export const SEARCH_ABN = 'SEARCH_ABN';
export const searchAbn = createAction(SEARCH_ABN);

export const SEARCH_ABN_FAILURE = 'SEARCH_ABN_FAILURE';
export const searchAbnFailure = createAction(SEARCH_ABN_FAILURE);

export const SEARCH_ABN_REQUESTED = 'SEARCH_ABN_REQUESTED';
export const searchAbnRequested = createAction(SEARCH_ABN_REQUESTED);

export const SEARCH_ABN_SUCCESS = 'SEARCH_ABN_SUCCESS';
export const searchAbnSuccess = createAction(SEARCH_ABN_SUCCESS);
