import axios from 'axios';
import { signOut } from 'firebase/auth';
import { clearUserInfo } from '../actions/user-action-types';
import configureFirebase from '../config/configure-firebase';
import configureStore from '../config/configure-store';
import { handleLoader } from '../actions/app-action-types';

const AxiosInstance = axios.create({
  baseURL: process.env.API_SERVER_URL,
  // baseURL: "http://localhost:3000",
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 1000 * 60 * 1,
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const auth = configureFirebase();

      signOut(auth);

      configureStore.dispatch(handleLoader(false));
      configureStore.dispatch(clearUserInfo());
    }
    if (error?.response?.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(error);
  },
);

export default AxiosInstance;
