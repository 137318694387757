import {
  FETCH_AGENT_FAVORITE_BUSINESSES_REQUESTED,
  FETCH_AGENT_FAVORITE_BUSINESSES_FAILURE,
  FETCH_AGENT_FAVORITE_BUSINESSES_SUCCESS,
  ADD_AGENT_FAVORITE_BUSINESSES_REQUESTED,
  ADD_AGENT_FAVORITE_BUSINESSES_FAILURE,
  ADD_AGENT_FAVORITE_BUSINESSES_SUCCESS,
  DELETE_AGENT_FAVORITE_BUSINESSES_REQUESTED,
  DELETE_AGENT_FAVORITE_BUSINESSES_FAILURE,
  DELETE_AGENT_FAVORITE_BUSINESSES_SUCCESS,
  FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_REQUESTED,
  FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_FAILURE,
  FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_SUCCESS,
} from '../actions/agent-favorite-business-action-types';

const initialState = {
  agentFavoriteBusinessList: [],
  agentServiceDirectoryByKeyword: [],
  isAgentFavoriteBusinessesListEmpty: false,
  fetchAgentFavoriteBusinessesStatus: 'pending',
  addAgentFavoriteBusinessesStatus: 'pending',
  deleteAgentFavoriteBusinessesStatus: 'pending',
  fetchAgentServiceDirectoryByKeywordStatus: 'pending',
};

export default function agentFavoriteBusiness(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_AGENT_FAVORITE_BUSINESSES_REQUESTED:
      return {
        ...state,
        fetchAgentFavoriteBusinessesStatus: 'requested',
        agentFavoriteBusinessList: [],
      };
    case FETCH_AGENT_FAVORITE_BUSINESSES_SUCCESS: {
      return {
        ...state,
        agentFavoriteBusinessList: payload.data,
        isAgentFavoriteBusinessesListEmpty: payload.isFavoriteBusinessListEmpty,
        fetchAgentFavoriteBusinessesStatus: 'success',
      };
    }
    case FETCH_AGENT_FAVORITE_BUSINESSES_FAILURE:
      return {
        ...state,
        fetchAgentFavoriteBusinessesStatus: 'failure',
        agentFavoriteBusinessList: [],
      };
    case ADD_AGENT_FAVORITE_BUSINESSES_REQUESTED:
      return {
        ...state,
        addAgentFavoriteBusinessesStatus: 'requested',
      };
    case ADD_AGENT_FAVORITE_BUSINESSES_SUCCESS: {
      return {
        ...state,
        addAgentFavoriteBusinessesStatus: 'success',
      };
    }
    case ADD_AGENT_FAVORITE_BUSINESSES_FAILURE:
      return {
        ...state,
        addAgentFavoriteBusinessesStatus: 'failure',
      };
    case DELETE_AGENT_FAVORITE_BUSINESSES_REQUESTED:
      return {
        ...state,
        deleteAgentFavoriteBusinessesStatus: 'requested',
      };
    case DELETE_AGENT_FAVORITE_BUSINESSES_SUCCESS: {
      return {
        ...state,
        deleteAgentFavoriteBusinessesStatus: 'success',
      };
    }
    case DELETE_AGENT_FAVORITE_BUSINESSES_FAILURE:
      return {
        ...state,
        deleteAgentFavoriteBusinessesStatus: 'failure',
      };
    case FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_REQUESTED:
      return {
        ...state,
        agentServiceDirectoryByKeyword: [],
        fetchAgentServiceDirectoryByKeywordStatus: 'requested',
      };
    case FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        agentServiceDirectoryByKeyword: payload.data,
        fetchAgentServiceDirectoryByKeywordStatus: 'success',
      };
    case FETCH_AGENT_SERVICE_DIRECTORY_BY_KEYWORD_FAILURE:
      return {
        ...state,
        fetchAgentServiceDirectoryByKeywordStatus: 'failure',
      };
    default:
      return state;
  }
}
