import {
  FETCH_CONNECTED_USERS_FAILURE,
  FETCH_CONNECTED_USERS_REQUESTED,
  FETCH_CONNECTED_USERS_SUCCESS,
} from '../actions/connected-customers-action-types';

const initialState = {
  fetchConnectedUsersStatus: 'pending',
  connectedUsers: [],
};

export default function connectedUsers(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_CONNECTED_USERS_REQUESTED:
      return {
        ...state,
        fetchConnectedUsersStatus: 'requested',
      };
    case FETCH_CONNECTED_USERS_FAILURE:
      return {
        ...state,
        fetchConnectedUsersStatus: 'failure',
      };
    case FETCH_CONNECTED_USERS_SUCCESS:
      return {
        ...state,
        fetchConnectedUsersStatus: 'success',
        connectedUsers: payload?.map((element, index) => ({
          ...element,
          id: index,
        })),
      };
    default:
      return state;
  }
}
