import {
  HIDE_LOADER,
  SHOW_LOADER,
  HANDLE_LOADER,
  HIDE_SNACKBAR,
  SHOW_SNACKBAR,
  TOGGLE_DRAWER,
  TOGGLE_DRAWER_MENU,
  TOGGLE_MY_ACCOUNT_POPUP,
  TOGGLE_MOBILE_VERIFICATION,
  SET_CUSTOMER_CARDS_FILTER,
  SET_REMOTE_CONFIG,
} from '../actions/app-action-types';
import { UPDATE_USER_ONBOARDING_DETAILS } from '../actions/user-action-types';

const initialState = {
  showProfilePopup: false,
  myAccountPopupStatus: false,
  activeDrawerMenu: null,
  drawer: 'visible',
  locale: null,
  snackbarVisible: {
    message: 'Error',
    position: {
      horizontal: 'right',
      vertical: 'top',
    },
    severity: 'error',
    timeout: 3000,
    visible: false,
  },
  visible: false,
  userOnBoardingDetails: {},
  mobileVerification: false,
  customerCardsFilter: 'All',
  remoteConfig: null,
};

export default function app(state = initialState, { payload, type }) {
  switch (type) {
    case HANDLE_LOADER:
      return {
        ...state,
        visible: payload,
      };

    case HIDE_LOADER:
      return {
        ...state,
        visible: false,
      };

    case SHOW_LOADER:
      return {
        ...state,
        visible: true,
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbarVisible: {
          position: {
            horizontal: 'right',
            vertical: 'top',
          },
          severity: 'error',
          timeout: 3000,
          visible: false,
        },
      };

    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarVisible: {
          ...state.snackbarVisible,
          ...payload,
        },
      };

    case TOGGLE_DRAWER:
      return {
        ...state,
        drawer: payload,
      };

    case TOGGLE_DRAWER_MENU:
      return {
        ...state,
        activeDrawerMenu: payload,
      };

    case UPDATE_USER_ONBOARDING_DETAILS: {
      return {
        ...state,
        userOnBoardingDetails: {
          ...state.userOnBoardingDetails,
          ...payload,
        },
      };
    }

    case TOGGLE_MY_ACCOUNT_POPUP:
      return {
        ...state,
        myAccountPopupStatus: payload,
      };

    case TOGGLE_MOBILE_VERIFICATION:
      return {
        ...state,
        mobileVerification: payload,
      };
    case SET_CUSTOMER_CARDS_FILTER:
      return {
        ...state,
        customerCardsFilter: payload,
      };
    case SET_REMOTE_CONFIG:
      return {
        ...state,
        remoteConfig: payload,
      };
    default:
      return state;
  }
}
