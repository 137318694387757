import { createAction } from 'redux-actions';

export const FETCH_JOBS = 'FETCH_JOBS';
export const fetchJobs = createAction(FETCH_JOBS);

export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';
export const fetchJobsFailure = createAction(FETCH_JOBS_FAILURE);

export const FETCH_JOBS_REQUESTED = 'FETCH_JOBS_REQUESTED';
export const fetchJobsRequested = createAction(FETCH_JOBS_REQUESTED);

export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const fetchJobsSuccess = createAction(FETCH_JOBS_SUCCESS);

// POST /v2/business/jobs
export const CREATE_JOB_BY_QUOTE_ID = 'CREATE_JOB_BY_QUOTE_ID';
export const createJobByQuoteId = createAction(CREATE_JOB_BY_QUOTE_ID);

export const CREATE_JOB_BY_QUOTE_ID_REQUESTED = 'CREATE_JOB_BY_QUOTE_ID_REQUESTED';
export const createJobByQuoteIdRequested = createAction(CREATE_JOB_BY_QUOTE_ID_REQUESTED);

export const CREATE_JOB_BY_QUOTE_ID_FAILURE = 'CREATE_JOB_BY_QUOTE_ID_FAILURE';
export const createJobByQuoteIdFailure = createAction(CREATE_JOB_BY_QUOTE_ID_FAILURE);

export const CREATE_JOB_BY_QUOTE_ID_SUCCESS = 'CREATE_JOB_BY_QUOTE_ID_SUCCESS';
export const createJobByQuoteIdSuccess = createAction(CREATE_JOB_BY_QUOTE_ID_SUCCESS);

// PUT /v2/business/jobs/{jobId}/deliveredDate
export const UPDATE_JOB_DELIVERED_DATE = 'UPDATE_JOB_DELIVERED_DATE';
export const updateJobDeliveredDate = createAction(UPDATE_JOB_DELIVERED_DATE);

export const UPDATE_JOB_DELIVERED_DATE_REQUESTED = 'UPDATE_JOB_DELIVERED_DATE_REQUESTED';
export const updateJobDeliveredDateRequested = createAction(UPDATE_JOB_DELIVERED_DATE_REQUESTED);

export const UPDATE_JOB_DELIVERED_DATE_SUCCESS = 'UPDATE_JOB_DELIVERED_DATE_SUCCESS';
export const updateJobDeliveredDateSuccess = createAction(UPDATE_JOB_DELIVERED_DATE_SUCCESS);

export const UPDATE_JOB_DELIVERED_DATE_FAILURE = 'UPDATE_JOB_DELIVERED_DATE_FAILURE';
export const updateJobDeliveredDateFailure = createAction(UPDATE_JOB_DELIVERED_DATE_FAILURE);
