import React from 'react';
import { bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { Watch } from 'react-loader-spinner';
import Backdrop from '@mui/material/Backdrop';

import { LoadingIcon } from './constants/images';

export const SpinningLoader = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: '25vmin' }}>
    <defs>
      <clipPath id="cut-off">
        <rect x="0" y="11" width="22" height="22" />
      </clipPath>
      <linearGradient id="gradient">
        <stop offset="0" stopColor="currentColor"></stop>
        <stop offset="100%" stopColor="currentColor" stopOpacity="0"></stop>
      </linearGradient>
    </defs>
    <circle cx="11" cy="11" r="10" stroke="url(#gradient)" strokeWidth="2" fill="none" clipPath="url(#cut-off)">
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 11 11"
        to="360 11 11"
        dur={'2s'}
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export const PulsingLoader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style={{ height: '25vmin' }}
  >
    <circle cx="50" cy="50" r="0" fill="none" stroke="#f66c47" strokeWidth="2">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1s"
        values="0;40"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
        begin="0s"
      ></animate>
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="50" cy="50" r="0" fill="none" stroke="#ffebd7" strokeWidth="2">
      <animate
        attributeName="r"
        repeatCount="indefinite"
        dur="1s"
        values="0;40"
        keyTimes="0;1"
        keySplines="0 0.2 0.8 1"
        calcMode="spline"
        begin="-0.5s"
      ></animate>
      <animate
        attributeName="opacity"
        repeatCount="indefinite"
        dur="1s"
        values="1;0"
        keyTimes="0;1"
        keySplines="0.2 0 0.8 1"
        calcMode="spline"
        begin="-0.5s"
      ></animate>
    </circle>
  </svg>
);

const Loader = ({ visible }) => {
  const active = useSelector((store) => store.app.visible);

  return visible || active ? (
    <Backdrop
      open
      sx={{
        color: '#F66C47',
        zIndex: (theme) => theme.zIndex.drawer + 99999999999999,
      }}
      onClick={() => {}}
    >
      <PulsingLoader />
    </Backdrop>
  ) : null;
};

Loader.propTypes = { visible: bool };
Loader.defaultProps = { visible: false };

export default Loader;
