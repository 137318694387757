import { all, call, put, takeLatest } from 'redux-saga/effects';
import httpClient from './http-client';
import {
  fetchJobsFailure,
  fetchJobsRequested,
  fetchJobsSuccess,
  FETCH_JOBS,
  CREATE_JOB_BY_QUOTE_ID,
  createJobByQuoteIdRequested,
  createJobByQuoteIdFailure,
  createJobByQuoteIdSuccess,
  UPDATE_JOB_DELIVERED_DATE,
  updateJobDeliveredDateRequested,
  updateJobDeliveredDateSuccess,
  updateJobDeliveredDateFailure,
} from '../actions/jobs-actions-types';
import { toggleSnackBar } from '../actions/app-action-types';
import { GENERAL_ERROR_MESSAGE } from '@Constants';

export function* fetchAllJobsHandler() {
  yield put(fetchJobsRequested());

  const request = {
    method: 'GET',
    url: '/api/v2/business/jobs',
  };

  const { data, error } = yield call(httpClient, request, true);

  if (error) {
    yield put(fetchJobsFailure(error));
  } else {
    yield put(fetchJobsSuccess(data));
  }
}

export function* createJobByQuoteIdHandler({ payload }) {
  yield put(createJobByQuoteIdRequested());

  const { quoteId } = payload;

  const requestBody = { ...payload };
  delete requestBody.quoteId;

  const request = {
    method: 'POST',
    data: requestBody,
    url: `/api/v2/business/quotes/${quoteId}/jobs`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(toggleSnackBar(error.response?.data?.message ?? GENERAL_ERROR_MESSAGE));
    yield put(createJobByQuoteIdFailure());
  } else {
    yield put(createJobByQuoteIdSuccess());
  }
}

function* updateJobDeliveredDateStatus({ payload }) {
  yield put(updateJobDeliveredDateRequested());

  const { jobId } = payload.param;
  const body = payload.body;

  const request = {
    method: 'PUT',
    url: `/api/v2/business/jobs/${jobId}/delivered-date`,
    data: body,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(updateJobDeliveredDateFailure());
  } else {
    yield put(updateJobDeliveredDateSuccess());
  }
}

function* Job() {
  yield all([
    takeLatest(FETCH_JOBS, fetchAllJobsHandler),
    takeLatest(CREATE_JOB_BY_QUOTE_ID, createJobByQuoteIdHandler),
    takeLatest(UPDATE_JOB_DELIVERED_DATE, updateJobDeliveredDateStatus),
  ]);
}

export default Job;
