import React from 'react';
import loadable from '@loadable/component';
import Pages from './pages.jsx';
import { useRemoteConfigFlag as UseRemoteConfigFlag } from '@Hooks';

// Common
const Dashboard = loadable(() => import('../pages/dashboard/index.jsx'));
const Calendar = loadable(() => import('../pages/calendar/index.jsx'));
const Chat = loadable(() => import('../pages/chat/index.jsx'));
const Tips = loadable(() => import('../pages/tips/index.jsx'));
const NotFound = loadable(() => import('../pages/not-found.jsx'));
const ServiceDirectory = loadable(() => import('../pages/service-directory'));
const BusinessActivateAccount = loadable(() => import('../pages/business-activate-account/index.jsx'));

const EmailVerified = loadable(() => import('../pages/user-auth-related/email-verified.jsx'));
const LoginPage = loadable(() => import('../pages/user-auth-related/index.jsx'));
const SignUpPage = loadable(() => import('../pages/sign-up/index'));

// Agent
const ConnectedCustomers = loadable(() => import('../pages/connected-customers/index.jsx'));

// Business
const BusinessProfilePage = loadable(() => import('../pages/business-profile/business-user-settings.jsx'));
const BusinessUserSettings = loadable(() => import('../pages/business-profile/index.jsx'));
const Jobs = loadable(() => import('../pages/jobs/index.jsx'));

// Customer
const MyProperties = loadable(() => import('../pages/my-properties/index.jsx'));
const Tasks = loadable(() => import('../pages/tasks/index.jsx'));
const MyCommunity = loadable(() => import('../pages/my-community/index.jsx'));
const Coaching = loadable(() => import('../pages/coaching/index.jsx'));

export default function configureRoutes() {
  const remoteConfigFlag = UseRemoteConfigFlag();

  /**
   * If you wanna hide routes that available within remoteConfigFlag, please define here
   * remoteConfigName: flag name, come from remote config
   * path: path name to be hidden
   */
  const configRoutes = [
    {
      remoteConfigName: 'enable_consumer_sign_up',
      path: '/consumer/signup',
    },
    {
      remoteConfigName: 'enable_chat',
      path: ['chat', '/chat/:publicId?'],
    },
  ];

  const allRoutes = [
    {
      accessible: true,
      element: <EmailVerified />,
      path: '/email-verified',
      title: 'BeMoved',
      type: 'public',
    },
    {
      accessible: true,
      element: <LoginPage />,
      path: '/',
      title: 'BeMoved',
      type: 'public',
    },
    {
      accessible: true,
      element: <SignUpPage />,
      path: '/signup',
      title: 'BeMoved',
      type: 'public',
    },
    //= =======Agent============
    {
      accessible: true,
      element: <ConnectedCustomers />,
      path: '/referrals',
      title: 'Connected Clients',
      type: 'private',
    },
    //= =========End of Agent==============
    //= ============Business=============
    {
      accessible: true,
      element: <BusinessUserSettings />,
      path: '/business-user-settings',
      title: 'User Settings',
      type: 'private',
    },
    {
      accessible: true,
      element: <Calendar />,
      path: '/calendar',
      title: 'Calendar',
      type: 'private',
    },
    {
      accessible: true,
      element: <Jobs />,
      path: '/jobs',
      title: 'Jobs',
      type: 'private',
    },
    {
      accessible: true,
      element: <BusinessProfilePage />,
      path: Pages.Profile,
      title: 'User Profile',
      type: 'private',
    },
    //= ===========End of Business===========
    // =========== Customer =============
    {
      accessible: true,
      element: <Tasks />,
      path: '/tasks',
      title: 'Tasks',
      type: 'private',
    },
    {
      accessible: true,
      element: <MyCommunity />,
      path: '/my-community',
      title: 'My Community',
      type: 'private',
    },
    {
      accessible: true,
      element: <Calendar />,
      path: '/calendar',
      title: 'Calendar',
      type: 'private',
    },
    {
      accessible: true,
      element: <MyProperties />,
      path: '/my-properties',
      title: 'My Properties',
      type: 'private',
    },
    // ============= End of Customer =========
    {
      accessible: true,
      element: <BusinessActivateAccount />,
      path: '/activate-account',
      title: 'BeMoved',
      type: 'public',
    },
    {
      accessible: true,
      element: <LoginPage />,
      path: '/login',
      title: 'BeMoved',
      type: 'public',
    },
    {
      accessible: true,
      element: <Dashboard />,
      path: '/home',
      title: 'Dashboard',
      type: 'private',
    },
    // hack routes
    {
      accessible: true,
      element: <BusinessActivateAccount />,
      path: '/activate-account',
      title: 'BeMoved',
      type: 'public',
    },
    ...['/chat', '/chat/:publicId'].map((path) => ({
      key: 'chat',
      accessible: true,
      element: <Chat />,
      path,
      title: 'Chat',
      type: 'private',
    })),
    ...[
      Pages.ServiceDirectory,
      `${Pages.ServiceDirectory}/search`,
      `${Pages.ServiceDirectory}/:category`,
      `${Pages.ServiceDirectory}/:category/:service`,
    ].map((path) => ({
      key: 'service-directory',
      accessible: true,
      element: <ServiceDirectory />,
      path,
      title: 'Service Directory',
      type: 'private',
    })),
    {
      accessible: true,
      element: <Tips />,
      path: '/tips',
      title: 'Guides, Tips & Hacks',
      type: 'private',
    },
    {
      accessible: true,
      element: <Coaching />,
      path: '/coaching',
      title: 'Coaching cards',
      type: 'private',
    },
    {
      accessible: true,
      element: <NotFound />,
      path: '/*',
      title: '404 Not Found',
      type: 'public',
    },
  ];

  const listKey = Object.keys(remoteConfigFlag);
  // check availability remoteConfigName from configRoutes and the value of remoteConfigName
  const filteredRoutes = configRoutes.filter((route) => {
    return listKey.includes(route.remoteConfigName) && !remoteConfigFlag[route.remoteConfigName];
  });

  if (!filteredRoutes.length) return allRoutes;

  const mappedFilter = filteredRoutes.map((fil) => fil.path);
  const result = allRoutes.filter((router) => !mappedFilter.includes(router.path));

  return result;
}
