export const DEFAULT_REMOTE_CONFIG_FLAGS = {
  enable_cimet_broadband: false,
  enable_consumer_service_directory: true,
  enable_agent_service_directory: true,
  enable_consumer_sign_up: true,
  enable_invite_customer: true,
  consumer_signup_countries: 'au,gb,us,id,nz',
  enable_social_sign_up: false,
  enable_chat: false,
  disable_web_url: '',
};
