import { createAction } from 'redux-actions';

// GET /api/v2/customer/service-directory
export const FETCH_SERVICE_DIRECTORY = 'FETCH_SERVICE_DIRECTORY';
export const fetchServiceDirectory = createAction(FETCH_SERVICE_DIRECTORY);

export const FETCH_SERVICE_DIRECTORY_REQUESTED = 'FETCH_SERVICE_DIRECTORY_REQUESTED';
export const fetchServiceDirectoryRequested = createAction(FETCH_SERVICE_DIRECTORY_REQUESTED);

export const FETCH_SERVICE_DIRECTORY_SUCCESS = 'FETCH_SERVICE_DIRECTORY_SUCCESS';
export const fetchServiceDirectorySuccess = createAction(FETCH_SERVICE_DIRECTORY_SUCCESS);

export const FETCH_SERVICE_DIRECTORY_FAILURE = 'FETCH_SERVICE_DIRECTORY_FAILURE';
export const fetchServiceDirectoryFailure = createAction(FETCH_SERVICE_DIRECTORY_FAILURE);

// GET /api/v2/customer/favourite-businesses
export const FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES = 'FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES';
export const fetchConnectedAgentFavouriteBusinesses = createAction(FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES);

export const FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_REQUESTED =
  'FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_REQUESTED';
export const fetchConnectedAgentFavouriteBusinessesRequested = createAction(
  FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_REQUESTED,
);

export const FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_SUCCESS = 'FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_SUCCESS';
export const fetchConnectedAgentFavouriteBusinessesSuccess = createAction(
  FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_SUCCESS,
);

export const FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_FAILURE = 'FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_FAILURE';
export const fetchConnectedAgentFavouriteBusinessesFailure = createAction(
  FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_FAILURE,
);

// GET V2 /customer/service-directory-search
export const FETCH_SERVICE_DIRECTORIES_BY_KEYWORD = 'FETCH_SERVICE_DIRECTORIES_BY_KEYWORD';
export const fetchServiceDirectoriesByKeyword = createAction(FETCH_SERVICE_DIRECTORIES_BY_KEYWORD);

export const FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_REQUESTED = 'FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_REQUESTED';
export const fetchServiceDirectoriesByKeywordRequested = createAction(FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_REQUESTED);

export const FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_SUCCESS = 'FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_SUCCESS';
export const fetchServiceDirectoriesByKeywordRequestedSuccess = createAction(
  FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_SUCCESS,
);

export const FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_FAILURE = 'FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_FAILURE';
export const fetchServiceDirectoriesByKeywordRequestedFailure = createAction(
  FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_FAILURE,
);
