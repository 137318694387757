import { createAction } from 'redux-actions';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const fetchCategories = createAction(FETCH_CATEGORIES);

export const FETCH_CATEGORIES_REQUESTED = 'FETCH_CATEGORIES_REQUESTED';
export const fetchCategoriesRequested = createAction(FETCH_CATEGORIES_REQUESTED);

export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);

export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const fetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);
