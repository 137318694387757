import React from 'react';
import configureRoutes from './routes.jsx';
import Redirector from './redirector.jsx';
import { useRemoteConfigFlag } from '@Hooks';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

const RoutesRoot = () => {
  const routes = configureRoutes();
  useRemoteConfigFlag();

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <Route key={route.key || route.path} path={route.path} element={<Redirector {...route} />}>
            <Route path={route.path} element={route.element} />
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesRoot;
