import { format } from 'date-fns';
import React from 'react';
import { useLocation } from 'react-router-dom';

const formatDate = (date, type) => {
  try {
    return format(new Date(date), type);
  } catch (error) {
    return '';
  }
};

const copyTextToClipboard = (text) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }

  return document.execCommand('copy', true, text);
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export { formatDate as default, copyTextToClipboard, useQuery };
