import {
  FETCH_SERVICE_DIRECTORY_REQUESTED,
  FETCH_SERVICE_DIRECTORY_FAILURE,
  FETCH_SERVICE_DIRECTORY_SUCCESS,
  FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_REQUESTED,
  FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_SUCCESS,
  FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_FAILURE,
  FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_REQUESTED,
  FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_SUCCESS,
  FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_FAILURE,
} from '../actions/service-directory-action-types';

const initialState = {
  serviceDirectories: [],
  connectedAgentFavouriteBusinesses: [],
  serviceDirectoriesByKeyword: [],
  fetchServiceDirectoriesStatus: 'pending',
  fetchConnectedAgentFavouriteBusinessStatus: 'pending',
  fetchServiceDirectoriesByKeywordStatus: 'pending',
};

export default function serviceDirectory(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_SERVICE_DIRECTORY_REQUESTED:
      return {
        ...state,
        fetchServiceDirectoriesStatus: 'requested',
      };
    case FETCH_SERVICE_DIRECTORY_SUCCESS: {
      return {
        ...state,
        serviceDirectories: payload.data,
        fetchServiceDirectoriesStatus: 'success',
      };
    }
    case FETCH_SERVICE_DIRECTORY_FAILURE:
      return {
        ...state,
        fetchServiceDirectoriesStatus: 'failure',
      };
    case FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_REQUESTED:
      return {
        ...state,
        fetchConnectedAgentFavouriteBusinessStatus: 'requested',
      };
    case FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_SUCCESS:
      return {
        ...state,
        connectedAgentFavouriteBusinesses: payload.data,
        fetchConnectedAgentFavouriteBusinessStatus: 'success',
      };
    case FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES_FAILURE:
      return {
        ...state,
        fetchConnectedAgentFavouriteBusinessStatus: 'failure',
      };
    case FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_REQUESTED:
      return {
        ...state,
        fetchServiceDirectoriesByKeywordStatus: 'requested',
      };
    case FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        serviceDirectoriesByKeyword: payload.data,
        fetchServiceDirectoriesByKeywordStatus: 'success',
      };
    case FETCH_SERVICE_DIRECTORIES_BY_KEYWORD_FAILURE:
      return {
        ...state,
        fetchServiceDirectoriesByKeywordStatus: 'failure',
      };
    default:
      return state;
  }
}
