import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  SEARCH_ABN,
  searchAbnFailure,
  searchAbnRequested,
  searchAbnSuccess,
  SEND_ABN_INVITE,
} from '../actions/abn-action-types';
import { toggleSnackBar } from '../actions/app-action-types';
import httpClient from './http-client';

export function* searchAbnHandler({ payload }) {
  yield put(searchAbnRequested());

  const request = {
    params: {
      abn: payload,
      guid: 'c55b24a8-e1aa-442f-84cf-7f64413c4986',
    },
    method: 'GET',
    url: 'https://abr.business.gov.au/json/AbnDetails.aspx',
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(searchAbnFailure(error));
  } else {
    yield put(searchAbnSuccess(data));
  }
}

function* Locations() {
  yield all([takeLatest(SEARCH_ABN, searchAbnHandler)]);
}

export default Locations;
