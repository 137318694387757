import { useSelector, useDispatch } from 'react-redux';
import {
  addAgentFavoriteBusinesses,
  fetchAgentFavoriteBusinesses,
  deleteAgentFavoriteBusinesses,
} from '../../actions/agent-favorite-business-action-types';

export const useAgentFavouriteBusiness = () => {
  const dispatch = useDispatch();

  const {
    agentFavoriteBusinessList,
    fetchAgentFavoriteBusinessesStatus,
    agentServiceDirectoryByKeyword,
    fetchAgentServiceDirectoryByKeywordStatus,
  } = useSelector((store) => ({
    agentFavoriteBusinessList: store.agentFavoriteBusiness.agentFavoriteBusinessList,
    fetchAgentFavoriteBusinessesStatus: store.agentFavoriteBusiness.fetchAgentFavoriteBusinessesStatus,
    agentServiceDirectoryByKeyword: store.agentFavoriteBusiness.agentServiceDirectoryByKeyword,
    fetchAgentServiceDirectoryByKeywordStatus: store.agentFavoriteBusiness.fetchAgentServiceDirectoryByKeywordStatus,
  }));

  const getAgentFavouriteBusinessByBusinessId = (businessId) => {
    return agentFavoriteBusinessList.find((business) => business.businessId === businessId);
  };

  const addToFavourite = (businessId) => {
    const sagaPayload = {
      meta: {
        callback: () => {
          dispatch(fetchAgentFavoriteBusinesses());
        },
      },
      body: { businessId },
    };

    dispatch(addAgentFavoriteBusinesses(sagaPayload));
  };

  const deleteFromFavourite = (favouriteId) => {
    const sagaPayload = {
      meta: {
        callback: () => {
          dispatch(fetchAgentFavoriteBusinesses());
        },
      },
      param: { itemID: favouriteId },
    };

    dispatch(deleteAgentFavoriteBusinesses(sagaPayload));
  };

  const toggleFavourite = (businessId) => {
    const business = getAgentFavouriteBusinessByBusinessId(businessId);

    const hasFavourited = !!business;
    if (hasFavourited) {
      deleteFromFavourite(business.identifier);
    } else {
      addToFavourite(businessId);
    }
  };

  return {
    fetchAgentFavoriteBusinessesStatus,
    agentFavoriteBusinessList,
    getAgentFavouriteBusinessByBusinessId,
    addToFavourite,
    deleteFromFavourite,
    toggleFavourite,
    agentServiceDirectoryByKeyword,
    fetchAgentServiceDirectoryByKeywordStatus,
  };
};
