import { all, delay, put, takeLatest } from 'redux-saga/effects';
import { hideSnackbar, showSnackbar, TOGGLE_SNACK_BAR } from '../actions/app-action-types';

function* handleSnackBar({ payload }) {
  const updates = {
    message: typeof payload === 'string' ? payload : payload.message || 'Error',
    position:
      typeof payload !== 'string' && payload.position
        ? payload.position
        : {
            horizontal: 'right',
            vertical: 'top',
          },
    severity: typeof payload === 'string' ? 'error' : payload.severity || 'error',
    timeout: typeof payload === 'string' ? 3000 : payload.timeout || 3000,
    visible: true,
  };

  yield put(showSnackbar(updates));
  yield delay(3000);
  yield put(hideSnackbar());
}

function* App() {
  yield all([takeLatest(TOGGLE_SNACK_BAR, handleSnackBar)]);
}

export default App;
