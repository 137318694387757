import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  FETCH_CONNECTED_USERS,
  fetchConnectedUsersFailure,
  fetchConnectedUsersRequested,
  fetchConnectedUsersSuccess,
} from '../actions/connected-customers-action-types';
import httpClient from './http-client';

export function* fetchConnectedUsersHandler({ payload }) {
  yield put(fetchConnectedUsersRequested());
  const request = {
    method: 'GET',
    url: `/api/v2/${payload}/connected-users`,
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchConnectedUsersFailure(data));
  } else {
    yield put(fetchConnectedUsersSuccess(data));
  }
}

function* ConnectedUsers() {
  yield all([takeLatest(FETCH_CONNECTED_USERS, fetchConnectedUsersHandler)]);
}

export default ConnectedUsers;
