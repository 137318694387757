import { createAction } from 'redux-actions';

// create new quote
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const createQuote = createAction(CREATE_QUOTE);

export const CREATE_QUOTE_PENDING = 'CREATE_QUOTE_PENDING';
export const createQuotePending = createAction(CREATE_QUOTE_PENDING);

export const CREATE_QUOTE_REQUESTED = 'CREATE_QUOTE_REQUESTED';
export const createQuoteRequested = createAction(CREATE_QUOTE_REQUESTED);

export const CREATE_QUOTE_FAILURE = 'CREATE_QUOTE_FAILURE';
export const createQuoteFailure = createAction(CREATE_QUOTE_FAILURE);

export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const createQuoteSuccess = createAction(CREATE_QUOTE_SUCCESS);

export const FETCH_CUSTOMER_QUOTES = 'FETCH_CUSTOMER_QUOTES';
export const fetchCustomerQuotes = createAction(FETCH_CUSTOMER_QUOTES);

export const FETCH_CUSTOMER_QUOTES_FAILURE = 'FETCH_CUSTOMER_QUOTES_FAILURE';
export const fetchCustomerQuotesFailure = createAction(FETCH_CUSTOMER_QUOTES_FAILURE);

export const FETCH_CUSTOMER_QUOTES_REQUESTED = 'FETCH_CUSTOMER_QUOTES_REQUESTED';
export const fetchCustomerQuotesRequested = createAction(FETCH_CUSTOMER_QUOTES_REQUESTED);

export const FETCH_CUSTOMER_QUOTES_SUCCESS = 'FETCH_CUSTOMER_QUOTES_SUCCESS';
export const fetchCustomerQuotesSuccess = createAction(FETCH_CUSTOMER_QUOTES_SUCCESS);

// GET /V2/business/quotes
export const FETCH_SERVICE_PROVIDER_QUOTES = 'FETCH_SERVICE_PROVIDER_QUOTES';
export const fetchServiceProviderQuotes = createAction(FETCH_SERVICE_PROVIDER_QUOTES);

export const FETCH_SERVICE_PROVIDER_QUOTES_REQUESTED = 'FETCH_SERVICE_PROVIDER_QUOTES_REQUESTED';
export const fetchServiceProviderQuotesRequested = createAction(FETCH_SERVICE_PROVIDER_QUOTES_REQUESTED);

export const FETCH_SERVICE_PROVIDER_QUOTES_FAILURE = 'FETCH_SERVICE_PROVIDER_QUOTES_FAILURE';
export const fetchServiceProviderQuotesFailure = createAction(FETCH_SERVICE_PROVIDER_QUOTES_FAILURE);

export const FETCH_SERVICE_PROVIDER_QUOTES_SUCCESS = 'FETCH_SERVICE_PROVIDER_QUOTES_SUCCESS';
export const fetchServiceProviderQuotesSuccess = createAction(FETCH_SERVICE_PROVIDER_QUOTES_SUCCESS);

// GET quote by child task
export const FETCH_QUOTE_BY_CHILD_TASK = 'FETCH_QUOTE_BY_CHILD_TASK';
export const fetchQuoteByChildTask = createAction(FETCH_QUOTE_BY_CHILD_TASK);

export const FETCH_QUOTE_BY_CHILD_TASK_REQUESTED = 'FETCH_QUOTE_BY_CHILD_TASK_REQUESTED';
export const fetchQuoteByChildTaskRequested = createAction(FETCH_QUOTE_BY_CHILD_TASK_REQUESTED);

export const FETCH_QUOTE_BY_CHILD_TASK_FAILURE = 'FETCH_QUOTE_BY_CHILD_TASK_FAILURE';
export const fetchQuoteByChildTaskFailure = createAction(FETCH_QUOTE_BY_CHILD_TASK_FAILURE);

export const FETCH_QUOTE_BY_CHILD_TASK_SUCCESS = 'FETCH_QUOTE_BY_CHILD_TASK_SUCCESS';
export const fetchQuoteByChildTaskSuccess = createAction(FETCH_QUOTE_BY_CHILD_TASK_SUCCESS);

// PUT /customer/quotes/{quoteId}/cancel
export const CANCEL_QUOTE_REQUEST = 'CANCEL_QUOTE_REQUEST';
export const cancelQuoteRequest = createAction(CANCEL_QUOTE_REQUEST);

export const CANCEL_QUOTE_REQUEST_REQUESTED = 'CANCEL_QUOTE_REQUEST_REQUESTED';
export const cancelQuoteRequestRequested = createAction(CANCEL_QUOTE_REQUEST_REQUESTED);

export const CANCEL_QUOTE_REQUEST_FAILURE = 'CANCEL_QUOTE_REQUEST_FAILURE';
export const cancelQuoteRequestFailure = createAction(CANCEL_QUOTE_REQUEST_FAILURE);

export const CANCEL_QUOTE_REQUEST_SUCCESS = 'CANCEL_QUOTE_REQUEST_SUCCESS';
export const cancelQuoteRequestSuccess = createAction(CANCEL_QUOTE_REQUEST_SUCCESS);

// PUT /customer/quotes/{quoteId}/jobs/{jobId}/accept
export const QUOTE_JOBS_ACCEPT = 'QUOTE_JOBS_ACCEPT';
export const quoteJobsAccept = createAction(QUOTE_JOBS_ACCEPT);

export const QUOTE_JOBS_ACCEPT_REQUESTED = 'QUOTE_JOBS_ACCEPT_REQUESTED';
export const quoteJobsAcceptRequested = createAction(QUOTE_JOBS_ACCEPT_REQUESTED);

export const QUOTE_JOBS_ACCEPT_SUCCESS = 'QUOTE_JOBS_ACCEPT_SUCCESS';
export const quoteJobsAcceptSuccess = createAction(QUOTE_JOBS_ACCEPT_SUCCESS);

export const QUOTE_JOBS_ACCEPT_FAILURE = 'QUOTE_JOBS_ACCEPT_FAILURE';
export const quoteJobsAcceptFailure = createAction(QUOTE_JOBS_ACCEPT_FAILURE);

// PUT /customer/quotes/{quoteId}/jobs/{jobId}/decline
export const QUOTE_JOBS_DECLINE = 'QUOTE_JOBS_DECLINE';
export const quoteJobsDecline = createAction(QUOTE_JOBS_DECLINE);

export const QUOTE_JOBS_DECLINE_REQUESTED = 'QUOTE_JOBS_DECLINE_REQUESTED';
export const quoteJobsDeclineRequested = createAction(QUOTE_JOBS_DECLINE_REQUESTED);

export const QUOTE_JOBS_DECLINE_SUCCESS = 'QUOTE_JOBS_DECLINE_SUCCESS';
export const quoteJobsDeclineSuccess = createAction(QUOTE_JOBS_DECLINE_SUCCESS);

export const QUOTE_JOBS_DECLINE_FAILURE = 'QUOTE_JOBS_DECLINE_FAILURE';
export const quoteJobsDeclineFailure = createAction(QUOTE_JOBS_DECLINE_FAILURE);
