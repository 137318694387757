import { all } from 'redux-saga/effects';
import app from './app';
import fileDownloader from './file-downloader';
import locations from './locations';
import task from './task';
import user from './user';
import myProperties from './my-properties';
import quote from './quote';
import job from './job';
import abn from './abn';
import analytics from './analytics';
import agentRecommendation from './agent-recommendation';
import ConnectedUsers from './connectedUsers';
import business from './business';
import utilities from './utilities';
import shared from './shared';
import ServiceDirectory from './service-directory';
import Agencies from './agencies';
import AgentFavoriteBusiness from './agent-favorite-business';

const sagas = function* sagas() {
  yield all([
    Agencies(),
    app(),
    fileDownloader(),
    locations(),
    user(),
    task(),
    quote(),
    myProperties(),
    job(),
    abn(),
    analytics(),
    agentRecommendation(),
    ConnectedUsers(),
    business(),
    utilities(),
    shared(),
    ServiceDirectory(),
    AgentFavoriteBusiness(),
  ]);
};

export default sagas;
