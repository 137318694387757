import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import httpClient from './http-client';
import {
  fetchConsumerChartsDataFailure,
  fetchConsumerChartsDataSuccess,
  fetchConsumerChartsDataRequested,
  FETCH_CONSUMER_CHARTS_DATA,
  FETCH_AGENT_ANALYTICS,
  fetchAgentAnalyticsSuccess,
  fetchAgentAnalyticsRequested,
  fetchAgentAnalyticsFailure,
} from '../actions/analytics-action-types';

export function* fetchConsumerChartsDataHandler() {
  yield put(fetchConsumerChartsDataRequested());

  const userData = yield select((state) => state?.user?.user);
  const userId = userData?.identifier;

  const request = {
    method: 'GET',
    url: `/api/v2/customer/statistics`,
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(fetchConsumerChartsDataFailure(error));
  } else {
    yield put(fetchConsumerChartsDataSuccess(data));
  }
}

export function* fetchAgentAnalyticHandler({ payload }) {
  yield put(fetchAgentAnalyticsRequested());

  const { year, month } = payload;
  const request = {
    method: 'GET',
    url: '/api/v2/agent/statistics',
  };

  if (year && month) {
    request.params = {
      startDate: new Date('01/01/2020').toISOString().split('T')[0],
      endDate: new Date(year, month + 1, 0).toISOString().split('T')[0],
    };
  }

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchAgentAnalyticsFailure(data));
  } else {
    yield put(fetchAgentAnalyticsSuccess(data));
  }
}

function* Job() {
  yield all([
    takeLatest(FETCH_CONSUMER_CHARTS_DATA, fetchConsumerChartsDataHandler),
    takeLatest(FETCH_AGENT_ANALYTICS, fetchAgentAnalyticHandler),
  ]);
}

export default Job;
