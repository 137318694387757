import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_SERVICE_DIRECTORY,
  fetchServiceDirectoryRequested,
  fetchServiceDirectorySuccess,
  fetchServiceDirectoryFailure,
  FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES,
  fetchConnectedAgentFavouriteBusinessesRequested,
  fetchConnectedAgentFavouriteBusinessesSuccess,
  fetchConnectedAgentFavouriteBusinessesFailure,
  FETCH_SERVICE_DIRECTORIES_BY_KEYWORD,
  fetchServiceDirectoriesByKeywordRequested,
  fetchServiceDirectoriesByKeywordRequestedSuccess,
  fetchServiceDirectoriesByKeywordRequestedFailure,
} from '../actions/service-directory-action-types';
import { handleLoader } from '../actions/app-action-types';
import httpClient from './http-client';
import UserRole from '../utility/user-role';

export function* fetchServiceDirectoryHandler({ payload }) {
  yield put(fetchServiceDirectoryRequested());

  const { query, meta } = payload;

  const ENDPOINT_DICTIONARY = {
    [UserRole.Customer]: '/api/v2/customer/service-directory',
    [UserRole.Agent]: '/api/v2/agent/service-directory',
  };

  const role = meta.role;

  const request = {
    method: 'GET',
    params: query,
    url: ENDPOINT_DICTIONARY[role],
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(fetchServiceDirectoryFailure(error));
  } else {
    const { category, service } = query;

    const flaggedData = data.map((business) => ({
      ...business,
      category,
      service,
    }));

    yield put(fetchServiceDirectorySuccess({ data: flaggedData }));
    yield put(handleLoader(false));
  }
}

export function* fetchConnectedAgentFavouriteBusinessesHandler({ payload }) {
  yield put(fetchConnectedAgentFavouriteBusinessesRequested());

  const loader = payload?.meta?.loader ?? true;

  const request = {
    method: 'GET',
    url: '/api/v2/customer/favourite-businesses',
  };

  const { data, error } = yield call(httpClient, request, loader);

  if (error) {
    yield put(fetchConnectedAgentFavouriteBusinessesFailure(error));
  } else {
    yield put(fetchConnectedAgentFavouriteBusinessesSuccess({ data }));
  }
}

export function* fetchServiceDirectoriesByKeywordHandler({ payload }) {
  yield put(fetchServiceDirectoriesByKeywordRequested());

  const loader = payload?.meta?.loader ?? true;
  const { query } = payload;

  const request = {
    method: 'GET',
    url: '/api/v2/customer/service-directory-search',
    params: query,
  };

  const { data, error } = yield call(httpClient, request, loader);

  if (error) {
    yield put(fetchServiceDirectoriesByKeywordRequestedFailure(error));
  } else {
    yield put(fetchServiceDirectoriesByKeywordRequestedSuccess({ data }));
  }
}

function* ServiceDirectory() {
  yield all([
    takeLatest(FETCH_SERVICE_DIRECTORY, fetchServiceDirectoryHandler),
    takeLatest(FETCH_CONNECTED_AGENT_FAVOURITE_BUSINESSES, fetchConnectedAgentFavouriteBusinessesHandler),
    takeLatest(FETCH_SERVICE_DIRECTORIES_BY_KEYWORD, fetchServiceDirectoriesByKeywordHandler),
  ]);
}

export default ServiceDirectory;
