import { createAction } from 'redux-actions';

// GET /v2/business/details
export const FETCH_MY_BUSINESS = 'FETCH_MY_BUSINESS';
export const fetchMyBusiness = createAction(FETCH_MY_BUSINESS);

export const FETCH_MY_BUSINESS_REQUESTED = 'FETCH_MY_BUSINESS_REQUESTED';
export const fetchMyBusinessRequested = createAction(FETCH_MY_BUSINESS_REQUESTED);

export const FETCH_MY_BUSINESS_FAILURE = 'FETCH_MY_BUSINESS_FAILURE';
export const fetchMyBusinessFailure = createAction(FETCH_MY_BUSINESS_FAILURE);

export const FETCH_MY_BUSINESS_SUCCESS = 'FETCH_MY_BUSINESS_SUCCESS';
export const fetchMyBusinessSuccess = createAction(FETCH_MY_BUSINESS_SUCCESS);

// PUT /v2/my-business
export const UPDATE_BUSINESS_PROFILE = 'UPDATE_BUSINESS_PROFILE';
export const updateBusinessProfile = createAction(UPDATE_BUSINESS_PROFILE);

export const UPDATE_BUSINESS_PROFILE_REQUESTED = 'UPDATE_BUSINESS_PROFILE_REQUESTED';
export const updateBusinessProfileRequested = createAction(UPDATE_BUSINESS_PROFILE_REQUESTED);

export const UPDATE_BUSINESS_PROFILE_FAILURE = 'UPDATE_BUSINESS_PROFILE_FAILURE';
export const updateBusinessProfileFailure = createAction(UPDATE_BUSINESS_PROFILE_FAILURE);

export const UPDATE_BUSINESS_PROFILE_SUCCESS = 'UPDATE_BUSINESS_PROFILE_SUCCESS';
export const updateBusinessProfileSuccess = createAction(UPDATE_BUSINESS_PROFILE_SUCCESS);

// POST /v2/business/locations
export const CREATE_BUSINESS_LOCATION = 'CREATE_BUSINESS_LOCATION';
export const createBusinessLocation = createAction(CREATE_BUSINESS_LOCATION);

export const CREATE_BUSINESS_LOCATION_REQUESTED = 'CREATE_BUSINESS_LOCATION_REQUESTED';
export const createBusinessLocationRequested = createAction(CREATE_BUSINESS_LOCATION_REQUESTED);

export const CREATE_BUSINESS_LOCATION_FAILURE = 'CREATE_BUSINESS_LOCATION_FAILURE';
export const createBusinessLocationFailure = createAction(CREATE_BUSINESS_LOCATION_FAILURE);

export const CREATE_BUSINESS_LOCATION_SUCCESS = 'CREATE_BUSINESS_LOCATION_SUCCESS';
export const createBusinessLocationSuccess = createAction(CREATE_BUSINESS_LOCATION_SUCCESS);

// DELETE /v2/business/locations/:locationId
export const DELETE_MULTIPLE_BUSINESS_LOCATIONS = 'DELETE_MULTIPLE_BUSINESS_LOCATIONS';
export const deleteMultipleBusinessLocations = createAction(DELETE_MULTIPLE_BUSINESS_LOCATIONS);

export const DELETE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED = 'DELETE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED';
export const deleteMultipleBusinessLocationsRequested = createAction(DELETE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED);

export const DELETE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE = 'DELETE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE';
export const deleteMultipleBusinessLocationsFailure = createAction(DELETE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE);

export const DELETE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS = 'DELETE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS';
export const deleteMultipleBusinessLocationsSuccess = createAction(DELETE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS);

// PUT /v2/business/locations/:locationId
export const UPDATE_MULTIPLE_BUSINESS_LOCATIONS = 'UPDATE_MULTIPLE_BUSINESS_LOCATIONS';
export const updateMultipleBusinessLocations = createAction(UPDATE_MULTIPLE_BUSINESS_LOCATIONS);

export const UPDATE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED = 'UPDATE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED';
export const updateMultipleBusinessLocationsRequested = createAction(UPDATE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED);

export const UPDATE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE = 'UPDATE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE';
export const updateMultipleBusinessLocationsFailure = createAction(UPDATE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE);

export const UPDATE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS = 'UPDATE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS';
export const updateMultipleBusinessLocationsSuccess = createAction(UPDATE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS);

// PUT /business/terms-and-conditions
export const UPDATE_BUSINESS_TERM_AND_CONDITIONS = 'UPDATE_BUSINESS_TERM_AND_CONDITIONS';
export const updateBusinessTermAndConditions = createAction(UPDATE_BUSINESS_TERM_AND_CONDITIONS);

export const UPDATE_BUSINESS_TERM_AND_CONDITIONS_REQUESTED = 'UPDATE_BUSINESS_TERM_AND_CONDITIONS_REQUESTED';
export const updateBusinessTermAndConditionsRequested = createAction(UPDATE_BUSINESS_TERM_AND_CONDITIONS_REQUESTED);

export const UPDATE_BUSINESS_TERM_AND_CONDITIONS_SUCCESS = 'UPDATE_BUSINESS_TERM_AND_CONDITIONS_SUCCESS';
export const updateBusinessTermAndConditionsSuccess = createAction(UPDATE_BUSINESS_TERM_AND_CONDITIONS_SUCCESS);

export const UPDATE_BUSINESS_TERM_AND_CONDITIONS_FAILURE = 'UPDATE_BUSINESS_TERM_AND_CONDITIONS_FAILURE';
export const updateBusinessTermAndConditionsFailure = createAction(UPDATE_BUSINESS_TERM_AND_CONDITIONS_FAILURE);

// PUT /v2/business/services
export const UPDATE_BUSINESS_SERVICES = 'UPDATE_BUSINESS_SERVICES';
export const updateBusinessServices = createAction(UPDATE_BUSINESS_SERVICES);

export const UPDATE_BUSINESS_SERVICES_REQUESTED = 'UPDATE_BUSINESS_SERVICES_REQUESTED';
export const updateBusinessServicesRequested = createAction(UPDATE_BUSINESS_SERVICES_REQUESTED);

export const UPDATE_BUSINESS_SERVICES_SUCCESS = 'UPDATE_BUSINESS_SERVICES_SUCCESS';
export const updateBusinessServicesSuccess = createAction(UPDATE_BUSINESS_SERVICES_SUCCESS);

export const UPDATE_BUSINESS_SERVICES_FAILURE = 'UPDATE_BUSINESS_SERVICES_FAILURE';
export const updateBusinessServicesFailure = createAction(UPDATE_BUSINESS_SERVICES_FAILURE);

// GET /v2/business/services
export const FETCH_BUSINESS_SERVICES = 'FETCH_BUSINESS_SERVICES';
export const fetchBusinessServices = createAction(FETCH_BUSINESS_SERVICES);

export const FETCH_BUSINESS_SERVICES_REQUESTED = 'FETCH_BUSINESS_SERVICES_REQUESTED';
export const fetchBusinessServicesRequested = createAction(FETCH_BUSINESS_SERVICES_REQUESTED);

export const FETCH_BUSINESS_SERVICES_SUCCESS = 'FETCH_BUSINESS_SERVICES_SUCCESS';
export const fetchBusinessServicesSuccess = createAction(FETCH_BUSINESS_SERVICES_SUCCESS);

export const FETCH_BUSINESS_SERVICES_FAILURE = 'FETCH_BUSINESS_SERVICES_FAILURE';
export const fetchBusinessServicesFailure = createAction(FETCH_BUSINESS_SERVICES_FAILURE);

// PUT /v2/business/payment-details
export const UPDATE_BUSINESS_PAYMENT_DETAILS = 'UPDATE_BUSINESS_PAYMENT_DETAILS';
export const updateBusinessPaymentDetails = createAction(UPDATE_BUSINESS_PAYMENT_DETAILS);

export const UPDATE_BUSINESS_PAYMENT_DETAILS_REQUESTED = 'UPDATE_BUSINESS_PAYMENT_DETAILS_REQUESTED';
export const updateBusinessPaymentDetailsRequested = createAction(UPDATE_BUSINESS_PAYMENT_DETAILS_REQUESTED);

export const UPDATE_BUSINESS_PAYMENT_DETAILS_SUCCESS = 'UPDATE_BUSINESS_PAYMENT_DETAILS_SUCCESS';
export const updateBusinessPaymentDetailsSuccess = createAction(UPDATE_BUSINESS_PAYMENT_DETAILS_SUCCESS);

export const UPDATE_BUSINESS_PAYMENT_DETAILS_FAILURE = 'UPDATE_BUSINESS_PAYMENT_DETAILS_FAILURE';
export const updateBusinessPaymentDetailsFailure = createAction(UPDATE_BUSINESS_PAYMENT_DETAILS_FAILURE);

export const UPDATE_BUSINESS_PAYMENT_DETAILS_PENDING = 'UPDATE_BUSINESS_PAYMENT_DETAILS_PENDING';
export const updateBusinessPaymentDetailsPending = createAction(UPDATE_BUSINESS_PAYMENT_DETAILS_PENDING);

// POST /v2/business/search-business
export const SEARCH_BUSINESS_BY_NAME = 'SEARCH_BUSINESS_BY_NAME';
export const searchBusinessByName = createAction(SEARCH_BUSINESS_BY_NAME);

export const SEARCH_BUSINESS_BY_NAME_REQUESTED = 'SEARCH_BUSINESS_BY_NAME_REQUESTED';
export const searchBusinessByNameRequested = createAction(SEARCH_BUSINESS_BY_NAME_REQUESTED);

export const SEARCH_BUSINESS_BY_NAME_SUCCESS = 'SEARCH_BUSINESS_BY_NAME_SUCCESS';
export const searchBusinessByNameSuccess = createAction(SEARCH_BUSINESS_BY_NAME_SUCCESS);

export const SEARCH_BUSINESS_BY_NAME_FAILURE = 'SEARCH_BUSINESS_BY_NAME_FAILURE';
export const searchBusinessByNameFailure = createAction(SEARCH_BUSINESS_BY_NAME_FAILURE);

// GET /v2/business/statistics
export const FETCH_BUSINESS_STATS = 'FETCH_BUSINESS_STATS';
export const fetchBusinessStats = createAction(FETCH_BUSINESS_STATS);

export const FETCH_BUSINESS_STATS_FAILURE = 'FETCH_BUSINESS_STATS_FAILURE';
export const fetchBusinessStatsFailure = createAction(FETCH_BUSINESS_STATS_FAILURE);

export const FETCH_BUSINESS_STATS_REQUESTED = 'FETCH_BUSINESS_STATS_REQUESTED';
export const fetchBusinessStatsRequested = createAction(FETCH_BUSINESS_STATS_REQUESTED);

export const FETCH_BUSINESS_STATS_SUCCESS = 'FETCH_BUSINESS_STATS_SUCCESS';
export const fetchBusinessStatsSuccess = createAction(FETCH_BUSINESS_STATS_SUCCESS);
