import { createAction } from 'redux-actions';

export const CREATE_TASK = 'CREATE_TASK';
export const createTask = createAction(CREATE_TASK);

export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';
export const createTaskFailure = createAction(CREATE_TASK_FAILURE);

export const CREATE_TASK_REQUESTED = 'CREATE_TASK_REQUESTED';
export const createTaskRequested = createAction(CREATE_TASK_REQUESTED);

export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const createTaskSuccess = createAction(CREATE_TASK_SUCCESS);

export const UPDATE_TASK = 'UPDATE_TASK';
export const updateTask = createAction(UPDATE_TASK);

export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';
export const updateTaskFailure = createAction(UPDATE_TASK_FAILURE);

export const UPDATE_TASK_REQUESTED = 'UPDATE_TASK_REQUESTED';
export const updateTaskRequested = createAction(UPDATE_TASK_REQUESTED);

export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const updateTaskSuccess = createAction(UPDATE_TASK_SUCCESS);

export const FETCH_TASKS = 'FETCH_TASKS';
export const fetchTasks = createAction(FETCH_TASKS);

export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';
export const fetchTasksFailure = createAction(FETCH_TASKS_FAILURE);

export const FETCH_TASKS_REQUESTED = 'FETCH_TASKS_REQUESTED';
export const fetchTasksRequested = createAction(FETCH_TASKS_REQUESTED);

export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const fetchTasksSuccess = createAction(FETCH_TASKS_SUCCESS);

export const FETCH_TASK_BY_ID = 'FETCH_TASK_BY_ID';
export const fetchTaskById = createAction(FETCH_TASK_BY_ID);

export const FETCH_TASK_BY_ID_FAILURE = 'FETCH_TASK_BY_ID_FAILURE';
export const fetchTaskByIdFailure = createAction(FETCH_TASK_BY_ID_FAILURE);

export const FETCH_TASK_BY_ID_REQUESTED = 'FETCH_TASK_BY_ID_REQUESTED';
export const fetchTaskByIdRequested = createAction(FETCH_TASK_BY_ID_REQUESTED);

export const FETCH_TASK_BY_ID_SUCCESS = 'FETCH_TASK_BY_ID_SUCCESS';
export const fetchTaskByIdSuccess = createAction(FETCH_TASK_BY_ID_SUCCESS);
