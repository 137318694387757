import cloneDeep from 'lodash.clonedeep';
import sortBy from 'lodash.sortby';
import {
  CREATE_TASK_FAILURE,
  CREATE_TASK_REQUESTED,
  CREATE_TASK_SUCCESS,
  FETCH_TASKS_FAILURE,
  FETCH_TASKS_REQUESTED,
  FETCH_TASKS_SUCCESS,
  FETCH_TASK_BY_ID_SUCCESS,
  FETCH_TASK_BY_ID_REQUESTED,
  FETCH_TASK_BY_ID_FAILURE,
  UPDATE_TASK_FAILURE,
  UPDATE_TASK_REQUESTED,
  UPDATE_TASK_SUCCESS,
} from '../actions/tasks-action-types';
import formatDate from '../pages/dashboard/common/commonFunctions.jsx';

const initialState = {
  addTaskStatus: 'pending',
  tasks: [],
  fetchTasksStatus: 'pending',
  fetchTaskByIdStatus: 'pending',
  updateTaskStatus: 'pending',
};

export default function tasks(state = initialState, { payload, type }) {
  switch (type) {
    case CREATE_TASK_FAILURE:
      return {
        ...state,
        addTaskStatus: 'failed',
      };

    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        addTaskStatus: 'success',
      };
    case CREATE_TASK_REQUESTED:
      return {
        ...state,
        addTaskStatus: 'request',
      };
    case FETCH_TASKS_FAILURE:
      return {
        ...state,
        fetchTasksStatus: 'failed',
      };

    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        fetchTasksStatus: 'success',
        tasks: payload,
      };
    case FETCH_TASKS_REQUESTED:
      return {
        ...state,
        fetchTasksStatus: 'request',
      };
    case FETCH_TASK_BY_ID_FAILURE:
      return {
        ...state,
        fetchTaskByIdStatus: 'failed',
      };

    case FETCH_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        fetchTaskByIdStatus: 'success',
        tasks: sortBy([...state.tasks, payload], (task) => formatDate(task.dueDate, 'dd-MM-yyyy')),
      };
    case FETCH_TASK_BY_ID_REQUESTED:
      return {
        ...state,
        fetchTaskByIdStatus: 'request',
      };
    case UPDATE_TASK_FAILURE:
      return {
        ...state,
        updateTaskStatus: 'failed',
      };
    case UPDATE_TASK_SUCCESS: {
      const taskList = cloneDeep(state.tasks);
      const index = taskList.findIndex((task) => task.identifier === payload.identifier);

      if (index > -1) {
        taskList[index] = payload;
      }

      return {
        ...state,
        updateTaskStatus: 'success',
        tasks: taskList,
      };
    }
    case UPDATE_TASK_REQUESTED:
      return {
        ...state,
        updateTaskStatus: 'requested',
      };
    default:
      return state;
  }
}
