import { format } from 'date-fns';

const composeDeliveryTimePayload = (deliveryTime) => {
  const anytimeTimeslots = ['morning', 'midday', 'afternoon', 'evening'];

  const isContainAnytime = deliveryTime.includes('anytime');

  if (isContainAnytime) {
    return anytimeTimeslots;
  }

  return deliveryTime;
};

const castPropertyFeatureValue = (value) => {
  const nullishValue = ['', null, undefined];

  if (nullishValue.includes(value)) {
    return null;
  }

  return +value;
};

const composePropertyDetails = (features, formValues, nameSpace) => {
  const { lockUpGarages } = features || {};

  const bedsPropertyName = `${nameSpace}Beds`;
  const bathsPropertyName = `${nameSpace}Baths`;
  const carSpacesPropertyName = `${nameSpace}CarSpaces`;
  const landAreaPropertyName = `${nameSpace}LandArea`;

  return {
    beds: castPropertyFeatureValue(formValues[bedsPropertyName]),
    baths: castPropertyFeatureValue(formValues[bathsPropertyName]),
    carSpaces: castPropertyFeatureValue(formValues[carSpacesPropertyName]),
    lockUpGarages: lockUpGarages || null,
    landArea: castPropertyFeatureValue(formValues[landAreaPropertyName]) || null,
    shareInfoInventoryList: false,
  };
};

const composeFromAddress = (formValues) => {
  if (!formValues?.isRequireFromAndToAddressField) {
    return {};
  }
  const movingFrom = formValues?.fromAddress;

  const isManualAddress = formValues.movingFromAddress === 'manualAddress';
  const propertyData = !isManualAddress ? movingFrom : formValues.manualAddress;

  const nameSpace = isManualAddress ? 'manualAddress' : 'movingFrom';
  return {
    from: {
      address: propertyData?.address,
      postcode: propertyData?.postcode,
      country: propertyData?.country,
      latitude: +propertyData?.latitude,
      longitude: +propertyData?.longitude,
      propertyType: formValues?.[`${nameSpace}PropertyType`],
      propertyDetails: composePropertyDetails(propertyData.features, formValues, nameSpace),
      questionnaire: [
        {
          question: 'What Is The Access Like?',
          answer: formValues?.movingFromPropertyAccess?.join(', '),
        },
        {
          question: 'How furnished is your home?',
          answer: formValues?.movingFromPropertyFurnish,
        },
        {
          question: 'Do you have any heavy/Bulky items?',
          answer: formValues?.movingFromBulkyItems?.join(', '),
        },
      ],
    },
  };
};

const composeToAddress = (formValues) => {
  if (!formValues?.isRequireFromAndToAddressField) {
    return {};
  }
  const movingTo = formValues?.toAddress;

  const isManualAddress = formValues.movingToAddress === 'manualAddress';
  const propertyData = !isManualAddress ? movingTo : formValues.manualAddress;

  const nameSpace = isManualAddress ? 'manualAddress' : 'movingTo';
  return {
    to: {
      address: propertyData?.address,
      postcode: propertyData?.postcode,
      country: propertyData?.country,
      latitude: +propertyData?.latitude,
      longitude: +propertyData?.longitude,
      propertyType: formValues?.[`${nameSpace}PropertyType`],
      propertyDetails: composePropertyDetails(propertyData.features, formValues, nameSpace),
      questionnaire: [
        {
          question: 'How do you access your new home?',
          answer: formValues?.movingToPropertyAccess?.join(', '),
        },
        {
          question: 'How furnished is your home?',
          answer: formValues?.movingToPropertyFurnish,
        },
        {
          question: 'Do you have any heavy/Bulky items?',
          answer: formValues?.movingToBulkyItems?.join(', '),
        },
      ],
    },
  };
};

const composeServiceProductAddress = (formValues) => {
  if (formValues?.isRequireFromAndToAddressField) {
    return {};
  }

  const lookupAddress = formValues?.serviceProductAddress;
  const movingAddress = formValues?.[lookupAddress];

  return {
    serviceProduct: {
      address: movingAddress?.address,
      postcode: movingAddress?.postcode,
      country: movingAddress?.country,
      latitude: +movingAddress?.latitude,
      longitude: +movingAddress?.longitude,
      propertyType: formValues?.serviceProductAddressPropertyType,
      propertyDetails: composePropertyDetails(movingAddress.features, formValues, 'serviceProductAddress'),
    },
  };
};

export const composeQuoteRequestPayload = (formValues) => {
  return {
    parentTaskId: formValues?.parentTaskId,
    childTaskId: formValues?.childTaskId,
    businessId: formValues?.businessId,
    category: formValues?.category,
    service: formValues?.service,
    dueDate: {
      dueDateType: formValues?.dueDateType,
      date: format(formValues?.targetDate, 'yyyy-MM-dd'),
      deliveryTime: composeDeliveryTimePayload(formValues?.deliveryTime),
    },
    bookingDetails: formValues?.bookingDetails || '',
    images: formValues?.images || [],
    address: {
      ...composeFromAddress(formValues),
      ...composeToAddress(formValues),
      ...composeServiceProductAddress(formValues),
    },
    isMoveMyThings: !!formValues?.isRequireFromAndToAddressField,
  };
};
