import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import {
  onboardingUserSuccess,
  INVITE_USER_ON_PLATFORM,
  inviteUserOnPlatformFailure,
  inviteUserOnPlatformRequested,
  inviteUserOnPlatformSuccess,
  SEARCH_AGENT_BY_KEYWORD,
  searchAgentByKeywordFailure,
  searchAgentByKeywordRequested,
  searchAgentByKeywordSuccess,
  UPDATE_USER_PROFILE,
  updateUserProfileFailure,
  updateUserProfileRequested,
  updateUserProfileSuccess,
  CHECK_USER_EXISTS,
  checkUserExistsSuccess,
  checkUserExistsFailure,
  CHECK_USER_EXISTS_CLEAR,
  checkUserExistsClearSuccess,
  CLEAR_USER_INFO,
  checkUserExistsRequested,
  STORE_USER_ONBOARDING,
  storeUserOnboardingSuccess,
  storeUserOnboardingClearSuccess,
  STORE_BUSINESS_ONBOARDING,
  storeBusinessOnboardingSuccess,
  storeBusinessOnboardingClear,
  CREATE_BUSINESS_SIGN_UP,
  createBusinessSignUpFailure,
  createBusinessSignUpClear,
  createBusinessSignUpSuccess,
  STORE_USER_PROFILE,
  CREATE_AGENT_SIGN_UP,
  createAgentSignUpFailure,
  createAgentSignUpSuccess,
  FETCH_USER_PROFILE,
  fetchUserProfileRequested,
  fetchUserProfileFailure,
  fetchUserProfileSuccess,
  createAgentSignUpClear,
  CREATE_CUSTOMER_ACCOUNT,
  createCustomerAccountSuccess,
  createCustomerAccountFailure,
  clearCustomerRegistrationData,
  createAgentSignUpRequested,
  createBusinessSignUpRequested,
  createCustomerAccountRequested,
  UPDATE_USER_MOBILE_NUMBER,
  updateUserMobileNumberRequested,
  updateUserMobileNumberFailure,
  updateUserMobileNumberSuccess,
  UPDATE_USER_EMAIL,
  updateUserEmailRequested,
  updateUserEmailFailure,
  updateUserEmailSuccess,
  UPDATE_AGENT_PROFILE,
  updateAgentProfileRequested,
  updateAgentProfileFailure,
  updateAgentProfileSuccess,
} from '../actions/user-action-types';
import { toggleSnackBar } from '../actions/app-action-types';
import httpClient from './http-client';
import Pages from '../routes/pages.jsx';

export function* updateProfileHandler({ payload }) {
  yield put(updateUserProfileRequested());

  const options = {
    message: 'Information saved successfully',
    severity: 'success',
  };

  const request = {
    data: payload.body,
    method: 'PUT',
    url: '/api/v2/user/profile',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(updateUserProfileFailure(error));
  } else {
    yield put(toggleSnackBar(options));
    yield put(updateUserProfileSuccess({ ...payload.body }));
    if (payload.meta && typeof payload.meta.callback === 'function') {
      payload.meta.callback();
    }
  }
}

export function* inviteUserHandler({ payload }) {
  yield put(inviteUserOnPlatformRequested());

  const request = {
    data: payload,
    method: 'POST',
    url: '/api/v2/customer/invite',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(inviteUserOnPlatformFailure(error));
  } else {
    const options = {
      message: 'Invitation has been sent successfully',
      severity: 'success',
    };

    yield put(toggleSnackBar(options));
    yield put(inviteUserOnPlatformSuccess());
  }
}

const convertGdriveUrl = (originalUrl) => {
  let newHeaderString = 'https://drive.google.com/uc?export=view&id=';
  let imageId = '';
  let targetString = 'thumbnail?id=';

  if (originalUrl.includes(targetString)) {
    let index = originalUrl.indexOf(targetString) + targetString.length;
    imageId = originalUrl.substring(index);
    return newHeaderString + imageId;
  }

  return originalUrl;
};

export function* searchAgentByKeywordHandler({ payload }) {
  yield put(searchAgentByKeywordRequested());
  yield delay(200);
  const request = {
    method: 'GET',
    params: payload,
    url: '/api/v2/customer/agent-search',
  };

  const { data, error } = yield call(httpClient, request, false);
  if (error) {
    yield put(searchAgentByKeywordFailure(error));
  } else {
    data.forEach((agentData) => {
      if (agentData.agentProfile?.logoUrl) {
        agentData.newUrl = convertGdriveUrl(agentData.agentProfile?.logoUrl);
      }
    });
    yield put(searchAgentByKeywordSuccess(data));
  }
}

export function* checkUserExistsHandler({ payload }) {
  yield put(checkUserExistsRequested());
  const request = {
    method: 'POST',
    url: '/api/v2/user/check-user-exists',
    data: payload,
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(checkUserExistsFailure(error.response));
  } else {
    yield put(checkUserExistsSuccess(data));
  }
}

export function* clearUserExistsHandler() {
  yield put(checkUserExistsClearSuccess());
}

export function* fetchUserProfileHandler() {
  yield put(fetchUserProfileRequested());

  const request = {
    method: 'GET',
    url: '/api/v2/user/me',
  };

  const { data, error } = yield call(httpClient, request);

  if (error) {
    yield put(fetchUserProfileFailure(error));
    if (fetchUserProfileFailure(error)?.payload?.response?.status === 404) {
      yield put(toggleSnackBar('You are not a registered user. Please register on our platform to proceed.'));
    }
  } else {
    yield put(fetchUserProfileSuccess(data));
  }
}

export function* clearUserInfoHandler() {
  yield window.$app.navigate(Pages.LandingPage);
}

export function* storeUserOnBoardingHandler(params) {
  const { body } = params.payload;

  yield put(storeUserOnboardingSuccess(body));
}

export function* storeUserOnBoardingClearHandler() {
  yield put(storeUserOnboardingClearSuccess());
}

export function* storeOnBoardingBusinessHandler(params) {
  const { body } = params.payload;

  yield put(storeBusinessOnboardingSuccess(body));
}

export function* storeOnBoardingBusinessClearHandler() {
  yield put(storeBusinessOnboardingClear());
}

export function* createBusinessSignUpHandler(params) {
  yield put(createBusinessSignUpRequested());

  const { body } = params.payload;
  const request = {
    method: 'POST',
    data: body,
    url: '/api/v2/business/signup',
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(createBusinessSignUpFailure(error.response));
  } else {
    yield put(createBusinessSignUpSuccess(data));
  }
}

export function* createBusinessSignUpClearHandler() {
  yield put(createBusinessSignUpClear());
}

export function* storeUserProfileHandler(params) {
  const { body } = params.payload;

  yield put(onboardingUserSuccess(body));
  yield put(storeUserOnboardingClearSuccess());
  yield put(storeBusinessOnboardingClear());
  yield put(createBusinessSignUpClear());
  yield put(createAgentSignUpClear());
  yield put(clearCustomerRegistrationData());
  yield window.$app.navigate('home');
}

export function* createAgentSignUpHandler(params) {
  yield put(createAgentSignUpRequested());

  const { body } = params.payload;
  const request = {
    method: 'POST',
    data: body,
    url: '/api/v2/agent/signup',
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(createAgentSignUpFailure(error.response));
  } else {
    yield put(createAgentSignUpSuccess(data));
  }
}

export function* storeBusinessOnboardingClearHandler() {
  yield put(storeBusinessOnboardingClear());
}

export function* createCustomerAccountHandler(params) {
  yield put(createCustomerAccountRequested());

  const { body } = params.payload;
  const request = {
    method: 'POST',
    data: body,
    url: '/api/v2/customer/signup',
  };

  const { data, error } = yield call(httpClient, request, false);

  if (error) {
    yield put(createCustomerAccountFailure(error.response));
  } else {
    yield put(createCustomerAccountSuccess(data));
  }
}

/**
 * The payload is being to update the phoneNumber state and not for the request body because it doesn't have a request body
 */
export function* updateUserMobileNumberHandler({ payload }) {
  yield put(updateUserMobileNumberRequested());

  const request = {
    method: 'PUT',
    url: '/api/v2/user/mobile-number',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(toggleSnackBar(error));
    yield put(updateUserMobileNumberFailure(error));
  } else {
    // If the call to the backend is successful then we know the mobile number has been verified.
    // Talk to the core team on how it works
    yield put(
      updateUserMobileNumberSuccess({
        ...payload.body,
        isPhoneVerified: true,
      }),
    );

    if (payload.meta && typeof payload.meta.callback === 'function') {
      payload.meta.callback();
    }
  }
}

/**
 * The payload is being to update the email state and not for the request body because it doesn't have a request body
 */
export function* updateUserEmailHandler({ payload }) {
  yield put(updateUserEmailRequested());

  const request = {
    method: 'PUT',
    url: '/api/v2/user/email',
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(toggleSnackBar(error));
    yield put(updateUserEmailFailure(error));
  } else {
    yield put(updateUserEmailSuccess({ ...payload.body }));

    if (payload.meta && typeof payload.meta.callback === 'function') {
      payload.meta.callback();
    }
  }
}

export function* updateAgentProfileHandler({ payload }) {
  yield put(updateAgentProfileRequested());

  const { profileId } = payload.param;

  const request = {
    data: payload.body,
    method: 'PUT',
    url: `/api/v2/agent/profile/${profileId}`,
  };

  const { error } = yield call(httpClient, request);

  if (error) {
    yield put(toggleSnackBar(error));
    yield put(updateAgentProfileFailure(error));
  } else {
    yield put(updateAgentProfileSuccess({ agentProfile: { ...payload.body } }));

    if (payload.meta && typeof payload.meta.callback === 'function') {
      payload.meta.callback();
    }
  }
}

function* User() {
  yield all([
    takeLatest(INVITE_USER_ON_PLATFORM, inviteUserHandler),
    takeLatest(UPDATE_USER_PROFILE, updateProfileHandler),
    takeLatest(SEARCH_AGENT_BY_KEYWORD, searchAgentByKeywordHandler),
    takeLatest(CHECK_USER_EXISTS, checkUserExistsHandler),
    takeLatest(CHECK_USER_EXISTS_CLEAR, clearUserExistsHandler),
    takeLatest(CLEAR_USER_INFO, clearUserInfoHandler),
    takeLatest(STORE_USER_ONBOARDING, storeUserOnBoardingHandler),
    takeLatest(STORE_BUSINESS_ONBOARDING, storeOnBoardingBusinessHandler),
    takeLatest(CREATE_BUSINESS_SIGN_UP, createBusinessSignUpHandler),
    takeLatest(STORE_USER_PROFILE, storeUserProfileHandler),
    takeLatest(CREATE_AGENT_SIGN_UP, createAgentSignUpHandler),
    takeLatest(FETCH_USER_PROFILE, fetchUserProfileHandler),
    takeLatest(CREATE_CUSTOMER_ACCOUNT, createCustomerAccountHandler),
    takeLatest(UPDATE_USER_MOBILE_NUMBER, updateUserMobileNumberHandler),
    takeLatest(UPDATE_USER_EMAIL, updateUserEmailHandler),
    takeLatest(UPDATE_AGENT_PROFILE, updateAgentProfileHandler),
  ]);
}

export default User;
