import React, { createContext, useState, useEffect } from 'react';
import { getRemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config';
import * as Sentry from '@sentry/react';
import { app } from '../firebase';
import { useDispatch } from 'react-redux';
import { setRemoteConfig } from '../actions/app-action-types';

const RemoteConfigContext = createContext({});
const remoteConfig = getRemoteConfig(app);

// For development only
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
  fetchTimeoutMillis: 30000,
};

const RemoteConfigProvider = ({ defaults, children }) => {
  const dispatch = useDispatch();
  const [remoteConfigs, setRemoteConfigs] = useState(defaults);

  remoteConfig.defaultConfig = defaults;

  const getTypedFlag = (flags, key) => {
    const flag = flags[key.toLowerCase()];
    switch (typeof remoteConfig.defaultConfig[key]) {
      case 'boolean':
        return flag.asBoolean();
      case 'number':
        return flag.asNumber();
      case 'string':
      default:
        return flag.asString();
    }
  };

  const remoteConfigObject = (flags) =>
    Object.fromEntries(Object.keys(remoteConfig.defaultConfig).map((key) => [key, getTypedFlag(flags, key)]));

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => getAll(remoteConfig))
      .then(remoteConfigObject)
      .then(setRemoteConfigs)
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
  }, []);

  useEffect(() => {
    dispatch(setRemoteConfig(remoteConfigs));
  }, [remoteConfigs]);

  return <RemoteConfigContext.Provider value={remoteConfigs}>{children}</RemoteConfigContext.Provider>;
};

export { RemoteConfigProvider, RemoteConfigContext };
