import { createAction } from 'redux-actions';

// fetch all connected customers
export const FETCH_CONNECTED_USERS = 'FETCH_CONNECTED_USERS';
export const fetchConnectedUsers = createAction(FETCH_CONNECTED_USERS);

export const FETCH_CONNECTED_USERS_REQUESTED = 'FETCH_CONNECTED_USERS_REQUESTED';
export const fetchConnectedUsersRequested = createAction(FETCH_CONNECTED_USERS_REQUESTED);

export const FETCH_CONNECTED_USERS_FAILURE = 'FETCH_CONNECTED_USERS_FAILURE';
export const fetchConnectedUsersFailure = createAction(FETCH_CONNECTED_USERS_FAILURE);

export const FETCH_CONNECTED_USERS_SUCCESS = 'FETCH_CONNECTED_USERS_SUCCESS';
export const fetchConnectedUsersSuccess = createAction(FETCH_CONNECTED_USERS_SUCCESS);
