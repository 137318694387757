import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import { RemoteConfigProvider } from './providers/RemoteConfigProvider';
import RoutesRoot from './routes/index.jsx';
import ErrorBoundary from './error-boundary.jsx';
import Loader from './loader.jsx';
import Snackbar from './components/snackbar.jsx';
import configureStore from './config/configure-store';
import theme from './config/configure-theme';
import './utility/prototype-methods';
import './firebase/index';
import 'react-datepicker/dist/react-datepicker.css';
import { setLocale as setValidationLocale } from 'yup';
import { DEFAULT_REMOTE_CONFIG_FLAGS, YUP_VALIDATION_CONFIGURATIONS } from '@Constants';
import { AnalyticsProvider } from '@Contexts/Analytics';

const { persistor, store } = configureStore();

setValidationLocale(YUP_VALIDATION_CONFIGURATIONS);

const BemovedApplication = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AnalyticsProvider>
        <PersistGate persistor={persistor} loading={<Loader visible />}>
          <RemoteConfigProvider defaults={DEFAULT_REMOTE_CONFIG_FLAGS}>
            <ErrorBoundary>
              <React.Suspense fallback={<Loader visible />}>
                <RoutesRoot />
              </React.Suspense>
            </ErrorBoundary>
            <Loader />
            <Snackbar />
          </RemoteConfigProvider>
        </PersistGate>
      </AnalyticsProvider>
    </Provider>
  </ThemeProvider>
);

export default BemovedApplication;
