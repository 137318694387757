import {
  FETCH_MY_BUSINESS,
  FETCH_MY_BUSINESS_REQUESTED,
  FETCH_MY_BUSINESS_FAILURE,
  FETCH_MY_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_PROFILE_REQUESTED,
  UPDATE_BUSINESS_PROFILE_FAILURE,
  UPDATE_BUSINESS_PROFILE_SUCCESS,
  CREATE_BUSINESS_LOCATION_REQUESTED,
  CREATE_BUSINESS_LOCATION_FAILURE,
  CREATE_BUSINESS_LOCATION_SUCCESS,
  DELETE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED,
  DELETE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE,
  DELETE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS,
  UPDATE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED,
  UPDATE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE,
  UPDATE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS,
  UPDATE_BUSINESS_TERM_AND_CONDITIONS_REQUESTED,
  UPDATE_BUSINESS_TERM_AND_CONDITIONS_SUCCESS,
  UPDATE_BUSINESS_TERM_AND_CONDITIONS_FAILURE,
  UPDATE_BUSINESS_SERVICES_REQUESTED,
  UPDATE_BUSINESS_SERVICES_SUCCESS,
  UPDATE_BUSINESS_SERVICES_FAILURE,
  FETCH_BUSINESS_SERVICES_REQUESTED,
  FETCH_BUSINESS_SERVICES_SUCCESS,
  FETCH_BUSINESS_SERVICES_FAILURE,
  UPDATE_BUSINESS_PAYMENT_DETAILS_REQUESTED,
  UPDATE_BUSINESS_PAYMENT_DETAILS_SUCCESS,
  UPDATE_BUSINESS_PAYMENT_DETAILS_FAILURE,
  UPDATE_BUSINESS_PAYMENT_DETAILS_PENDING,
  SEARCH_BUSINESS_BY_NAME_REQUESTED,
  SEARCH_BUSINESS_BY_NAME_SUCCESS,
  SEARCH_BUSINESS_BY_NAME_FAILURE,
  FETCH_BUSINESS_STATS_FAILURE,
  FETCH_BUSINESS_STATS_REQUESTED,
  FETCH_BUSINESS_STATS_SUCCESS,
} from '../actions/business-action-types';

const initialState = {
  myBusiness: {},
  businessServices: [],
  fetchMyBusinessStatus: 'pending',
  updateBusinessProfileStatus: 'pending',
  createBusinessLocationStatus: 'pending',
  deleteMultipleBusinessLocationsStatus: 'pending',
  updateMultipleBusinessLocationsStatus: 'pending',
  updateBusinessTermAndConditionsStatus: 'pending',
  updateBusinessServicesStatus: 'pending',
  fetchBusinessServicesStatus: 'pending',
  updateBusinessPaymentDetailsStatus: 'pending',
  searchBusinessByNameStatus: 'pending',
  fetchBusinessStatsStatus: 'pending',
  businessDetails: {},
  businessStats: {},
};

export default function business(state = initialState, { payload, type }) {
  switch (type) {
    case FETCH_MY_BUSINESS:
      return {
        ...state,
        ...payload,
      };
    case FETCH_MY_BUSINESS_REQUESTED:
      return {
        ...state,
        fetchMyBusinessStatus: 'fetching',
      };
    case FETCH_MY_BUSINESS_FAILURE:
      return {
        ...state,
        fetchMyBusinessStatus: 'failure',
      };
    case FETCH_MY_BUSINESS_SUCCESS:
      return {
        ...state,
        myBusiness: payload,
        fetchMyBusinessStatus: 'success',
      };
    case UPDATE_BUSINESS_PROFILE_REQUESTED:
      return {
        ...state,
        updateBusinessProfileStatus: 'requested',
      };
    case UPDATE_BUSINESS_PROFILE_FAILURE:
      return {
        ...state,
        updateBusinessProfileStatus: 'failure',
      };
    case UPDATE_BUSINESS_PROFILE_SUCCESS:
      return {
        ...state,
        updateBusinessProfileStatus: 'success',
      };
    case CREATE_BUSINESS_LOCATION_REQUESTED:
      return {
        ...state,
        createBusinessLocationStatus: 'requested',
      };
    case CREATE_BUSINESS_LOCATION_FAILURE:
      return {
        ...state,
        createBusinessLocationStatus: 'failure',
      };
    case CREATE_BUSINESS_LOCATION_SUCCESS:
      return {
        ...state,
        createBusinessLocationStatus: 'success',
      };
    case DELETE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED:
      return {
        ...state,
        deleteMultipleBusinessLocationsStatus: 'requested',
      };
    case DELETE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE:
      return {
        ...state,
        deleteMultipleBusinessLocationsStatus: 'failure',
      };
    case DELETE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS:
      return {
        ...state,
        deleteMultipleBusinessLocationsStatus: 'success',
      };
    case UPDATE_MULTIPLE_BUSINESS_LOCATIONS_REQUESTED:
      return {
        ...state,
        updateMultipleBusinessLocationsStatus: 'requested',
      };
    case UPDATE_MULTIPLE_BUSINESS_LOCATIONS_FAILURE:
      return {
        ...state,
        updateMultipleBusinessLocationsStatus: 'failure',
      };
    case UPDATE_MULTIPLE_BUSINESS_LOCATIONS_SUCCESS:
      return {
        ...state,
        updateMultipleBusinessLocationsStatus: 'success',
      };
    case UPDATE_BUSINESS_TERM_AND_CONDITIONS_REQUESTED:
      return {
        ...state,
        updateBusinessTermAndConditionsStatus: 'requested',
      };
    case UPDATE_BUSINESS_TERM_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        updateBusinessTermAndConditionsStatus: 'success',
      };
    case UPDATE_BUSINESS_TERM_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        updateBusinessTermAndConditionsStatus: 'failure',
      };
    case UPDATE_BUSINESS_SERVICES_REQUESTED:
      return {
        ...state,
        updateBusinessServicesStatus: 'requested',
      };
    case UPDATE_BUSINESS_SERVICES_SUCCESS:
      return {
        ...state,
        updateBusinessServicesStatus: 'success',
      };
    case UPDATE_BUSINESS_SERVICES_FAILURE:
      return {
        ...state,
        updateBusinessServicesStatus: 'failure',
      };
    case FETCH_BUSINESS_SERVICES_REQUESTED:
      return {
        ...state,
        fetchBusinessServicesStatus: 'requested',
      };
    case FETCH_BUSINESS_SERVICES_SUCCESS:
      return {
        ...state,
        businessServices: [...payload],
        fetchBusinessServicesStatus: 'success',
      };
    case FETCH_BUSINESS_SERVICES_FAILURE:
      return {
        ...state,
        fetchBusinessServicesStatus: 'failure',
      };
    case UPDATE_BUSINESS_PAYMENT_DETAILS_REQUESTED:
      return {
        ...state,
        updateBusinessPaymentDetailsStatus: 'requested',
      };
    case UPDATE_BUSINESS_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        updateBusinessPaymentDetailsStatus: 'success',
      };
    case UPDATE_BUSINESS_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        updateBusinessPaymentDetailsStatus: 'failure',
      };
    case UPDATE_BUSINESS_PAYMENT_DETAILS_PENDING:
      return {
        ...state,
        updateBusinessPaymentDetailsStatus: 'pending',
      };
    case SEARCH_BUSINESS_BY_NAME_REQUESTED:
      return {
        ...state,
        searchBusinessByNameStatus: 'requested',
      };

    case SEARCH_BUSINESS_BY_NAME_SUCCESS:
      return {
        ...state,
        searchBusinessByNameStatus: 'success',
        businessDetails: payload,
      };

    case SEARCH_BUSINESS_BY_NAME_FAILURE:
      return {
        ...state,
        searchBusinessByNameStatus: 'failure',
      };

    case FETCH_BUSINESS_STATS_FAILURE:
      return {
        ...state,
        fetchBusinessStatsStatus: 'failed',
      };

    case FETCH_BUSINESS_STATS_SUCCESS:
      return {
        ...state,
        fetchBusinessStatsStatus: 'success',
        businessStats: payload,
      };

    case FETCH_BUSINESS_STATS_REQUESTED:
      return {
        ...state,
        fetchBusinessStatsStatus: 'request',
      };
    default:
      return state;
  }
}
